import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { GridCustomHeaderComponent } from './data-grid/grid-custom-header.component';
declare var $: any;

@Component({
  selector: 'ccp-report-table',
  templateUrl: './ccp-analytics-table.component.html',
  styleUrls: ['./ccp-analytics-table.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class CcpAnalyticsTableComponent implements OnInit {
  @Input() analyticsMetadata: any;

  gridAPI: any;
  gridOptions: any;
  showDataGrid: boolean = true;
  columnDefs: any[] = [];
  condStyleSheetMap: any = {};
  autoWidthFieldList: any[string];
  analyticsGridConfig: any;
  gridConfigTheme: any;
  frameworkComponents: any;

  constructor() { }

  ngOnInit() {
    this.analyticsGridConfig = this.analyticsMetadata.reportConfig.grid;
    this.updateCondStyleSheetMap();
    this.gridConfigTheme = this.analyticsGridConfig.theme || {};
    this.frameworkComponents = { agColumnHeader: GridCustomHeaderComponent };
    this.initDataGrid();
  }

  initDataGrid() {
    this.setColDefs();
    this.setGridOptions();
  }

  setColDefs(){
    const returnFieldList = (this.analyticsGridConfig.columnDefs || []).length == 0 ? this.analyticsMetadata.returnField : this.analyticsGridConfig.columnDefs;
    this.autoWidthFieldList = [];
    this.columnDefs = returnFieldList.map((field: any) => {
      let fieldName: string = field.name;
      let headerName: string;
      if (field.displayName) headerName = field.displayName;
      else {
        headerName = fieldName.replace(/([A-Z])/g, " $1");
        headerName = headerName.charAt(0).toUpperCase() + headerName.slice(1);
      }
      let width: number = field.width;
      if(!width) this.autoWidthFieldList.push(fieldName);
      return {
        headerName,
        headerComponentParams: { analyticsGridConfigTheme: this.gridConfigTheme },
        field: fieldName,
        width,
        filterParams: { buttons: ['reset', 'apply'], debounceMs: 200 },
        suppressMovable: true,
        cellStyle: (params: any) => {
          var validateRuleCondition = (value: any, ruleObj: any) => {
            if (["<", "<=", ">", ">=", "<>"].indexOf(ruleObj.cond) != -1 && typeof value != "number") return false;
            switch (ruleObj.cond) {
              case "=":
                return value == ruleObj.value;
              case "!=":
                return value != ruleObj.value;
              case "<":
                return value < ruleObj.value;
              case "<=":
                return value <= ruleObj.value;
              case ">":
                return value > ruleObj.value;
              case ">=":
                return value >= ruleObj.value;
              case "<>":
                return ruleObj.startVal < value && ruleObj.endVal > value;
              case "IN":
                return ruleObj.value.split(",").indexOf(value) != -1;
              case "!IN":
                return ruleObj.value.split(",").indexOf(value) == -1;
              default:
                return false;
            }
          }
          var styleObj: any = {};
          var matchedConditionalTheme = (this.condStyleSheetMap[params.colDef.field] || []).find((axisRule: any) => validateRuleCondition(params.data[axisRule.axis], axisRule));
          const rowTheme = (((this.analyticsGridConfig || {}).theme || {}).body || {}).rows || {};
          const rowIndex = params.rowIndex + 1;
          styleObj = { "text-align": rowTheme.align, "fontSize": rowTheme.fontSize };
          if (matchedConditionalTheme) {
            styleObj["background-color"] = matchedConditionalTheme.backgroundColor;
            styleObj["color"] = matchedConditionalTheme.color;
          } else if (rowTheme.needAlternalteRowTheme && rowIndex % 2 == 0) {
            styleObj["background-color"] = rowTheme.evenBgColor;
            styleObj["color"] = rowTheme.evenColor;
          } else {
            styleObj["background-color"] = rowTheme.backgroundColor;
            styleObj["color"] = rowTheme.color;
          }
          return styleObj;
        }
      };
    });
  }

  setGridOptions() {
    this.gridOptions = {
      unSortIcon: true,
      enableCellTextSelection: true,
      enableColResize: false,
      pagination: true,
      paginationPageSize: 10,
      domLayout: 'autoHeight',
      context: {},
      onGridReady: (params: any) => {
        this.gridAPI = params.api;
        if(this.autoWidthFieldList.length > 0) params.columnApi.autoSizeColumns(this.autoWidthFieldList);
      }
    }
    const tableGeneralThemePpty = this.gridConfigTheme.general || {};
    if(tableGeneralThemePpty.hasOwnProperty("pagination") && tableGeneralThemePpty.pagination == false) {
      this.gridOptions.pagination = false;
      delete this.gridOptions.paginationPageSize;
    }
  }

  updateCondStyleSheetMap() {
    var stylesheetMap = this.condStyleSheetMap = {};
    (this.analyticsGridConfig.styleSheet || []).forEach((ruleObj: any) => {
        stylesheetMap[ruleObj.axis] = stylesheetMap[ruleObj.axis] || [];
        stylesheetMap[ruleObj.axis].push(ruleObj);
    });
  }

  rerenderTableWithProperties(params: any) {
    var tableTheme: any = this.gridConfigTheme;
    switch (params.type) {
      case 'tableGeneral':
        var existingPaginationStat = tableTheme.general.pagination;
        tableTheme.general = params.value;
        $(".ccp-report-table *").css("font-family", tableTheme.general.fontFamily);
        if(existingPaginationStat == params.value.pagination) {
          this.setColDefs();
        }else{
          this.showDataGrid = false;
          setTimeout(() => {
            this.initDataGrid();
            this.showDataGrid = true;
          }, 0);
        }
        break;
      case 'tableHeader':
        tableTheme.header = params.value;
        this.setColDefs();
        break;
      case 'tableBody':
        tableTheme.body = params.value;
        this.gridAPI.redrawRows();
        break;
      case 'tableColDefs':
        this.analyticsGridConfig.columnDefs = params.value;
        this.setColDefs();
        break;
      case 'tableConditionalStyleSheet':
        this.analyticsGridConfig.styleSheet = params.value;
        this.updateCondStyleSheetMap();
        this.setColDefs();
        break;
      default:
        break;
    }
  }
  
}
