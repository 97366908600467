import { Component } from "@angular/core";
import { Utils } from "./../utility/util";
import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
    selector: 'api-method-badge-cell',
    template: ` <label [title]="updatedCellValue" class="api-method-badge get" 
                    [ngClass]="{'get': updatedCellValue == 'GET', 
                                'post': updatedCellValue == 'POST', 
                                'put': (updatedCellValue == 'PUT' || updatedCellValue == 'PATCH'), 
                                'delete': updatedCellValue == 'DELETE'}">
                    <span> {{updatedCellValue}} </span>
                </label>`,
    styles: []
})
export class ApiMethodBadgeRendererComponent implements ICellRendererAngularComp {
    public updatedCellValue: any;

    constructor() {}

    agInit(params: any): void {
        const data: any = params.value;
        this.updatedCellValue = data != undefined && data != null ? Utils.escapeHtml(data) : '-';
    }

    refresh(): boolean {
        return false;
    }
}