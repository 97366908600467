import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DefaultTextCellRendererComponent } from '../data-grid/default-text-cell-renderer.component';
import { ApiConnectorListActionsComponent } from './../data-grid/api-connector-list-actions.component';
import { ApiUtilService } from '../service/api-util.service';
import { apiProperties } from '../utility/constants';
import { Utils } from '../utility/util';
declare var $: any;

@Component({
  selector: 'ccp-api-connector-list',
  templateUrl: './api-connector-list.component.html',
  styleUrls: ['./api-connector-list.component.css']
})
export class ApiConnectorListComponent implements OnInit {

  private gridApi: any;
  public pageSize: any = 10;
  public integratorType: any = "All";
  public columnDefs: any[];
  public context: any;
  public frameworkComponents: object;

  public isSubmodule: boolean;
  public selectedRowObject: any = {};

  connectorListOrignal: any[];
  connectorList: any[] = [];
  newConnector: any;
  defaultConnectorList: any[];
  quickAccessType: string;

  constructor(private apiUtilService: ApiUtilService, 
              private router: Router,
              private route: ActivatedRoute,
              private toastr: ToastrService) {
    this.initDataGridDetails();
  }

  ngOnInit() {
    document.title = "CCS - Integrations";
    this.isSubmodule = this.apiUtilService.routedSubmoduleName != "";
    this.getAllConnectorList();
    this.newConnector = { name: "", type: "" };
    this.quickAccessType = this.route.snapshot.queryParamMap.get("quickAccess");
    this.defaultConnectorList = [{ name: "MYSQL Database", value: "Database" },
                                  { name: "REST Service", value: "REST" },
                                  { name: "File Transfer Protocol", value: "FTP" },
                                  { name: "Google Cloud Service", value: "GCS" },
                                  { name: "AWS CLI", value: "AWS CLI" }];
  }

  initDataGridDetails() {
    this.context = { componentParent: this };
    this.columnDefs = [
      { headerName: 'Name', field: 'name', sortable: true, suppressMovable: true },
      { headerName: 'Integrator Type', field: 'subType', sortable: true, suppressMovable: true, width: 50  },
      { headerName: 'Created Date', field: 'createdAt', sortable: true, suppressMovable: true, width: 90  },
      { headerName: 'Updated Date', field: 'updatedAt', sortable: true, suppressMovable: true, width: 90  },
      { headerName: 'Actions', sortable: false, suppressMovable: true, width: 50 }
    ];
    this.frameworkComponents = {
      defaultTextCellRendererComponent: DefaultTextCellRendererComponent,
      apiConnectorListActionsComponent: ApiConnectorListActionsComponent
    }
    this.setDataGridColDefFormatters();
  }

  setDataGridColDefFormatters() {
    this.columnDefs.forEach((colDef: any) => {
      if(!colDef.valueFormatter) {
        const headerName: string = colDef.headerName;
        switch (headerName) {
          case 'Created Date':
          case 'Updated Date':
            colDef.valueFormatter = Utils.dateValueFormatter;
            break;
          case 'Actions':
            colDef.cellRenderer = 'apiConnectorListActionsComponent';
            break;
          default:
            colDef.cellRenderer = 'defaultTextCellRendererComponent';
            break;
        }
      }
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout("autoHeight");
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
  }

  invokeGridAction(data: any, type: any) {
    let currentRowData = data;
    if(type === 'edit') {
      if (this.isSubmodule) this.router.navigate(['landing', { outlets: { studio: `submodule/${this.apiUtilService.routedSubmoduleName}/intergrations-configuration/${currentRowData['id']}` }}]);
      else this.router.navigate(['landing', { outlets: { studio: `intergrations-configuration/${currentRowData['id']}` }}]);
    }
    else if (type === 'delete') {
      this.selectedRowObject = currentRowData;
      console.log('Delete initiated' + this.selectedRowObject);
      $('#delete-confirmation-modal').modal('show');
    }
  }

  getAllConnectorList() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.GET_ALL_API_CONNECTORS;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        this.connectorList = res.body;
        this.connectorListOrignal = res.body;
        Utils.loader('#page-loader', 'hide');
        if(this.quickAccessType == 'create') this.createConnector();
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        if(err.name == "HttpErrorResponse"){
          this.toastr.error("Something went wrong!");
        }
        console.error(err);
      } 
    );
  }

  deleteConnector() {
    Utils.loader('#page-loader', 'show');
    let id = this.selectedRowObject.id;
    let currentAPIProperty = apiProperties.DELETE_API_CONNECTOR_BY_ID;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{ID}", id), currentAPIProperty.method).subscribe(
      (res: any) => {
        Utils.loader('#page-loader', 'hide');
        $('#delete-confirmation-modal').modal('hide');
        this.integratorType = "All";
        this.getAllConnectorList();
        this.toastr.success("Deleted Successfully!");
       },
       (err: any) => {
        Utils.loader('#page-loader', 'hide');
        this.toastr.error((err.error || {}).message || 'Delete Failed');
        console.error(err);
      }
    );
  }

  createConnector() {
    this.newConnector = { name: "", type: "" };
    $("#create-confirmation-modal").modal("show");
  }

  invokeCreate(data: any) {
    let reqObj:any;
    switch(data.type){
      case "GCS":
        reqObj = this.gcsRequest(data);
        break;
      case "FTP":
        reqObj = this.ftpRequest(data);
        break;
      case "Database":
        reqObj = this.dbRequest(data);
        break;
      case "REST":
        reqObj = this.restRequest(data);
        break;
      case "AWS CLI":
        reqObj = this.awsCliRequest(data);
        break;
      default:
        reqObj = null;
        break;
    }
    if(reqObj){
      Utils.loader('#page-loader', 'show');
      let currentAPIProperty = apiProperties.SAVE_API_CONNECTOR;
      this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method, reqObj).subscribe(
        (res: any) => {
          let resp = res.body;
          $("#create-confirmation-modal").modal("hide");
          Utils.loader('#page-loader', 'hide');
          this.router.navigate(['landing', { outlets: { studio: 'intergrations-configuration/' + resp['id'] }}]);
        },
        (err: any) => {
          Utils.loader('#page-loader', 'hide');
          this.toastr.error((err.error || {}).message || 'Save Failed');
          console.error(err);
        } 
      );
    }
  }

  gcsRequest(data: any) {
    let reqObj = {name: data.name, type: "GCS", subType: "GCS", config: {configuredName: data.name, connector_id: "49", connector_name: "Google Cloud Storage", connector_type: "GCS", connector_subtype: "GCS", projectId: "3456", bucketName: "", keyFileName: "", keyFileBase64Content: "", isAppDB: false, isDefaultDB: false, isSecured: false, enableTransactionLog: false, timeout: ""}};
    return reqObj;
  }

  ftpRequest(data: any) {
    let reqObj = {name: data.name, type: "FTP", subType: "FTP", config: {path: "", port: "", isAppDB: false, timeout: "", hostname: "", password: "", supports: "", username: "", isSecured: false, privateKey: "", isDefaultDB: false, connector_id: "24", configuredName: "FTP", connector_name: "FTP", connector_type: "FTP", ftpPpkFileName: "", connector_subtype: "FTP", enableTransactionLog: false, ftpPpkFileBase64Content: ""}, conversionMap: {connector_id: "24"}};
    return reqObj;
  }

  dbRequest(data: any) {
    let reqObj = {name: data.name, type: "Database", subType: "MYSQL", config: {connector_id: "17", configuredName: data.name, connector_name: "MYSQL Database", connector_type: "Database", connector_subtype: "MYSQL", hostname: "", port: "", username: "", password: "", schema: ""}, conversionMap: {connector_id: "17"}};
    return reqObj;
  }

  restRequest(data: any) {
    let reqObj = {name: data.name, type: "Application", subType: "REST", config: {configuredName: data.name, connector_type: "Application", connector_subtype: "REST", header: [], url: "", connector_id: "200", connector_name: "REST Client", request_timeout: "", enableTransactionLog: false, auth: {auth_type: "", auth_details: {} }},conversionMap: {resource: {request: [], response: []}, resourceMap: {request: [], response: []}, operation: [], connector_id: "200"}};
    return reqObj;
  }

  awsCliRequest(data: any) {
    let reqObj = {name: data.name, type: "AWS CLI", subType: "AWS CLI", config: {configuredName: data.name, connector_name: "AWS CLI", connector_type: "AWS CLI", connector_subtype: "AWS CLI", accessKey: "", secretKey: "", sessionToken: ""}};
    return reqObj;
  }

  onTypeChanged() {
    let type = this.integratorType;
    if(type === "Database") type = "MYSQL";
    if(type === "All"){
      this.connectorList = this.connectorListOrignal;
    } else {
      this.connectorList = [];
      let typeList = this.connectorListOrignal.filter( obj => {
                        return obj.subType === type;
                      });
      this.connectorList = typeList;
    }
  }

  onFilterTextBoxChanged(searchText: string) {
    if(searchText){
      let typeList = this.connectorList.filter( obj => obj.name.match(new RegExp(searchText, "gi")) );
      this.connectorList = typeList;
    } else {
      if(this.integratorType !== "All"){
        if(this.integratorType === "Database") this.integratorType = "MYSQL";
        let typeList = this.connectorListOrignal.filter( obj => {
          return obj.subType.contains(this.integratorType);
        });
        this.connectorList = typeList;
      } else {
        this.connectorList = this.connectorListOrignal;
      }
    }
  }

  showCloneIntegratorModal(integrator: any) {
    this.selectedRowObject = JSON.parse(JSON.stringify(integrator));
    this.selectedRowObject.name = this.selectedRowObject.name + " Clone";
    $('#clone-integrator-modal').modal('show');
  }

  cloneIntegrator(navToEdit?: boolean) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.CLONE_API_CONNECTOR;
    var { name } = this.selectedRowObject;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{ID}", this.selectedRowObject.id), currentAPIProperty.method, { name }).subscribe(
      (res: any) => {
        $('#clone-integrator-modal').modal('hide');
        Utils.loader('#page-loader', 'hide');
        if(navToEdit) this.invokeGridAction(res.body, 'edit');
        else this.getAllConnectorList();
        this.toastr.success("Integrator Cloned Successfully.");
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        console.log(err);
      }
    );
  }

}
