import { Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { _get } from './../shared/utils';

@Component({
  selector: "formly-custom-label",
  template: `
    {{ value }}
  `
})
export class CustomLabelComponent extends FieldType implements OnInit {
  value: string;
  ngOnInit() {
    this.value = _get(this.model, this.field.key as string);
  }
}
