import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiUtilService } from '../service/api-util.service';
import { apiProperties } from 'src/app/utility/constants';
import { ToastrService } from 'ngx-toastr';
import { DynamicTemplateComponent } from '../dynamic-template/dynamic-template.component';
import { CcpAnalyticsChartComponent } from '../ccp-analytics-chart/ccp-analytics-chart.component';
import { CcpAnalyticsTableComponent } from '../ccp-analytics-table/ccp-analytics-table.component';

@Component({
  selector: 'ccp-analytics-landing',
  templateUrl: './ccp-analytics-landing.component.html',
  styleUrls: ['./ccp-analytics-landing.component.css']
})
export class CcpAnalyticsLandingComponent implements OnInit {

  @ViewChild('report_dynamic_template', { static: false }) dynamicTemplateChild: DynamicTemplateComponent;
  @ViewChild(CcpAnalyticsChartComponent, { static: false }) chartRef: CcpAnalyticsChartComponent;
  @ViewChild(CcpAnalyticsTableComponent, { static: false }) tableRef: CcpAnalyticsTableComponent;

  @Input() analyticsId: string;
  @Input() currentFilterReqObj: any;
  @Output() showErrorRetryBannerEvent = new EventEmitter<boolean>();
  @Output() responseEmitter = new EventEmitter<boolean>();
  
  analyticsExecutionResp: any = {};
  showPageLoader: boolean = false;
  gridTimeoutRef: any = null;

  constructor(private route: ActivatedRoute, private apiService: ApiUtilService, private toastr: ToastrService) {
    if(!this.analyticsId) {
      this.route.paramMap.subscribe(paramObject => {
        this.analyticsId = (paramObject['params']['id']) || '';
      });
    }
  }

  ngOnInit() {
    this.executeReport();
  }

  executeReport() {
    this.showPageLoader = true;
    let reqObj: any = this.currentFilterReqObj ? this.currentFilterReqObj : this.getQueryParamFilterReqObj();
    let currentAPIProperty = apiProperties.GET_REPORT_RECORDS;
    this.apiService.invokeAPI(currentAPIProperty.path.replace("{ID}", this.analyticsId), 'POST', reqObj).subscribe(
      (res: any) => {
        this.analyticsExecutionResp = res.body || {};
        if(this.responseEmitter) this.responseEmitter.emit(this.analyticsExecutionResp);
        if(this.dynamicTemplateChild) this.dynamicTemplateChild.createComponentFromRaw(this.analyticsExecutionResp);
        this.showPageLoader = false;
      },
      (err: any) => {
        console.log(err);
        this.toastr.error(((err.error || {}).errorMessage || 'Something went wrong. Please check console'), 'FAILED');
        this.showPageLoader = false;
        if(this.showErrorRetryBannerEvent) this.showErrorRetryBannerEvent.emit(true);
      }
    );
  }

  getQueryParamFilterReqObj() {
    var queryParamFilterReqObj = {};
    this.route.queryParams.subscribe(params => queryParamFilterReqObj = params);
    return queryParamFilterReqObj;
  }

  updateLoader(action: string) {
    this.showPageLoader = (action == "show");
  }

  rerenderHTMLReportProperties(htmlReport: any) {
    this.analyticsExecutionResp.reportConfig.htmlReport = htmlReport;
    if(this.dynamicTemplateChild) this.dynamicTemplateChild.createComponentFromRaw(this.analyticsExecutionResp);
  }

  rerenderPreview(reportType: string, params: any) {
    try {
      if(reportType == "Graphical") this.chartRef.rerenderChartWithProperties(params);
      else if(reportType == "Data Grid") this.tableRef.rerenderTableWithProperties(params);
      else if(reportType == "HTML Reports") this.rerenderHTMLReportProperties(params);
    } catch (err) {
      console.error(err);
    }
  }

}
