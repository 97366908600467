import { Component, OnInit } from "@angular/core";
import { FieldType } from "@ngx-formly/core";
import { _get } from './../shared/utils';

//TODO: medium will replay to dynamic and it's support custom format

@Component({
  selector: "formly-custom-datelabel",
  template: `
    {{ value | date : 'medium' }}
  `
})

export class CustomDatelabelComponent extends FieldType implements OnInit {
  value: string;
  
  ngOnInit() {
    this.value = _get(this.model, this.field.key as string);
  }

}