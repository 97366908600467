import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-api-connection-configuration',
  templateUrl: './api-connection-configuration.component.html',
  styleUrls: ['./api-connection-configuration.component.css']
})
export class ApiConnectionConfigurationComponent implements OnInit {
  @Input() configuration: any;
  @Input() isSubmodule: boolean;

  constructor() { }
  staticData: any = {
    advanceType: [
      { value: "", name: "--Select--" },
      { value: "date", name: "Date" },
      { value: "random", name: "Random" },
      { value: "reference", name: "Reference" }
    ],
    random_type: [
      {value: "", name: "--Select--"},
      {value: "numeric", name: "Numeric"},
      {value: "alphanumeric", name: "Alpha numeric"}
    ],
    date_format: [
      { value: "", name: "--Select--" },
      { value: "MM/DD/YYYY", name: "MM/DD/YYYY" },
      { value: "DD/MM/YYYY", name: "DD/MM/YYYY" },
      { value: "YYYY-MM-DD", name: "YYYY-MM-DD" },
      { value: "YYYY-MM-DDTHH:mm:ss.SSSZ", name: "YYYY-MM-DDTHH:mm:ss.SSSZ" }
    ],
    date_timezone: [
      { "value": "", "name": "--Select--" },
      { "value": "GMT-12:00", "name": "(GMT-12:00) International Date Line West" },
      { "value": "GMT-11:00", "name": "(GMT-11:00) Midway Island, Samoa" },
      { "value": "GMT-10:00", "name": "(GMT-10:00) Hawaii" },
      { "value": "GMT-09:00", "name": "(GMT-09:00) Alaska" },
      { "value": "GMT-08:00", "name": "(GMT-08:00) Pacific Time (US & Canada)" },
      { "value": "GMT-08:00", "name": "(GMT-08:00) Tijuana, Baja California" },
      { "value": "GMT-07:00", "name": "(GMT-07:00) Arizona" },
      { "value": "GMT-07:00", "name": "(GMT-07:00) Chihuahua, La Paz, Mazatlan" },
      { "value": "GMT-07:00", "name": "(GMT-07:00) Mountain Time (US & Canada)" },
      { "value": "GMT-06:00", "name": "(GMT-06:00) Central America" },
      { "value": "GMT-06:00", "name": "(GMT-06:00) Central Time (US & Canada)" },
      { "value": "GMT-06:00", "name": "(GMT-06:00) Guadalajara, Mexico City, Monterrey" },
      { "value": "GMT-06:00", "name": "(GMT-06:00) Saskatchewan" },
      { "value": "GMT-05:00", "name": "(GMT-05:00) Bogota, Lima, Quito, Rio Branco" },
      { "value": "GMT-05:00", "name": "(GMT-05:00) Eastern Time (US & Canada)" },
      { "value": "GMT-05:00", "name": "(GMT-05:00) Indiana (East)" },
      { "value": "GMT-04:00", "name": "(GMT-04:00) Atlantic Time (Canada)" },
      { "value": "GMT-04:00", "name": "(GMT-04:00) Caracas, La Paz" },
      { "value": "GMT-04:00", "name": "(GMT-04:00) Manaus" },
      { "value": "GMT-04:00", "name": "(GMT-04:00) Santiago" },
      { "value": "GMT-03:30", "name": "(GMT-03:30) Newfoundland" },
      { "value": "GMT-03:00", "name": "(GMT-03:00) Brasilia" },
      { "value": "GMT-03:00", "name": "(GMT-03:00) Buenos Aires, Georgetown" },
      { "value": "GMT-03:00", "name": "(GMT-03:00) Greenland" },
      { "value": "GMT-03:00", "name": "(GMT-03:00) Montevideo" },
      { "value": "GMT-02:00", "name": "(GMT-02:00) Mid-Atlantic" },
      { "value": "GMT-01:00", "name": "(GMT-01:00) Cape Verde Is." },
      { "value": "GMT-01:00", "name": "(GMT-01:00) Azores" },
      { "value": "GMT+00:00", "name": "(GMT+00:00) Casablanca, Monrovia, Reykjavik" },
      { "value": "GMT+00:00", "name": "(GMT+00:00) Greenwich Mean Time : Dublin, Edinburgh, Lisbon, London" },
      { "value": "GMT+01:00", "name": "(GMT+01:00) Amsterdam, Berlin, Bern, Rome, Stockholm, Vienna" },
      { "value": "GMT+01:00", "name": "(GMT+01:00) Belgrade, Bratislava, Budapest, Ljubljana, Prague" },
      { "value": "GMT+01:00", "name": "(GMT+01:00) Brussels, Copenhagen, Madrid, Paris" },
      { "value": "GMT+01:00", "name": "(GMT+01:00) Sarajevo, Skopje, Warsaw, Zagreb" },
      { "value": "GMT+01:00", "name": "(GMT+01:00) West Central Africa" },
      { "value": "GMT+02:00", "name": "(GMT+02:00) Amman" },
      { "value": "GMT+02:00", "name": "(GMT+02:00) Athens, Bucharest, Istanbul" },
      { "value": "GMT+02:00", "name": "(GMT+02:00) Beirut" },
      { "value": "GMT+02:00", "name": "(GMT+02:00) Cairo" },
      { "value": "GMT+02:00", "name": "(GMT+02:00) Harare, Pretoria" },
      { "value": "GMT+02:00", "name": "(GMT+02:00) Helsinki, Kyiv, Riga, Sofia, Tallinn, Vilnius" },
      { "value": "GMT+02:00", "name": "(GMT+02:00) Jerusalem" },
      { "value": "GMT+02:00", "name": "(GMT+02:00) Minsk" },
      { "value": "GMT+02:00", "name": "(GMT+02:00) Windhoek" },
      { "value": "GMT+03:00", "name": "(GMT+03:00) Kuwait, Riyadh, Baghdad" },
      { "value": "GMT+03:00", "name": "(GMT+03:00) Moscow, St. Petersburg, Volgograd" },
      { "value": "GMT+03:00", "name": "(GMT+03:00) Nairobi" },
      { "value": "GMT+03:00", "name": "(GMT+03:00) Tbilisi" },
      { "value": "GMT+03:30", "name": "(GMT+03:30) Tehran" },
      { "value": "GMT+04:00", "name": "(GMT+04:00) Abu Dhabi, Muscat" },
      { "value": "GMT+04:00", "name": "(GMT+04:00) Baku" },
      { "value": "GMT+04:00", "name": "(GMT+04:00) Yerevan" },
      { "value": "GMT+04:30", "name": "(GMT+04:30) Kabul" },
      { "value": "GMT+05:00", "name": "(GMT+05:00) Yekaterinburg" },
      { "value": "GMT+05:00", "name": "(GMT+05:00) Islamabad, Karachi, Tashkent" },
      { "value": "GMT+05:30", "name": "(GMT+05:30) Sri Jayawardenapura" },
      { "value": "GMT+05:30", "name": "(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi" },
      { "value": "GMT+05:45", "name": "(GMT+05:45) Kathmandu" },
      { "value": "GMT+06:00", "name": "(GMT+06:00) Almaty, Novosibirsk" },
      { "value": "GMT+06:00", "name": "(GMT+06:00) Astana, Dhaka" },
      { "value": "GMT+06:30", "name": "(GMT+06:30) Yangon (Rangoon)" },
      { "value": "GMT+07:00", "name": "(GMT+07:00) Bangkok, Hanoi, Jakarta" },
      { "value": "GMT+07:00", "name": "(GMT+07:00) Krasnoyarsk" },
      { "value": "GMT+08:00", "name": "(GMT+08:00) Beijing, Chongqing, Hong Kong, Urumqi" },
      { "value": "GMT+08:00", "name": "(GMT+08:00) Kuala Lumpur, Singapore" },
      { "value": "GMT+08:00", "name": "(GMT+08:00) Irkutsk, Ulaan Bataar" },
      { "value": "GMT+08:00", "name": "(GMT+08:00) Perth" },
      { "value": "GMT+08:00", "name": "(GMT+08:00) Taipei" },
      { "value": "GMT+09:00", "name": "(GMT+09:00) Osaka, Sapporo, Tokyo" },
      { "value": "GMT+09:00", "name": "(GMT+09:00) Seoul" },
      { "value": "GMT+09:00", "name": "(GMT+09:00) Yakutsk" },
      { "value": "GMT+09:30", "name": "(GMT+09:30) Adelaide" },
      { "value": "GMT+09:30", "name": "(GMT+09:30) Darwin" },
      { "value": "GMT+10:00", "name": "(GMT+10:00) Brisbane" },
      { "value": "GMT+10:00", "name": "(GMT+10:00) Canberra, Melbourne, Sydney" },
      { "value": "GMT+10:00", "name": "(GMT+10:00) Hobart" },
      { "value": "GMT+10:00", "name": "(GMT+10:00) Guam, Port Moresby" },
      { "value": "GMT+10:00", "name": "(GMT+10:00) Vladivostok" },
      { "value": "GMT+11:00", "name": "(GMT+11:00) Magadan, Solomon Is., New Caledonia" },
      { "value": "GMT+12:00", "name": "(GMT+12:00) Auckland, Wellington" },
      { "value": "GMT+12:00", "name": "(GMT+12:00) Fiji, Kamchatka, Marshall Is." },
      { "value": "GMT+13:00", "name": "(GMT+13:00) Nuku'alofa" }
    ]
  };
  auth: any = {
    REST: [
      {value: "BASICAUTH", text: "BASICAUTH"},
      {value: "OAUTH1.0", text: "OAUTH1.0"},
      {value: "OAUTH2.0", text: "OAUTH2.0"},
      {value: "AWSSIGNATURE", text: "AWSSIGNATURE"}
    ],
    SOAP: [
      {value: "BASICAUTH", text: "BASICAUTH"},
      {value: "BEARERSECURITY", text: "BEARERSECURITY"},
      {value: "WSSECURITY", text: "WSSECURITY"},
      {value: "CUSTOMAUTH", text: "CUSTOMAUTH"}
    ]
  };
  ngOnInit() {
  } 

  getConfiguration(): any {
    return this.configuration;
  }
  setValue(variable,value){
    this.configuration[variable] = value;
  }
  setConnectorConfig(config){
      this.configuration = config;
  }
  addItem(meta: any, itemKey: string){
    meta[itemKey] = meta[itemKey] || [];
    meta[itemKey].push({isAdvance: false});
  }
  deleteItem(itemList: any[], index: number){
    itemList.splice(index, 1);
  }
  onTimezoneChange(obj: any, zone: string){
    if(obj !== undefined && obj !== null && zone !== undefined && zone !== null){
      obj.date.timezone = zone.substring(1,zone.indexOf(')'));
    }
  }
}
