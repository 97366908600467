import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { CommonService } from '../service/common.service';
import { DefaultTextCellRendererComponent } from '../data-grid/default-text-cell-renderer.component';
import { SystemPropertiesListActionComponent } from '../data-grid/system-properties-list-actions.component';
import { SystemPropertiesIssecuredCellRendererComponent } from '../data-grid/system-properties-issecured-cell-renderer.component.1';
import { DateTimeRendererComponent } from './../data-grid/date-time-renderer.component';
import { ApiUtilService } from '../service/api-util.service';
import { apiProperties } from '../utility/constants';
import { Utils } from '../utility/util';
declare var $: any;


@Component({
  selector: 'ccp-system-properties-list',
  templateUrl: './system-properties-list.component.html',
  styleUrls: ['./system-properties-list.component.css']
})
export class SystemPropertiesListComponent implements OnInit {

  private gridApi: any;
  public pageSize: any = 10;
  public columnDefs: any[];
  public context: any;
  public frameworkComponents: object;
  public isCreateFlow: boolean;
  public selectedRowKey: any;

  public selectedRowObject: any = {};

  systemPropertiesList: any[];

  constructor(
    private apiUtilService: ApiUtilService, 
    private router: Router,
    private toastr: ToastrService,
    private cs: CommonService
    ) {
    this.initDataGridDetails();
   }

  ngOnInit() {
    document.title = "CCS - Custom Variables";
    this.isCreateFlow = false;
    this.getAllSystemProperties();
  }

  initDataGridDetails() {
    this.context = { componentParent: this };
    this.columnDefs = [
      { headerName: 'Key', field: 'key', sortable: true, suppressMovable: true, cellClass: 'text-custom-link' },
      { headerName: 'Is Secured ?', field: 'isSecured', sortable: true, suppressMovable: true, width: 140, cellClass: 'ag-text-val-color' },
      { headerName: 'Description', field: 'description', sortable: true, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Created', field: 'createdAt', sortable: true, suppressMovable: true, width: 110, cellClass: 'ag-text-val-color'  },
      { headerName: 'Updated', field: 'updatedAt', sortable: true, suppressMovable: true, width: 110, cellClass: 'ag-text-val-color' },
      { headerName: 'Actions', sortable: false, suppressMovable: true, width: 115, cellClass: 'p-0' }
    ];
    this.frameworkComponents = {
      defaultTextCellRendererComponent: DefaultTextCellRendererComponent,
      systemPropertiesListActionsComponent: SystemPropertiesListActionComponent,
      systemPropertiesIssecuredCellRendererComponent: SystemPropertiesIssecuredCellRendererComponent,
      dateTimeRendererComponent: DateTimeRendererComponent
    }
    this.setDataGridColDefFormatters();
  }

  setDataGridColDefFormatters() {
    this.columnDefs.forEach((colDef: any) => {
      if(!colDef.valueFormatter) {
        const headerName: string = colDef.headerName;
        switch (headerName) {
          case 'Created':
          case 'Updated':
            colDef.cellRenderer = 'dateTimeRendererComponent';
            break;
          case 'Is Secured ?':
            colDef.cellRenderer = 'systemPropertiesIssecuredCellRendererComponent';
            break;
          case 'Actions':
            colDef.cellRenderer = 'systemPropertiesListActionsComponent';
            break;
          default:
            colDef.cellRenderer = 'defaultTextCellRendererComponent';
            break;
        }
      }
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout("autoHeight");
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
    this.cs.updateCurrentGridAPI(this.gridApi);
  }

  onFilterTextBoxChanged(searchText: string) {
    this.gridApi.setQuickFilter(searchText);
  }

  invokeGridAction(params: any) {
    let currentRowData: object = params.rowData;
    let type: string = params.type;
    this.selectedRowObject = currentRowData;
    this.selectedRowKey = currentRowData['key'];
    if(type === 'edit'){
      this.isCreateFlow = false;
      $(".sysPval").attr("type","password");
      $(".eyeIcon").removeClass("fa-eye-slash");
      $(".eyeIcon").addClass("fa-eye");
      $("#system-properties-modal").modal("show");
    }else if (type === 'delete') {
      console.log('Delete initiated' + this.selectedRowObject);
      $('#delete-confirmation-modal').modal('show');
    }
  }

  getAllSystemProperties() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.GET_ALL_SYSTEM_PROPERTIES;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        this.systemPropertiesList = res.body;
        Utils.loader('#page-loader', 'hide');
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        if(err.name == "HttpErrorResponse"){
          this.toastr.error("Something went wrong!");
        }
        console.error(err);
      }
    );
  }

  createNew() {
    this.isCreateFlow = true;
    this.selectedRowObject = { key:"", value:"", description: "" };
    $(".sysPval").attr("type","password");
    $(".eyeIcon").removeClass("fa-eye-slash");
    $(".eyeIcon").addClass("fa-eye");
    $("#system-properties-modal").modal("show");
  }

  saveProperty() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.SAVE_SYSTEM_PROPERTIES;
    let reqBody = this.selectedRowObject;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method, reqBody).subscribe(
      (res: any) => {
        Utils.loader('#page-loader', 'hide');
        $("#system-properties-modal").modal("hide");
        this.selectedRowObject = { key:"", value:"", description: "" };
        this.toastr.success("Saved Successfully!");
        this.getAllSystemProperties();
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        this.toastr.error((err.error || {}).message || 'Save Failed');
        console.error(err);
      }
    );
  }

  updateProperty() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.UPDATE_SYSTEM_PROPERTY_BY_KEY;
    let reqBody = this.selectedRowObject;
    reqBody.key = this.selectedRowKey;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{KEY}", this.selectedRowKey), currentAPIProperty.method, reqBody).subscribe(
      (res: any) => {
        Utils.loader('#page-loader', 'hide');
        $("#system-properties-modal").modal("hide");
        this.getAllSystemProperties();
        this.toastr.success("Updated Successfully!");
       },
       (err: any) => {
        Utils.loader('#page-loader', 'hide');
        this.toastr.error((err.error || {}).message || 'Update Failed');
        console.error(err);
      }
    );
  }

  deleteProperty() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.DELETE_SYSTEM_PROPERTY_BY_KEY;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{KEY}", this.selectedRowKey), currentAPIProperty.method).subscribe(
      (res: any) => {
        Utils.loader('#page-loader', 'hide');
        $('#delete-confirmation-modal').modal('hide');
        this.getAllSystemProperties();
        this.toastr.success("Deleted Successfully!");
       },
       (err: any) => {
        Utils.loader('#page-loader', 'hide');
        this.toastr.error((err.error || {}).message || 'Delete Failed');
        console.error(err);
      }
    );
  }

}
