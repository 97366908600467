/**
 * isObject returns whether the provided value is an object type but not an array.
 */
export function isObject(val: unknown): val is {} {
  return val != null && typeof val === 'object' && !Array.isArray(val);
}

/**
 * isPlainObject returns whether the provided value is an object AND doesn't have a
 * prototype besides Object.
 *
 * For example
 * > new Set()         // false
 * > new Map()         // false
 * > new Foo()         // false
 * > new Date()        // false
 * > Object.create({}) // false
 * > {}                // true
 */
export function isPlainObject(val: unknown): boolean {
  return isObject(val) && Object.getPrototypeOf(val) === Object.prototype;
}

/**
 * Makes a deep copy of an object. If multiple objects are passed through then it will merge them
 * in the given order.
 */
export function deepCopy<T extends {}>(value: T, ...partialValues: Array<Partial<T>>): T {
  return deepAssign({} as T, value, ...partialValues);
}

/**
 * deepAssign is like Object.assign but will operate on nested objects. If deepAssign encounters
 * a non-plainObject (see isPlainObject), it will not merge values but instead overwrite any
 * existing value with the newly encountered non-plainObject. In other words, deepAssign does not
 * attempt to merge arrays of objects.
 */
export function deepAssign<T extends {}>(target: T, ...sources: Array<Partial<T>>): T {
  for (const obj of sources) {
    for (const prop of Object.keys(obj)) {
      const newVal = Reflect.get(obj, prop);
      const oldVal = Reflect.get(target, prop);
      if (isPlainObject(newVal) && isPlainObject(oldVal)) {
        Reflect.set(target, prop, deepCopy(oldVal, newVal));
      } else if (isPlainObject(newVal)) {
        Reflect.set(target, prop, deepCopy(newVal));
      } else if (Array.isArray(newVal)) {
        Reflect.set(target, prop, deepAssign([], newVal));
      } else {
        Reflect.set(target, prop, newVal);
      }
    }
  }
  return target;
}

/**
 * Performs a deep value equality test on objects.
 * Note, this method does not handle cyclical references.
 */
export function deepEqual<T>(x: T, y: T): boolean {
  return deepEqualImpl(x, y, false);
}

/**
 * Performs the same equality test as {@code deepEqual}, but relaxes the comparison between two
 * objects, because proto falsy values are considered equivalent in proto3. For example: "", 0,
 * and false are usually not encoded by the API and would return as undefined. With
 * proto3Comparison enabled, undefined will be considered equivalent to falsy primitive types.
 */
export function deepEqualProto3<T>(x: T, y: T): boolean {
  return deepEqualImpl(x, y, true);
}

function deepEqualImpl<T>(x: T, y: T, proto3Comparison = false): boolean {
  if (typeof x === 'object' && typeof x === typeof y) {
    // Traverse through all possible paths between the two objects.
    const fields = new Set<string>([...Object.keys(x), ...Object.keys(y)]);
    return Array.from(fields).every(
        key => deepEqualImpl(
            Reflect.get(x as {}, key),
            Reflect.get(y as {}, key),
            proto3Comparison,
            ));
  } else {
    if (proto3Comparison) {
      return proto3PrimitiveTypeComparison(x, y);
    }
    return x === y;
  }
}

// Relaxes primitive comparison for proto3 objects.
function proto3PrimitiveTypeComparison(x: unknown, y: unknown): boolean {
  // Perform simple comparison if both types are the same.
  if (typeof x === typeof y) {
    return x === y;
    // If one is undefined, then simply check if the other field is falsy.
  } else if (typeof x === 'undefined' || typeof y === 'undefined') {
    return !x && !y;
  }
  return false;
}
