import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonService {
  
  private menuBreadCrumbSubject$: Subject<any> = new Subject<any>();
  public menuBreadCrumbComponentDetails = this.menuBreadCrumbSubject$.asObservable();

  private currentGridAPISubject$: Subject<any> = new Subject<any>();
  public currentGridAPIDetails = this.currentGridAPISubject$.asObservable(); 
  
  constructor() { }

  updateMenuBreadCrumbDetails(data: any) {
    this.menuBreadCrumbSubject$.next(data);
  }

  updateCurrentGridAPI(data: any) {
    this.currentGridAPISubject$.next(data); 
  }
}
