import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ObjectModelerService } from './../service/object-modeler.service';
import { ToastrService } from 'ngx-toastr';
import { Utils } from './../utility/util';
declare var $: any;

@Component({
    selector: 'bulk-api-create-cell-renderer',
    template: ` <ng-container *ngIf="isColumnHeader">
                    <button *ngIf="params.displayName == 'Action'" class="btn btn-sm text-white bg-custom-primary" (click)="addNewApi()"> Add </button>
                </ng-container>
                <ng-container *ngIf="!isColumnHeader">
                    <input type="text" *ngIf="params.colDef.field === 'name'" class="form-control form-control-sm" [(ngModel)]="params.data.name" [disabled]="!params.data.isNewApi"/>
                    
                    <select class="form-control form-control-sm pr-0" *ngIf="params.colDef.field === 'method' && params.data.isNewApi" [(ngModel)]="params.data.method" [disabled]="!params.data.isNewApi">
                        <option value="">Select</option>
                        <option value="GET">GET</option>
                        <option value="POST">POST</option>
                        <option value="PUT">PUT</option>
                        <option value="PATCH">PATCH</option>
                        <option value="DELETE">DELETE</option>
                    </select>
                    <label *ngIf="params.colDef.field === 'method' && !params.data.isNewApi" [title]="updatedCellValue" class="api-method-badge get" 
                        [ngClass]="{'get': updatedCellValue == 'GET', 
                                    'post': updatedCellValue == 'POST', 
                                    'put': (updatedCellValue == 'PUT' || updatedCellValue == 'PATCH'), 
                                    'delete': updatedCellValue == 'DELETE'}">
                        <span> {{updatedCellValue}} </span>
                    </label>
                    
                    <input type="text" *ngIf="params.colDef.field === 'path'" class="form-control form-control-sm" [(ngModel)]="params.data.path" [disabled]="!params.data.isNewApi" />
                    
                    <select class="form-control form-control-sm pr-0" *ngIf="params.colDef.field === 'output'" [(ngModel)]="params.data.output" [disabled]="!params.data.isNewApi">
                        <ng-container *ngFor="let ob of oms.objectsList">
                            <option hidden selected value="">Select Object</option>
                            <option value={{ob.model}}> {{ob.model}} </option>
                        </ng-container>
                    </select>
                
                    <div class="col" *ngIf="params.colDef.headerName === 'Action' && params.data.isNewApi">
                        <span title="Delete" class="cursor-pointer" (click)="removeCurrentAPI()"> <i class="fa fa-trash-o fa-lg" aria-hidden="true"></i> </span>
                    </div>
                </ng-container>`,
    styles: []
})
export class BulkAPICreateCellRendererComponent implements ICellRendererAngularComp {
    public params: any;
    updatedCellValue: any;
    isColumnHeader: boolean = false;

    constructor(public oms: ObjectModelerService, private toastr: ToastrService) { }

    agInit(params: any): void {
        this.params = params;
        const data: any = this.params.value;
        this.updatedCellValue = data != undefined && data != null ? Utils.escapeHtml(data) : '-';
        if(params.eGridHeader) this.isColumnHeader = true;
    }

    refresh(): boolean {
        return false;
    }

    public removeCurrentAPI() {
        this.params.context.componentParent.removeCurrentAPI(this.params.rowIndex);
    }

    public addNewApi() {
        this.params.context.componentParent.addNewApi();
    }


}