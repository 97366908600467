import { apiProperties } from '../utility/constants';
import { Injectable } from '@angular/core';
import { ApiUtilService } from '../service/api-util.service';
import { Utils } from '../utility/util';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import onChange from 'on-change';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsStudioService {

  isAutoPreview: boolean = true;
  showChartPreviewOverlay: boolean = false;
  showAnalyticsPreviewComp: boolean = false;
  showChartDataGrid: boolean = false;
  lastSavedAtsDate: string = null;

  objectsList: any[] = [];
  objectsMap: any = {};

  dbTablesList: any[] = null;
  dbColumnDetails: any = {};

  internalFlowList: any[] = [];
  internalFlowIDMap: any = {};
  internalFlowInputMap: any = {};

  analytics: any = null;
  analyticsMetadata: any = null;
  analyticsApiDetails: any = null;
  analyticsApiFilters: any[] = null;
  analyticsDBDetails: any = null;
  analyticsDBFilters: any[] = null;
  analyticsDBOrderBy: any[] = null;
  analyticsReturnField: any[] = null;
  analyticsConfig: any = null;
  analyticsConfFilter: any = null;
  analyticsConfGrid: any = null;
  analyticsConfGraphical: any = null;
  analyticsConfGraphAddDet: any = null;
  analyticsConfHtmlReport: any = null;

  existingAnalytics: any = null;

  activeTabList: any[string] = [];
  disableTabList: any[string] = [];
  defaultActiveTabList: any[string] = ["dataSource", "chartType", "chartProp", "autoPreview"];
  defaultDisableTabList: any[string] = ["advanced"];
  defaultAnalyticsNamesMap: object = {"Graphical": "Chart", "Data Grid": "Table", "HTML Reports": "Report"}

  private chartFontStyle = { fontSize: "14px", fontFamily: "Montserrat-SemiBold" }

  private chartAdditionConfig: any = {
    title: { text: "", style: { color: '#000', ...this.chartFontStyle } },
    subtitle: { text: "", style: { color: '#000', ...this.chartFontStyle } },
    xAxis: {
      labels: { style: { color: '#000', ...this.chartFontStyle } },
      title: { style: { color: '#000', ...this.chartFontStyle } }
    },
    yAxis: {
      labels: { style: { color: '#000', ...this.chartFontStyle } },
      title: { style: { color: '#000', ...this.chartFontStyle } }
    },
    legend: { itemStyle: { color: '#000', ...this.chartFontStyle } }
  };

  private plotOptionSeries: any = {
    point: { events: {} },
    dataLabels: { enabled: true }
  };

  private plotOptions = { series: { pointPadding: 0, groupPadding: 0.05, ...this.plotOptionSeries } };

  basicChartInfo: any = {
    reports: {
      name: "HTMLReports",
      properties:[],
      additionalInfo: {
        category: "reports",
        stylesheet: ".card-header,\n.card-footer {\n  background-color: #F3F6F9;\n}",
        template: "<div class=\"card text-center\">\n  <div class=\"card-header\">\n    Analytics\n  </div>\n  <div class=\"card-body\">\n    <h5 class=\"card-title\">HTML Report</h5>\n    <p class=\"card-text\">Dashboard to get an overview of your account.</p>\n    <a href=\"javascript:void(0);\" class=\"btn btn-primary\">Refresh results</a>\n  </div>\n  <div class=\"card-footer text-muted\">\n    <i class=\"fa fa-copyright\" aria-hidden=\"true\"></i>&nbsp;Copyright 2020 Apptium Technologies. All rights reserved. <br />\n    <a href=\"javascript:void(0);\" class=\"btn btn-link\">Sitemap</a> |\n    <a href=\"javascript:void(0);\" class=\"btn btn-link\">Privacy Policy</a>\n  </div>\n</div>"
      }
    },
    column: {
      name: "Float Bar",
      mandatoryAxis: ["xAxis", "yAxis"],
      properties:['general', 'series', 'dataLabel', 'axes', 'chart3d', 'legend', 'tooltip', 'advanceChart', 'drilldown'],
      additionalInfo: {
        category: "2-Dimension",
        chart: { type: "column", style: this.chartFontStyle },
        plotOptions: this.plotOptions,
        ...this.chartAdditionConfig,
      }
    },
    lollipop: {
      name: "Lollipop",
      mandatoryAxis: ["xAxis", "yAxis"],
      properties:['general', 'series', 'dataLabel', 'axes', 'legend', 'tooltip', 'advanceChart', 'drilldown'],
      additionalInfo: {
        category: "2-Dimension",
        chart: { type: "lollipop", style: this.chartFontStyle },
        plotOptions: this.plotOptions,
        ...this.chartAdditionConfig
      }
    },
    radialbar: {
      name: "radialbar",
      mandatoryAxis: ["xAxis", "yAxis"],
      properties:['general', 'series', 'dataLabel', 'legend', 'tooltip', 'advanceChart', 'drilldown'],
      additionalInfo: {
        category: "2-Dimension",
        chart: { type: "column", inverted: true, polar: true, style: this.chartFontStyle },
        tooltip: { outside: true },
        pane: { size: '85%', innerSize: '20%', endAngle: 270 },
        plotOptions: { series: { ...this.plotOptionSeries } },
        ...this.chartAdditionConfig
      }
    },
    columnpyramid: {
      name: "Column Pyramid",
      mandatoryAxis: ["xAxis", "yAxis"],
      properties:['general', 'series', 'dataLabel', 'axes', 'legend', 'tooltip', 'advanceChart', 'drilldown'],
      additionalInfo: {
        category: "2-Dimension",
        chart: { type: "columnpyramid", style: this.chartFontStyle },
        plotOptions: this.plotOptions,
        ...this.chartAdditionConfig
      }
    },
    dumbbell: {
      name: "Dumbbell",
      additionalInfo: {
        category: "2-Dimension",
        chart: { type: "dumbbell", inverted: true, style: this.chartFontStyle },
        plotOptions: this.plotOptions,
        ...this.chartAdditionConfig
      }
    },
    streamgraph: {
      name: "StreamGraph",
      mandatoryAxis: ["xAxis", "yAxis"],
      properties:['general', 'series', 'dataLabel', 'axes', 'chart3d', 'legend', 'tooltip', 'advanceChart', 'drilldown'],
      additionalInfo: {
        category: "2-Dimension",
        chart: { type: "streamgraph", style: this.chartFontStyle },
        plotOptions: this.plotOptions,
        ...this.chartAdditionConfig
      }
    },
    bar: {
      name: "Horizontal Bar",
      mandatoryAxis: ["xAxis", "yAxis"],
      properties:['general', 'series', 'dataLabel', 'axes', 'chart3d', 'legend', 'tooltip', 'advanceChart', 'drilldown'],
      additionalInfo: {
        category: "2-Dimension",
        chart: { type: "bar", style: this.chartFontStyle },
        plotOptions: this.plotOptions,
        ...this.chartAdditionConfig
      }
    },
    pie: {
      name: "Pie",
      mandatoryAxis: ["series", "count"],
      properties:['general', 'series', 'dataLabel', 'chart3d', 'tooltip', 'advanceChart', 'drilldown'],
      additionalInfo: {
        category: "1-Dimension",
        chart: { type: "pie", style: this.chartFontStyle },
        plotOptions: { series: { innerSize: 0, allowPointSelect: true, cursor: "pointer", showInLegend: false, ...this.plotOptionSeries } },
        ...this.chartAdditionConfig
      }
    },
    donut: {
      name: "Donut",
      mandatoryAxis: ["series", "count"],
      properties:['general', 'series', 'dataLabel', 'chart3d', 'tooltip', 'advanceChart', 'drilldown'],
      additionalInfo: {
        category: "1-Dimension",
        chart: { type: "pie", style: this.chartFontStyle },
        plotOptions: { series: { innerSize: 100, showInLegend: false, ...this.plotOptionSeries } },
        ...this.chartAdditionConfig
      }
    },
    line: {
      name: "Line",
      mandatoryAxis: ["xAxis", "yAxis"],
      properties:['general', 'series', 'dataLabel', 'axes', 'chart3d', 'legend', 'tooltip', 'advanceChart', 'drilldown'],
      additionalInfo: {
        category: "2-Dimension",
        chart: { type: "line", style: this.chartFontStyle },
        plotOptions: { series: { ...this.plotOptionSeries } },
        ...this.chartAdditionConfig
      }
    },
    bubble: {
      name: "Bubble",
      mandatoryAxis: ["xAxis", "yAxis"],
      properties:['general', 'series', 'dataLabel', 'axes', 'chart3d', 'legend', 'tooltip', 'advanceChart', 'drilldown'],
      additionalInfo: {
        category: "3-Dimension",
        chart: { type: "bubble", zoomType: "xy", style: this.chartFontStyle },
        plotOptions: {
          series: {
            point: { events: {} },
            dataLabels: { enabled: true, style: { fontSize: '14px', fontFamily: '"Montserrat-SemiBold"' }, format: "{point.name}" }
          }
        },
        ...this.chartAdditionConfig,
        legend: { enabled: false }
      }
    },
    gauge: {
      name: "Gauge",
      mandatoryAxis: ["count"],
      properties:['general', 'series', 'dataLabel', 'tooltip', 'advanceChart', 'drilldown'],
      additionalInfo: {
        category: "Gauge",
        chart: { type: "gauge", style: this.chartFontStyle },
        pane: { startAngle: -150, endAngle: 150, background: [{ backgroundColor: '#DDD', borderWidth: 0, outerRadius: '105%', innerRadius: '103%' }] },
        yAxis: {
          min: 0, max: 200,
          minorTickInterval: 'auto', minorTickWidth: 1, minorTickLength: 10, minorTickPosition: 'inside', minorTickColor: '#666',
          tickPixelInterval: 30, tickWidth: 2, tickPosition: 'inside', tickLength: 10, tickColor: '#666',
          labels: { step: 2, rotation: 'auto' },
        },
        plotOptions: { series: { point: { events: {} } } },
        ...this.chartAdditionConfig,
      }
    },
    solidgauge: {
      name: "Solid Gauge",
      mandatoryAxis: ["count"],
      properties:['general', 'series', 'dataLabel', 'tooltip', 'advanceChart', 'drilldown'],
      additionalInfo: {
        category: "Gauge",
        chart: { type: "solidgauge", style: this.chartFontStyle },
        plotOptions: { series: { point: { events: {} } } },
        pane: {
          size: "140%", center: ["50%", "70%"], startAngle: -90, endAngle: 90,
          background: { backgroundColor: "#EEE", innerRadius: "60%", outerRadius: "100%", shape: "arc" }
        },
        yAxis: {
          min: 0, max: 200, lineWidth: 0, minorTickInterval: null, tickAmount: 0,
          labels: { y: 20, style: { color: '#000', font: '14px "Montserrat-SemiBold"' } },
          title: { style: { color: '#000', fontSize: '14px', fontFamily: '"Montserrat-SemiBold"' } }
        },
        ...this.chartAdditionConfig,
      }
    },
    funnel: {
      name: "Funnel",
      mandatoryAxis: ["series", "count"],
      properties:['general', 'series', 'dataLabel', 'tooltip', 'advanceChart', 'drilldown'],
      additionalInfo: {
        category: "1-Dimension",
        chart: { type: "funnel", marginRight: 100, style: this.chartFontStyle },
        plotOptions: { series: { point: { events: {} } } },
        ...this.chartAdditionConfig,
      }
    },
    area: {
      name: "area",
      mandatoryAxis: ["xAxis", "yAxis"],
      properties:['general', 'series', 'dataLabel', 'axes', 'chart3d', 'legend', 'tooltip', 'advanceChart', 'drilldown'],
      additionalInfo: {
        category: "2-Dimension",
        chart: { type: "area", style: this.chartFontStyle },
        plotOptions: this.plotOptions,
        ...this.chartAdditionConfig
      }
    },
    scatter: {
      name: "scatter",
      mandatoryAxis: ["xAxis", "yAxis"],
      properties:['general', 'series', 'dataLabel', 'axes', 'chart3d', 'legend', 'tooltip', 'advanceChart', 'drilldown'],
      additionalInfo: {
        category: "3-Dimension",
        chart: { type: "scatter", zoomType: "xy", style: this.chartFontStyle },
        plotOptions: this.plotOptions,
        ...this.chartAdditionConfig,
        legend: { enabled: false }
      }
    },
    heatmap: {
      name: "heatmap",
      mandatoryAxis: ["xAxis", "yAxis"],
      properties:['general', 'colorAxis', 'dataLabel', 'axes', 'legendHM','tooltip', 'advanceChart', 'drilldown'],
      additionalInfo: {
        category: "2-Dimension",
        name: "Sample Heat Map",
        chart: { type: 'heatmap', marginTop: 40, marginBottom: 80, plotBorderWidth: 1, style: this.chartFontStyle },
        title: { text: '' },
        subtitle: this.chartAdditionConfig.subtitle,
        xAxis: {
          categories: [],
          labels: { style: { color: '#000', font: '14px Montserrat-Regular' } },
          title: { style: { color: '#000', fontSize: '14px', fontFamily: '"Montserrat-SemiBold"' } }
        },
        yAxis: {
          categories: [], reversed: true,
          labels: { style: { color: '#000', font: '14px "Montserrat-Regular"' } },
          title: { style: { color: '#000', fontSize: '14px', fontFamily: '"Montserrat-SemiBold"' } }
        },
        colorAxis: { min: 0, minColor: '#FFFFFF', maxColor: '#FF0000' },
        legend: { enabled:true, align: 'right', layout: 'vertical', margin: 0, verticalAlign: 'top', y: 25, symbolHeight: 280 },
        plotOptions: {
          series: {
            name: '', borderWidth: 1, data: [],
            dataLabels: { enabled: true, color: '#000000' },
            point: { events: {} }
          }
        },
        series: [{
          name: '', borderWidth: 1, data: [],
          dataLabels: { enabled: true, color:'#000', style:{ color:'#000', fontSize:'14px', textOutline:'1px 1px contrast' }}
        }]
      }
    },
    table: {
      name: "table",
      properties:['tableGeneral', 'tableHeader', 'tableBody'],
      additionalInfo: {
        category: "table",
        theme: {
          general: {fontFamily: "Montserrat-SemiBold", sort: true, columnFilter: true, pagination: true},
          header: { backgroundColor: "#fff", color: "#0F1642", align: "center", fontSize: "12px", fontWeight: ""},
          body: { rows: { backgroundColor: "#fff", color: "#0F1642", align: "left", fontSize: "12px", needAlternalteRowTheme: false, evenBgColor: '#fff', evenColor :"#0F1642" }
          }
        }
      }
    }
  };

  constructor(private toastr: ToastrService, private apiUtilService: ApiUtilService, private router: Router) { }

  reset () {
    this.showAnalyticsPreviewComp = false;
    this.showChartPreviewOverlay = false;
    this.isAutoPreview = true;
    this.lastSavedAtsDate = null;
    this.disableTabList = [].concat(this.defaultDisableTabList);
    this.activeTabList = [].concat(this.defaultActiveTabList);
  }

  setCurrentAnalyticsMetadata(analytics: any, initWatcher: boolean = false) {
    if(initWatcher) this.initAnalyticsWatcher(analytics);
    else this.analytics = analytics;
    this.analyticsMetadata = this.analytics.metadata = this.analytics.metadata || {};
    this.analyticsApiDetails = this.analyticsMetadata.apiDetails = this.analyticsMetadata.apiDetails || {};
    this.analyticsApiFilters = this.analyticsApiDetails.filters = this.analyticsApiDetails.filters || [];
    this.analyticsDBDetails = this.analyticsMetadata.dbDetails = this.analyticsMetadata.dbDetails || { dataSource: [{}], type: 'Dynamic' };
    this.analyticsDBFilters = this.analyticsDBDetails.filters = this.analyticsDBDetails.filters || [];
    this.analyticsDBOrderBy = this.analyticsDBDetails.orderBy = this.analyticsDBDetails.orderBy || [];
    this.analyticsReturnField = this.analyticsMetadata.returnField = this.analyticsMetadata.returnField || [];
    this.analyticsConfig = this.analyticsMetadata.config = this.analyticsMetadata.config || {};
    this.analyticsConfFilter = this.analyticsConfig.filters = this.analyticsConfig.filters || {};
    this.analyticsConfGrid = this.analyticsConfig.grid = this.analyticsConfig.grid || {};
    this.analyticsConfGraphical = this.analyticsConfig.graphical = this.analyticsConfig.graphical || Utils.cloneJSON(this.basicChartInfo[this.analytics.analyticsType].additionalInfo || {});
    this.analyticsConfGraphAddDet = this.analyticsConfig.graphAdditionalDetails = this.analyticsConfig.graphAdditionalDetails || {};
    this.analyticsConfHtmlReport = this.analyticsConfig.htmlReport = this.analyticsConfig.htmlReport || Utils.cloneJSON(this.basicChartInfo.reports.additionalInfo || {});

    if(!initWatcher) this.setCurrentAnalyticsMetadata(analytics, true);
  }

  reInitAnalyticsWatcher() {
    this.setCurrentAnalyticsMetadata(Utils.cloneJSON(this.analytics), true);
  }

  initAnalyticsWatcher(analytics: any =  this.analytics) {
    var _this = this;
    var timeoutRef = null;
    var ignorePathList = ["name", "description", "updatedAt"];
    if(this.analytics) onChange.unsubscribe(this.analytics);
    this.analytics = onChange(analytics, function (path, value, previousValue, applyData) {
      if(ignorePathList.indexOf(path) == -1 && !path.endsWith(".alias") && !path.endsWith(".sourceAttr") && !path.endsWith(".targetAttr")) {
        if(timeoutRef) clearTimeout(timeoutRef);
        timeoutRef = setTimeout(() => {
          timeoutRef = null;
          _this.save(true);
        }, 0);
      }
    });
  }

  isValidAnalytics(showErrorMsg: boolean = false) {
    try {
      if(this.analyticsMetadata.type == "API") {
        if (!this.analyticsApiDetails.objectName) throw { message: "Please select Entity" };
        if (!this.analyticsApiDetails.apiId) throw { message: "Please select API" };
      } else if (this.analyticsMetadata.type == "DATABASE") {
        if(this.analyticsDBDetails.type == "Native" && !this.analyticsDBDetails.nativeQuery) throw { message: "Please enter Native query" };
        var dsError: any = null;
        if(this.analyticsDBDetails.dataSource.find((dbDataSource: any, index: number) => {
          if(!dbDataSource.objectName) dsError = { message: "Please select Entity" };
          else if(!dbDataSource.alias) dsError = { message: "Please enter Alias name" };
          else if(index != 0 && !(dbDataSource.sourceAttr && dbDataSource.targetAttr)) dsError = { message: "Please enter Condition" };
          return dsError != null;
        })) throw dsError;
      }
      if(!this.analytics.reportType || !this.analytics.analyticsType) throw { message: "Please select Chart type" };
      if(this.analytics.reportType == "Graphical") {
        if(this.analyticsReturnField.length == 0) throw { message: "Dimensions missing" };
        var emptyMandatoryAxis = ((this.basicChartInfo[this.analytics.analyticsType] || {}).mandatoryAxis || []).find((attr: string) => !this.analyticsConfGraphAddDet[attr]);
        if(emptyMandatoryAxis) throw { message: emptyMandatoryAxis + " is Mandatory" };
      }
    } catch (err) {
      if(showErrorMsg) this.toastr.error(err.message || err);
      return false;
    }
    return true;
  }

  cleanUpReqData(analytics: any) {
    var analyticsMetadata = analytics.metadata;
    var analyticsConfig = analyticsMetadata.config;
    if (analyticsMetadata.type == "API") {
      delete analyticsMetadata.dbDetails;
    } else if (analyticsMetadata.type == "DATABASE") {
      delete analyticsMetadata.apiDetails;
    }
    if (analytics.reportType == "Graphical") {
      delete analyticsConfig.grid;
      delete analyticsConfig.htmlReport;
    } else if (analytics.reportType == "Data Grid") {
      delete analyticsConfig.htmlReport;
      delete analyticsConfig.graphical;
    } else if (analytics.reportType == "HTML Reports") {
      delete analyticsConfig.grid;
      delete analyticsConfig.graphical;
    }
    Utils.cleanEmptyStrFromObj(analytics);
  }

  isValidPreviewAnalytics(analytics: any) {
    var existingAnalytics = Utils.cloneJSON(this.existingAnalytics);
    var analytics = Utils.cloneJSON(analytics);

    var existingAnalyticsConfig = existingAnalytics.metadata.config;
    var analyticsConfig = analytics.metadata.config;

    var isColorsChanged = JSON.stringify((existingAnalyticsConfig.graphical || {}).colors || []) != JSON.stringify((analyticsConfig.graphical || {}).colors || []);

    delete existingAnalytics.createdAt;
    delete existingAnalytics.updatedAt;
    delete existingAnalyticsConfig.graphical;
    delete existingAnalyticsConfig.grid;
    delete existingAnalyticsConfig.htmlReport;

    delete analytics.createdAt;
    delete analytics.updatedAt;
    delete analyticsConfig.graphical;
    delete analyticsConfig.grid;
    delete analyticsConfig.htmlReport;

    var isPreviewRequired = JSON.stringify(existingAnalytics) != JSON.stringify(analytics);
    if(!isPreviewRequired){
      
      isPreviewRequired = isColorsChanged && analyticsConfig.graphAdditionalDetails && analyticsConfig.graphAdditionalDetails.applySeriesClrToAxis;
    }
    return isPreviewRequired;
  }

  save(isAutoSave: boolean = false) {
    if(!this.analytics.name) {
      let nameSuffix = " - " + this.defaultAnalyticsNamesMap[this.analytics.reportType];
      if (this.analyticsMetadata.type == "API" && this.analyticsApiDetails.objectName) {
        this.analytics.name = this.analyticsApiDetails.objectName + nameSuffix;
      } else if (this.analyticsMetadata.type == "DATABASE" && this.analyticsDBDetails.dataSource[0].objectName) {
        this.analytics.name = this.analyticsDBDetails.dataSource[0].objectName + nameSuffix;
      }
    }
    var analyticsRef = Utils.cloneJSON(this.analytics);
    var showErrorMsg = !isAutoSave;
    if(this.isValidAnalytics(showErrorMsg)) {
      this.cleanUpReqData(analyticsRef);
      if(!analyticsRef.id) this.create(analyticsRef);
      else if(JSON.stringify(analyticsRef) != JSON.stringify(this.existingAnalytics)){
        this.update(analyticsRef, this.isValidPreviewAnalytics(analyticsRef));
      } 
    }
  }

  create(analytics: any) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.SAVE_REPORT_METADATA;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method, analytics).subscribe(
      (res: any) => {
        Utils.loader('#page-loader', 'hide');
        this.router.navigate(['landing', { outlets: { studio: 'analytics-studio/' + res.body.id } }]);
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        this.toastr.error((err.error || {}).message || 'Save Failed');
        console.error(err);
      }
    );
  }

  update(analytics: any, isPreviewRequired: boolean = false) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.UPDATE_REPORT_METADATA_BY_ID;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{ID}", analytics.id), currentAPIProperty.method, analytics).subscribe(
      (res: any) => {
        this.existingAnalytics = res.body;
        this.analytics.updatedAt = res.body.updatedAt;
        this.lastSavedAtsDate = Utils.dateTimeValueFormatter({ value: res.body.updatedAt, dateTimeFormat: "MMM dd, YYYY hh:mm:ss A" });
        Utils.loader('#page-loader', 'hide');
        if(isPreviewRequired) {
          if(this.isAutoPreview) {
            this.invokePreviewReload();
          } else {
            this.showChartPreviewOverlay = true;
          }
        }
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        this.toastr.error((err.error || {}).message || 'Update Failed');
        console.error(err);
      }
    );
  }

  invokePreviewReload() {
    this.showChartPreviewOverlay = false;
    this.showAnalyticsPreviewComp = false;
    setTimeout(() => {
      this.showAnalyticsPreviewComp = true;
    }, 0);
  }

}