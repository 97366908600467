import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
 selector: 'formly-refresh-button',
 template: `
 <button class="btn-primary" (click)="onClick()">{{ to.text }}</button>
 `,
})
export class FormlyRefreshButton extends FieldType {
  onClick = () => {
    const attr = this.to.attributes as any;
    if (attr && attr.hasOwnProperty('refreshCallback') && typeof attr.refreshCallback === 'function') {
      attr.refreshCallback();
    }
  };
}