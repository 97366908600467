import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AnalyticsStudioService } from '../analytics-studio.service';

@Component({
  selector: 'ccp-data-filters',
  templateUrl: './data-filters.component.html',
  styleUrls: ['./data-filters.component.css']
})
export class DataFiltersComponent implements OnInit {

  isFilterEdit: boolean = false;
  isOrderByEdit: boolean = false;
  filterEditIndex: number = null;
  orderByEditIndex: number = null;
  filterDetails: any = null;
  orderByDetails: any = null;
  private defaultFilterDetails: any = { attr: "", isDynamicData: true, givenName: "", widget: "", isMandatory: false, isRangeSelector: false, dateFormat: "yyyy-MM-dd HH:mm:ss" };
  private defaultOrderByDetails: any = { attr: "", type: "ASC" };
  
  dateFormatWidgets = ["Date", "DateTime", "Date Range"];
  rangeSelectorWidgets = ["Date Range", "Number Range"];
  operationByWidget = {
    "Textbox": ["=", "!=", ">", ">=", "<", "<=", "<>"],
    "Single-Select": ["=", "!=", ">", ">=", "<", "<=", "<>"],
    "Multi-Select": ["="],
    "DateTime": ["=", "!=", ">", ">=", "<", "<=", "<>"],
    "Date": ["=", "!=", ">", ">=", "<", "<=", "<>"],
    "Date Range": ["BETWEEN"],
    "Number": ["=", "!=", ">", ">=", "<", "<=", "<>"],
    "Number Range": ["BETWEEN"]
  };

  constructor(public ats: AnalyticsStudioService, private toastr: ToastrService) { }

  ngOnInit() {
    this.initFilter();
    this.initOrderBy();
  }

  initFilter() {
    this.isFilterEdit = false;
    this.filterEditIndex = null;
    this.filterDetails = {...this.defaultFilterDetails};
    if(this.ats.analyticsMetadata.type == "DATABASE") {
      this.filterDetails.operation = "";
      if(this.ats.analyticsDBFilters.length > 0) this.filterDetails.operator = "AND";
    }
  }

  initOrderBy() {
    this.isOrderByEdit = false;
    this.orderByEditIndex = null;
    this.orderByDetails = {...this.defaultOrderByDetails};
  }

  getFilters() {
    if(this.ats.analyticsMetadata.type == "DATABASE"){
      return this.ats.analyticsDBFilters;
    } else if(this.ats.analyticsMetadata.type == "API"){
      return this.ats.analyticsApiFilters;
    }
  }

  addFilter(){
    if(this.ats.analyticsMetadata.type == "DATABASE" && this.validateDBFilter()){
      this.ats.analyticsDBFilters.push(this.filterDetails);
      this.initFilter();
    } else if(this.ats.analyticsMetadata.type == "API" && this.validateApiFilter()){
      this.ats.analyticsApiFilters.push(this.filterDetails);
      this.initFilter();
    }
  }

  validateDBFilter(){
    if(!this.filterDetails.attr) this.toastr.error("Please Select Data Field.");
    else if(!this.filterDetails.givenName) this.toastr.error("Please Select Display Name.");
    else if(!this.filterDetails.widget) this.toastr.error("Please Select Widget.");
    else if(!this.filterDetails.operation) this.toastr.error("Please Select Operation.");
    else if(this.ats.analyticsDBFilters.length > 0 && !this.filterDetails.operator &&  this.filterEditIndex != 0) this.toastr.error("Please Select Logic.");
    else{
      if(this.dateFormatWidgets.indexOf(this.filterDetails.widget) == -1) delete this.filterDetails.dateFormat;
      else if(!this.filterDetails.dateFormat) {
        this.toastr.error("Please Enter Date Format");
        return false;
      }
      return true;
    }
    return false;
  }

  validateApiFilter(){
    if(!this.filterDetails.attr) this.toastr.error("Please Select Data Field.");
    else if(!this.filterDetails.givenName) this.toastr.error("Please Select Display Name.");
    else if(!this.filterDetails.widget) this.toastr.error("Please Select Widget.");
    else{
      if(this.dateFormatWidgets.indexOf(this.filterDetails.widget) == -1) delete this.filterDetails.dateFormat;
      else if(!this.filterDetails.dateFormat) {
        this.toastr.error("Please Enter Date Format");
        return false;
      }
      return true;
    }
    return false;
  }

  validateSort() {
    if(!this.orderByDetails.attr) this.toastr.error("Please Select Data Field.");
    if(!this.orderByDetails.type) this.toastr.error("Please Select OrderBy Type.");
    else return true;
    return false;
  }

  editFilter(obj: any, index: number){
    this.isFilterEdit = true;
    this.filterEditIndex = index;
    this.filterDetails = {... obj};
  }

  cancelEditFilter(){
    this.initFilter();
  }

  updateFilter(){
    if(this.ats.analyticsMetadata.type == "DATABASE" && this.validateDBFilter()){
      this.ats.analyticsDBFilters[this.filterEditIndex] = this.filterDetails;
      this.initFilter();
    }else if(this.ats.analyticsMetadata.type == "API" && this.validateApiFilter()){
      this.ats.analyticsApiFilters[this.filterEditIndex] = this.filterDetails;
      this.initFilter();
    }
  }

  deleteFilter(index: number){
    if(this.ats.analyticsMetadata.type == "DATABASE"){
      this.ats.analyticsDBFilters.splice(index, 1);
      if(this.ats.analyticsDBFilters[0] && this.ats.analyticsDBFilters[0].operator) delete this.ats.analyticsDBFilters[0].operator;
    }else if(this.ats.analyticsMetadata.type == "API"){
      this.ats.analyticsApiFilters.splice(index, 1);
    }
    this.initFilter();
  }

  addSort(){
    if(this.validateSort()) {
      this.ats.analyticsDBOrderBy.push(this.orderByDetails);
      this.initOrderBy();
    }
  }

  editSort(obj: any, index: number) {
    this.isOrderByEdit = true;
    this.orderByEditIndex = index;
    this.orderByDetails = {... obj};
  }

  cancelSort(){
    this.initOrderBy();
  }

  updateSort(){
    if(this.validateSort()) {
      this.ats.analyticsDBOrderBy[this.orderByEditIndex] = this.orderByDetails;
      this.initOrderBy();
    }
  }

  deleteSort(index: number){
    this.ats.analyticsDBOrderBy.splice(index, 1);
    this.initOrderBy();
  }

}
