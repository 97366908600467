import { Component, OnInit } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';
import { Options } from "@angular-slider/ngx-slider";

@Component({
  selector: 'formly-field-ngx-slider',
  template: `
    <ngx-slider [(value)]="model[key]" [options]="sliderOptions"></ngx-slider>
  `,
})
export class FormlyFieldNgxSlider extends FieldType<FormlyFieldConfig> implements OnInit {
  key: string;
  sliderOptions: Options;

  defaultOptions = {
    templateOptions: {
      hideFieldUnderline: true,
      floatLabel: 'always',
      hideLabel: true,
    },
  };

  ngOnInit() {
    this.sliderOptions = this.field.templateOptions!.sliderOptions;
  }
}