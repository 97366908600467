import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiUtilService } from '../../service/api-util.service';
import { apiProperties } from 'src/app/utility/constants';
import { Utils } from 'src/app/utility/util';
import { ToastrService } from 'ngx-toastr';

import * as Highcharts from 'highcharts';
import highcharts3D from 'highcharts/highcharts-3d';
highcharts3D(Highcharts);

declare var $: any;
declare var require: any;
let Boost = require('highcharts/modules/boost');
let noData = require('highcharts/modules/no-data-to-display');
let More = require('highcharts/highcharts-more');
let Dumbbell = require('highcharts/modules/dumbbell');
let Lollipop = require('highcharts/modules/lollipop');
let Streamgraph = require('highcharts/modules/streamgraph');
let SeriesLabel = require('highcharts/modules/series-label');
let Funnel = require('highcharts/modules/funnel');
let SolidGauge =require('highcharts/modules/solid-gauge');
let HeatMap = require('highcharts/modules/heatmap');



Boost(Highcharts);
More(Highcharts);
noData(Highcharts);
Dumbbell(Highcharts);
Lollipop(Highcharts);
Streamgraph(Highcharts);
SeriesLabel(Highcharts);
Funnel(Highcharts);
SolidGauge(Highcharts);
HeatMap(Highcharts);

Highcharts.wrap(Highcharts.Axis.prototype, 'getPlotLinePath', function(proceed) {
  var path = proceed.apply(this, Array.prototype.slice.call(arguments, 1));
  if (path) {
      path.flat = false;
  }
  return path;
});

@Component({
  selector: 'ccp-analytics-chart',
  templateUrl: './ccp-analytics-chart.component.html',
  styleUrls: ['./ccp-analytics-chart.component.css']
})
export class CcpAnalyticsChartComponent implements OnInit {

  @ViewChild('chartContainer', { static: true }) chartDOM: ElementRef<any>;
  @Input() analyticsMetadata: any;
  @Output() loaderEmitter = new EventEmitter<any>();

  showDrilldownGrid: boolean = false;
  chartDrilldownRecords: any[];
  highChartRef: any = null;

  private gridApi: any;
  pageSize: any = 10;
  columnDefs: any[];
  context: any;
  searchText: string;

  constructor(private apiService: ApiUtilService, private toastr: ToastrService) { }

  ngOnInit() {
    this.renderChart();
  }

  chartClickEvent = (currentEvent: any) => {
    var reportConfig = this.analyticsMetadata.reportConfig;
    var graphAdditionalDetails = reportConfig.graphAdditionalDetails;
    var drilldownInfo = graphAdditionalDetails.drilldownInfo;
    if(drilldownInfo && drilldownInfo.apiId) {
      var currentChartType = reportConfig.graphical.chart.type;
      var chartEventDetails = currentEvent.point;
      var reqObj = {};
      if (currentChartType === "column" || currentChartType === "bar" || currentChartType === "line" || currentChartType === "area" ) {
        reqObj[graphAdditionalDetails.xAxis] = chartEventDetails.category;
        if(!graphAdditionalDetails.yAxisCondition) reqObj[graphAdditionalDetails.yAxis] = chartEventDetails.y;
        if(graphAdditionalDetails.series) reqObj[graphAdditionalDetails.series] = chartEventDetails.series.name;
        this.invokeChartDrillDownRecordsAPI(reqObj, drilldownInfo.apiId);
      } else if (currentChartType === "pie" || currentChartType === "donut" || currentChartType === "funnel") {
        if(!graphAdditionalDetails.countCondition) reqObj[graphAdditionalDetails.count] = chartEventDetails.y;
        reqObj[graphAdditionalDetails.series] = chartEventDetails.name;
        this.invokeChartDrillDownRecordsAPI(reqObj, drilldownInfo.apiId);
      } else if(currentChartType === "heatmap"){
        if(graphAdditionalDetails.xAxis) reqObj[graphAdditionalDetails.xAxis] = reportConfig.graphical.xAxis.categories[chartEventDetails.x];
        if(graphAdditionalDetails.yAxis) reqObj[graphAdditionalDetails.yAxis] = reportConfig.graphical.yAxis.categories[chartEventDetails.y];
        if(graphAdditionalDetails.series) reqObj[graphAdditionalDetails.series] = chartEventDetails.value;
        this.invokeChartDrillDownRecordsAPI(reqObj, drilldownInfo.apiId);
      }
    }
  }

  invokeChartDrillDownRecordsAPI(reqObj: any, apiId: string) {
    let currentAPIProperty = apiProperties.GET_CHART_DRILLDOWN_RECORDS;
    this.loaderEmitter.emit('show');
    this.apiService.invokeAPI(currentAPIProperty.path.replace("{APIID}", apiId), 'POST', reqObj).subscribe(
      (res: any) => {
        this.buildDrilldownColDef();
        this.chartDrilldownRecords = res.body || [];
        setTimeout(() => { this.showDrilldownGrid = true; }, 0);
        this.loaderEmitter.emit('hide');
      },
      (err: any) => {
        this.loaderEmitter.emit('hide');
        console.log(err);
        this.toastr.error(('Something went wrong. Please check console'), 'FAILED');
      });
  }

  buildDrilldownColDef() {
    this.columnDefs = this.analyticsMetadata.returnField.map((field: any) => {
      var headerName = field.name.replace(/([A-Z])/g, " $1");
      headerName = headerName.charAt(0).toUpperCase() + headerName.slice(1);
      return { headerName, field: field.name, sortable: true, suppressMovable: true };
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.setDomLayout("autoHeight");
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
  }

  onPageSizeChanged() {
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(this.searchText);
  }

  renderChart() {
    var chartResponse: any = this.analyticsMetadata.results.chartResponse;
    var domObj: any = this.chartDOM.nativeElement;;
    var domRect: any = domObj.getBoundingClientRect();
    if (chartResponse.legend.itemStyle) {
      chartResponse.legend.itemStyle.width = domRect.width - 20;
    } else {
      chartResponse.legend['itemStyle'] = { width: domRect.width - 20 };
    }

    chartResponse.chart.height =  chartResponse.chart.type === "heatmap" ? 400 : domRect.height;

    if(!chartResponse.plotOptions.series.point){
      chartResponse.plotOptions.series.point = {events: {click: {}}};
    }
    chartResponse.plotOptions.series.point.events.click = this.chartClickEvent;
    this.highChartRef = Highcharts.chart(domObj, chartResponse, (chart: any) => {
      if (chartResponse.customizeLegendFunction) {
        chartResponse.customizeLegendFunction(chart);
      }
    });
  }

  rerenderChartWithProperties(params: any) {
    var chartResponse: any = this.analyticsMetadata.results.chartResponse;
    var analyticType = chartResponse.chart.type;
    switch (params.type) {
      case 'general':
        chartResponse.title = params.value.title;
        chartResponse.subtitle = params.value.subtitle;
        chartResponse.chart.zoomType = params.value.zoomType;
        break;
      case 'series':
        chartResponse.colors = params.value.colors;
        break;
      case 'valueLabel':
        analyticType != 'heatmap' ?  chartResponse.plotOptions.series.dataLabels = params.value :  chartResponse.series[0].dataLabels = params.value; 
        //chartResponse.series[0].dataLabels.color = params.value.style.color;
        break;
      case 'axes':
        chartResponse.xAxis.labels = params.value.xAxis.labels;
        chartResponse.xAxis.title = params.value.xAxis.title;
        chartResponse.xAxis.opposite = params.value.xAxis.opposite;
        chartResponse.yAxis.labels = params.value.yAxis.labels;
        chartResponse.yAxis.title = params.value.yAxis.title;
        chartResponse.yAxis.opposite = params.value.yAxis.opposite;
        break;
      case 'chart3d':
        if(params.value.thickness > 0) chartResponse.plotOptions.series.depth = params.value.thickness;
        else delete chartResponse.plotOptions.series.depth;
        delete params.value.thickness;
        chartResponse.chart.options3d = params.value;
        break;
      case 'chartLegend':
        chartResponse.legend = params.value;
        break;
      case 'mapLegend':
        chartResponse.legend = params.value;
        break;
      case 'drillDown':
        chartResponse.drilldownInfo = params.value;
        break;
      case 'tooltip':
        chartResponse.tooltip = Utils.cloneJSON(params.value);
        break;
      case 'advanceChart':
        //chartResponse.tooltip = Utils.cloneJSON(params.value.tooltip);
        break;
      case 'colorAxis':
        chartResponse.colorAxis = params.value;
      break;
    }
    this.highChartRef.update(chartResponse);
  }

  chartResize() {
    setTimeout(() => this.highChartRef.reflow(), 0);
  }

}
