import { Component } from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
    selector: 'report-generator-landing-action-cell',
    template: `<div class="row d-flex w-100 position-absolute action-icon-list-3 m-0 t-0" style="line-height: 55px;">
                    <div class="col-4 left-icon text-center cursor-pointer" (click)="invokeAction('edit')">
                        <img class="form-control-img" src="assets/images/actions/edit.svg"/>
                        <div class="left-icon-with-label" style="background-color: #477ED8;">
                            <img class="form-control-img" src="assets/images/actions/edit-white.svg"/>
                            <label class="label-primary-sm float-right pt-3 w-50 cursor-pointer">Edit</label>
                        </div>
                    </div>
                    <div class="col-4 center-icon text-center cursor-pointer" (click)="invokeAction('clone')">
                        <i class="fa fa-lg fa-clone font-weight-bold text-dark" aria-hidden="true"></i>
                        <div class="center-icon-with-label" style="background-color: #fb9500;">
                            <i class="fa fa-lg fa-clone font-weight-bold text-white" aria-hidden="true"></i>
                            <label class="label-primary-sm float-right pt-3 w-50 cursor-pointer">Clone</label>
                        </div>
                    </div>
                    <div class="col-4 right-icon text-center cursor-pointer" (click)="invokeAction('delete')">
                        <img class="form-control-img" src="assets/images/actions/delete.svg"/>
                        <div class="right-icon-with-label" style="background-color: #FF7979;">
                            <label class="label-primary-sm pt-3 w-50 float-left cursor-pointer">Delete</label>
                            <img class="form-control-img" src="assets/images/actions/delete-white.svg"/>
                        </div>
                    </div>
               </div>`,
    styles: []
})

export class AnalyticsListActions implements ICellRendererAngularComp {
    private params: any;

    constructor() {}

    agInit(params: any): void {
        this.params = params;
    }

    public invokeAction(type: string) {
        this.params.context.componentParent.invokeGridAction({rowData: this.params.node.data, type});
    }

    refresh(): boolean {
        return false;
    }
}