import { Injectable } from '@angular/core';
import { ApiUtilService } from './api-util.service';
import { apiProperties } from '../utility/constants';
import { Utils } from '../utility/util';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class EventSchemaService {

  eventSchemaList: any[] = [];

  constructor(private apiUtilService: ApiUtilService, private toastr: ToastrService,) { }

  getAllEventSchemaList(successCallback: any, errorCallback: any) {
    let currentAPIProperty = apiProperties.GET_ALL_EVENT_SCHEMA;
    Utils.loader('#page-loader', 'show');
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        Utils.loader('#page-loader', 'hide');
        this.eventSchemaList = res.body;
        successCallback(res);
      },
      (err: any) => {
        if(err.name == "HttpErrorResponse"){
          this.toastr.error("Something went wrong!");
        }
        Utils.loader('#page-loader', 'hide');
        errorCallback();
      }
    );
  }
}
