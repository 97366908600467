import { Pipe, PipeTransform } from '@angular/core';
import { isEmptyValue, _get } from './../shared/utils';

@Pipe({
  name: 'textfilter'
})
export class TextFilterPipe implements PipeTransform {
  transform(items: any[], paths: string | string[], value: string): any[] {
    if (isEmptyValue(items)) return [];
    if (isEmptyValue(paths) || isEmptyValue(value)) return items;

    return items.filter(item => {
      if (typeof paths === 'string') {
        paths = [paths];
      }

      return paths.some(path => {
        if (path === '*') {
          return Object.keys(item).some(key => {
            return String(item[key]).toLowerCase().includes(value.toLowerCase());
          });
        } else {
          return String(_get(item, path)).toLowerCase().includes(value.toLowerCase());
        }
      });
    });
   }
}