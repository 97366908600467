import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ObjectModelerService } from './../service/object-modeler.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
    selector: 'object-rels-cell-renderer',
    template: `<select class="form-control form-control-sm pr-0" *ngIf="params.colDef.field === 'model'" [(ngModel)]="params.data.model" id="objectRelation_{{params.rowIndex}}" (change)="updateSelectedRelationship(params.rowIndex)" [disabled]="oms.currentEditObject.isSystemObject">
                <ng-container *ngFor="let ob of oms.objectsList">
                 <option hidden selected value="">Select Object</option>
                 <option *ngIf="ob.model !== oms.currentEditObject.model" value={{ob.model}}>
                  {{ob.model}}
                 </option>
                </ng-container>
               </select>
               <input type="text" *ngIf="params.colDef.field === 'name'" class="form-control form-control-sm" [(ngModel)]="params.data.name" id="objectRelationAlias_{{params.rowIndex}}" (blur)="validateRelsAliasName(params.rowIndex)" [disabled]="oms.currentEditObject.isSystemObject"/>
               <select class="form-control form-control-sm pr-0" *ngIf="params.colDef.field === 'type'" [(ngModel)]="params.data.type" [disabled]="oms.currentEditObject.isSystemObject">
                <option value="1"> One-to-One </option>
                <option value="n"> One-to-Many </option>
                <option value="n-n"> Many-to-Many </option>
               </select>
               <div class="col" *ngIf="params.colDef.headerName === 'Action'">
                <span title="Delete" class="cursor-pointer" (click)="deleteRels()"> <i class="fa fa-trash-o fa-lg" aria-hidden="true"></i> </span>
               </div>`,
    styles: []
})
export class ObjectRelationshipCellRendererComponent implements ICellRendererAngularComp {
    public params: any;

    constructor(public oms: ObjectModelerService, private toastr: ToastrService) { }

    agInit(params: any): void {
        this.params = params;
    }

    public invokeAction(type: string) {
        this.params.context.componentParent.invokeGridAction({ rowData: this.params.node.data, type });
    }

    refresh(): boolean {
        return false;
    }

    public deleteRels() {
        this.params.context.componentParent.deleteRelationship(this.params.rowIndex);
    }

    updateSelectedRelationship(index: number){
        var relName = this.getRelationshipAliasName(this.params.data.model);
        relName = relName.charAt(0).toLowerCase() + relName.slice(1);
        this.params.data.name = relName;
        $("#objectRelation_" + index).removeClass('border-warning');
    }

    getRelationshipAliasName(selectedRelModelName){
        var relationShipAliasName = selectedRelModelName;
        var rel_attr_Map = {};
        this.oms.currentEditObject.relationships.forEach((rel: any) => {
            if(rel.model == this.params.data.model) rel_attr_Map[rel.name] = rel;
        });
        this.oms.currentEditObject.attributes.forEach((attr: any) => rel_attr_Map[attr.name] = attr );
        var aliasLength = 1;
        if(Object.keys(rel_attr_Map).length > 1){
            relationShipAliasName = selectedRelModelName + (aliasLength == 1 ? "" : aliasLength);
            while (rel_attr_Map[relationShipAliasName]) {
                aliasLength++;
                relationShipAliasName = selectedRelModelName + aliasLength;
            }
        }
        return relationShipAliasName;
    }

    validateRelsAliasName(index: number){
        var aliasName = this.params.data.name
        var relsAliasList = (this.oms.currentEditObject.relationships || []).filter((rel: any) => rel.name == aliasName);
        if(relsAliasList && relsAliasList.length > 1){
            this.toastr.error("Alias name already exists");
            $("#objectRelationAlias_" + index).addClass('border-warning');
            return;
        }
        var attrAliasList = (this.oms.currentEditObject.attributes || []).find((attr: any) => attr.name == aliasName);
        if(attrAliasList) {
            this.toastr.error("Alias name already exists as an attribute name");
            $("#objectRelationAlias_" + index).addClass('border-warning');
            return;
        }	
        $("#objectRelationAlias_"+index).removeClass('border-warning');
    }

}