import { Compiler, Component, ComponentRef, Injector, NgModule, NgModuleRef, ViewChild, ViewContainerRef, Input } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ccp-dynamic-template',
  template: '<div #vc></div>'
})
export class DynamicTemplateComponent {

  @ViewChild('vc', { read: ViewContainerRef, static: false }) vc: ViewContainerRef;
  @Input() reportDetails: any;

  private cmpRef: ComponentRef<any>;

  constructor(private compiler: Compiler,
    private injector: Injector,
    private moduleRef: NgModuleRef<any>,
    public toastr: ToastrService
  ) { }

  ngAfterViewInit() {
    this.createComponentFromRaw();
  }

  public createComponentFromRaw(reportDetails = this.reportDetails) {
    var _this = this;
    this.destroyComponent();
    
    const reportResponse = reportDetails.results.reportResponse || {};
    const htmlReportConfig = reportDetails.reportConfig.htmlReport;
    const template = htmlReportConfig.template || "";
    const styles = [htmlReportConfig.stylesheet || ""];
    var script = htmlReportConfig.script || "{}";
    
    try {
      script = new Function("return " +  script)();
    } catch (error) {      
      this.toastr.error("Invalid Template Scope Ref");
      return;
    }

    function TmpCmpConstructor() {
      Object.keys(script).forEach( key => {
        this[key] = script[key];
      });
      this.result = reportResponse;
    }
    const tmpCmp = Component({ template, styles })(new TmpCmpConstructor().constructor);
    const tmpModule = NgModule({
      imports: [CommonModule, FormsModule],
      declarations: [tmpCmp],
    })(class { });

    this.compiler.compileModuleAndAllComponentsAsync(tmpModule).then((factories) => {
      const cmpFactory = factories.componentFactories[0];
      this.cmpRef = cmpFactory.create(this.injector, [], null, this.moduleRef);
      this.vc.insert(this.cmpRef.hostView);
      if(this.cmpRef.instance.init){
        this.cmpRef.instance.init(this.cmpRef);
      }
    });
  }

  ngOnDestroy() {
    this.destroyComponent();
  }

  destroyComponent() {
    if (this.cmpRef) this.cmpRef.destroy();
  }

}