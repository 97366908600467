import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiUtilService } from '../service/api-util.service';
import { apiProperties } from '../utility/constants';
import { Utils } from '../utility/util';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  public emailId: any;
  public password: any;

  constructor(private apiUtilService: ApiUtilService,
              private toastr: ToastrService,
              private router: Router,
              private route: ActivatedRoute) { }

  ngOnInit() {
    document.title = "CCS - Login";
    this.apiUtilService.isUserLoggedIn = false;
  }

  onLoginSubmit() {
    let currentAPIProperty = apiProperties.STUDIO_LOGIN;
    let reqBody = {email: this.emailId, password: Utils.cryptoJSEncrypt(this.password)};
    this.apiUtilService.invokeStudioAPI(currentAPIProperty.path, currentAPIProperty.method, reqBody).subscribe(
      (res: any) => {
        this.route.queryParams
          .subscribe(params => {
            if(params.route) this.router.navigateByUrl(params.route);
            else this.router.navigate(['/landing']);
          }
        );
      },
      (err: any) => {
        this.toastr.error(err.error || 'Login Failed!');
        console.error(err);
      }
    );
  }
  
}
