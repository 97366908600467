import { Component } from "@angular/core";
import { Utils } from "./../utility/util";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: 'date-time-value-cell',
    template: ` <div class="position-absolute w-100 h-100 t-0 l-0 date-time-value-container text-center">
                    <div class="date-val mt-3"> {{ dateVal }} </div>
                    <span class="time-val"> {{ timeVal }} </span>
                </div>`,
    styles: [
        `.time-val {
            display: none;
        }
        .date-time-value-container:hover > .time-val{
            display: block;
        }
        .date-time-value-container:hover > .date-val{
            margin-top: .5rem !important;
        }`
    ]
})
export class DateTimeRendererComponent implements ICellRendererAngularComp {
    dateVal: string;
    timeVal: string;

    constructor() {}

    agInit(params: any): void {
        let dateTimeStr = Utils.dateTimeValueFormatter(params);
        let dateTimeStrSplit = dateTimeStr.split(" ");
        this.timeVal = dateTimeStrSplit.splice(3, 2).join(" ");
        this.dateVal = dateTimeStrSplit.join(" ");
    }

    refresh(): boolean {
        return false;
    }
}