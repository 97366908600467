import {Pipe, PipeTransform, SecurityContext} from '@angular/core';
import {DomSanitizer} from '@angular/platform-browser';

/**
 * Converts URLs to <a> elements that open said URL in another window
 * Usage example:
 *  <div [innerHTML]="value | linkify"></div>
 *
 * If you do not bind the piped data to innerHtml, your output will be escaped and the links will
 * not be clickable.
 *
 * Any HTML passed into this pipe is sanitized (to prevent XSS vulnerabilities), but not escaped.
 * In general, you should just be passing plain text into this pipe.
 */
@Pipe({name: 'linkify'})
export class LinkifyPipe implements PipeTransform {
  constructor(private readonly sanitizer: DomSanitizer) {}

  transform(text: string): string {
    const sanitized = this.sanitizer.sanitize(SecurityContext.HTML, text) || '';

    // Regexp matching http/https URLs derived from RFCs 1738 and 3986
    return sanitized.replace(
        /(https?:\/\/)+([^/?#\s]+)+([^?#\s]*)(\?([^#\s]*))?(#(\S*))?/g,
        '<a href="$&" target="_blank">$&</a>');
  }
}
