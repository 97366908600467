import { Injectable } from '@angular/core';
import { apiProperties } from '../utility/constants';
import { CommonService } from './common.service';
import { ApiUtilService } from './api-util.service';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../utility/util';
import { Router } from '@angular/router';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class UsersService {
    usersList: any[] = [];
    userObj: any = {};

    constructor(private apiUtilService: ApiUtilService, private toastr: ToastrService, private router: Router, private cs: CommonService) {
    }

    getAllUsers(successCallback: any, errorCallback: any) {
        Utils.loader('#page-loader', 'show');
        let currentAPIProperty = apiProperties.LIST_USERS;
        this.apiUtilService.invokeStudioAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
          (res: any) => {
            Utils.loader('#page-loader', 'hide');
            this.usersList = res.body || [];
            this.usersList.forEach((user, indx) => {
                user['name'] = user.userName;
                this.usersList[indx] = user;
              })
            successCallback(res);
          },
          (err: any) => {
            Utils.loader('#page-loader', 'hide');
            if(err.name == "HttpErrorResponse"){
              this.toastr.error("Something went wrong!");
            }
            errorCallback(err);
          }
        );
      }

      getUserById(id: any, successCallback: any, errorCallback: any) {
        Utils.loader('#page-loader', 'show');
        let currentAPIProperty = apiProperties.GET_USER_BY_ID;
        this.apiUtilService.invokeStudioAPI(currentAPIProperty.path.replace("{id}", id), currentAPIProperty.method).subscribe(
          (res: any) => {
            Utils.loader('#page-loader', 'hide');
            this.userObj = res.body || {};
            successCallback(res);
          },
          (err: any) => {
            Utils.loader('#page-loader', 'hide');
            if(err.name == "HttpErrorResponse"){
              this.toastr.error("Something went wrong!");
            }
            errorCallback(err);
          }
        );
      }

      createUser(data: any, successCallback: any, errorCallback: any) {
        Utils.loader('#page-loader', 'show');
        let currentAPIProperty = apiProperties.CREATE_USER;
        this.apiUtilService.invokeStudioAPI(currentAPIProperty.path, currentAPIProperty.method, data).subscribe(
          (res: any) => {
            Utils.loader('#page-loader', 'hide');
            this.toastr.success("User created successfully!");
            successCallback(res);
          },
          (err: any) => {
            Utils.loader('#page-loader', 'hide');
            if(err.name == "HttpErrorResponse"){
              this.toastr.error("Something went wrong!");
            }
            errorCallback(err);
          }
        );
      }

      updateUser(data: any, successCallback: any, errorCallback: any) {
        Utils.loader('#page-loader', 'show');
        let currentAPIProperty = apiProperties.UPDATE_USER;
          this.apiUtilService.invokeStudioAPI(currentAPIProperty.path.replace("{id}", data.id), currentAPIProperty.method, data).subscribe(
            (res: any) => {
              Utils.loader('#page-loader', 'hide');
              this.toastr.success("User updated successfully!");
              successCallback(res);
            },
            (err: any) => {
              this.toastr.error((err.error || {}).message || 'Update Failed');
              Utils.loader('#page-loader', 'hide');
              errorCallback(err);
            }
          );
      }

      deleteUserFlow(id: any, successCallback: any, errorCallback: any) {
        Utils.loader('#page-loader', 'show');
        let currentAPIProperty = apiProperties.DELETE_USER;
          this.apiUtilService.invokeStudioAPI(currentAPIProperty.path.replace("{id}", id), currentAPIProperty.method).subscribe(
            (res: any) => {
              Utils.loader('#page-loader', 'hide');
              successCallback(res);
            },
            (err: any) => {
              this.toastr.error((err.error || {}).message || 'Delete Failed');
              Utils.loader('#page-loader', 'hide');
              errorCallback(err);
            }
          );
      }

}