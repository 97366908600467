import { Component, OnInit } from "@angular/core";
import { FieldArrayType, FieldType } from "@ngx-formly/core";

@Component({
  selector: "formly-repeat-section",
  template: `
    <div *ngFor="let field of field.fieldGroup; let i = index" class="row">
      <formly-field class="col" [field]="field"></formly-field>
    </div>
  `
})
export class RepeatSectionComponent extends FieldArrayType {
  view(input: any) {
  }
}
