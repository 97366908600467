import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { UsersService } from 'src/app/service/users.service';
import { Utils } from 'src/app/utility/util';
declare var $: any;

@Component({
  selector: 'app-users-create',
  templateUrl: './users-create.component.html',
  styleUrls: ['./users-create.component.css']
})
export class UsersCreateComponent implements OnInit {

  public userId: string;
  userObj: any = {};
  private oldPassword: string;

  constructor(private route: ActivatedRoute,
              private usersService: UsersService,
              private toastr: ToastrService,) { 
    this.route.paramMap.subscribe(paramObject => {
      this.userId = (paramObject['params']['id']) || '';
    });
  }

  ngOnInit() {
    if(this.userId){
      this.usersService.getUserById(this.userId, (res: any) => {
        this.userObj = res.body || {};
        this.oldPassword = this.userObj.password;
        Utils.loader('#page-loader', 'hide');
      }, (err: any) => {
        console.log(err);
        // this.toastr.error((err.error || {}).message || 'Internal Flow (' + this.selectedRowObject.name + ') Delete Failed');
        Utils.loader('#page-loader', 'hide');
      });
    }
  }

  createUser() {
    if(this.userObj){
      if(!this.userObj.email){
        this.toastr.error("User email is required");
      } else if(!this.userObj.userName){
        this.toastr.error("User userName is required");
      } else if(!this.userObj.password){
        this.toastr.error("User password is required");
      } else {
        this.userObj.password = Utils.cryptoJSEncrypt(this.userObj.password);
        this.usersService.createUser(this.userObj, (res: any) => {
          Utils.loader('#page-loader', 'hide');
          // this.toastr.success("User created successfully!");
        }, (err: any) => {
          console.log(err);
          this.toastr.error("Create user failed!");
          // this.toastr.error((err.error || {}).message || 'Internal Flow (' + this.selectedRowObject.name + ') Delete Failed');
          Utils.loader('#page-loader', 'hide');
        });
      }
    }
  }

  updateUser() {
    if(this.userObj){
      if(!this.userObj.email){
        this.toastr.error("User email is required");
      } else if(!this.userObj.userName){
        this.toastr.error("User userName is required");
      } else if(!this.userObj.password){
        this.toastr.error("User password is required");
      } else {
        if(this.oldPassword !== this.userObj.password){
          this.userObj.password = Utils.cryptoJSEncrypt(this.userObj.password);
        }
        this.usersService.updateUser(this.userObj, (res: any) => {
          // this.userObj = res.body || {};
          Utils.loader('#page-loader', 'hide');
        }, (err: any) => {
          console.log(err);
          this.toastr.error("Update user failed!");
          // this.toastr.error((err.error || {}).message || 'Internal Flow (' + this.selectedRowObject.name + ') Delete Failed');
          Utils.loader('#page-loader', 'hide');
        });
      }
    }
  }

}
