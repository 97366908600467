import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiUtilService } from './api-util.service';
import { apiProperties } from '../utility/constants';
import { Utils } from './../utility/util';
import {JwtHelperService} from '@auth0/angular-jwt';

@Injectable({
  providedIn: 'root'
})
export class MonitorLogsService {
  
  accessToken: any;
  accessTokenValidityInSecs: any;
  processList: any = [];
  processDetails: any = {processContext: {}};
  processLogDetails: any;
  processLogDetailsById: any = {requestString: "", request: {}, response: "", responseString: {}};
  processEvent: any = {schema: {}, event: {}};
  environmentList: any = [];
  selectedEnvironmentRef = "";

  constructor(public jwtHelper: JwtHelperService, 
    private apiUtilService: ApiUtilService,
    private toastr: ToastrService,
    private router: Router) { }

    getAccessToken(successCallback) {
      if(this.accessToken && !this.jwtHelper.isTokenExpired(this.accessToken)) {
        successCallback();
        return;
      }
      this.accessToken = null;
      let currentAPIProperty = apiProperties.GENERATE_ACCESS_TOKEN;
      this.apiUtilService.invokeStudioAPI(currentAPIProperty.path, currentAPIProperty.method, "", {}).subscribe(
        (res: any) => {
          this.accessToken = res.body.access_token;
          this.accessTokenValidityInSecs = res.body.expires_in;
          successCallback();
        },
        (err: any) => {
          if(err.name == "HttpErrorResponse"){
            this.toastr.error("Something went wrong!");
          }
          Utils.loader('#page-loader', 'hide');
        } 
      );
    }
    setSelectedEnvRef(selectedEnvironment: any) {
      this.selectedEnvironmentRef = selectedEnvironment;
    }

    formatDateString(obj, key, newKey) {
      if(obj[key]) { 
        obj[newKey] = Utils.dateTimeValueFormatter({value: obj[key]}, 'MMM dd, YYYY HH:mm:ss.S');
      }
    }

    getAllProcess(filters: any, initCall: boolean, callback: any = null) {
      this.getAccessToken(() => {
        let currentAPIProperty = apiProperties.LIST_ALL_PROCESS;
        this.processList = [];
        Utils.loader('#page-loader', 'show');
        let path = currentAPIProperty.path + "?";
        if(initCall) path = path + "limit=100&";
        const params = {};
        Object.keys(filters).filter(key => ((filters[key] || "").trim() != "" && key != "environment")).forEach(key => {
          params[key] = filters[key];
        });
        path = path + new URLSearchParams(params).toString();
        let reqHeaders = {"x-access-token": this.accessToken, "x-show-secured-info": "true"};
        if(this.selectedEnvironmentRef) {
          reqHeaders[ "x-env-key"] = this.selectedEnvironmentRef;
        }
        this.apiUtilService.invokeStudioAPI(path, currentAPIProperty.method, "", reqHeaders).subscribe(
          (res: any) => {
            Utils.loader('#page-loader', 'hide');
            this.processList = res.body;
            if(callback) callback();
          },
          (err: any) => {
            if(err.name == "HttpErrorResponse"){
              this.toastr.error("Something went wrong!");
            }
            Utils.loader('#page-loader', 'hide');
            console.error(err);
          } 
        );
      });
    }

    getProcessDetailsById(processId, callback) {
      this.getAccessToken(() => {
        let currentAPIProperty = apiProperties.GET_PROCESS_DETAILS;
        Utils.loader('#page-loader', 'show');
        let path = currentAPIProperty.path.replace("{processId}", processId);
        let reqHeaders = {"x-access-token": this.accessToken, "x-show-secured-info": "true"};
        if(this.selectedEnvironmentRef) {
          reqHeaders[ "x-env-key"] = this.selectedEnvironmentRef;
        }
        this.apiUtilService.invokeStudioAPI(path, currentAPIProperty.method, "", reqHeaders).subscribe(
          (res: any) => {
            Utils.loader('#page-loader', 'hide');
            this.processDetails = res.body;
            callback();
          },
          (err: any) => {
            if(err.name == "HttpErrorResponse"){
              this.toastr.error("Something went wrong!");
            }
            Utils.loader('#page-loader', 'hide');
            console.error(err);
          } 
        );
      });
    }

    getProcessLogDetails(processId, callback) {
      this.getAccessToken(() => {
        let currentAPIProperty = apiProperties.GET_PROCESS_LOG;
        this.processLogDetails = null;
        Utils.loader('#page-loader', 'show');
        let path = currentAPIProperty.path.replace("{processId}", processId);
        let reqHeaders = {"x-access-token": this.accessToken, "x-show-secured-info": "true"};
        if(this.selectedEnvironmentRef) {
          reqHeaders[ "x-env-key"] = this.selectedEnvironmentRef;
        }
        this.apiUtilService.invokeStudioAPI(path, currentAPIProperty.method, "", reqHeaders).subscribe(
          (res: any) => {
            Utils.loader('#page-loader', 'hide');
            this.processLogDetails = res.body;
            if(callback) callback();
          },
          (err: any) => {
            if(err.name == "HttpErrorResponse"){
              this.toastr.error("Something went wrong!");
            }
            Utils.loader('#page-loader', 'hide');
            console.error(err);
          } 
        );
      });
    }

    getProcessLogDetailsById(processId, stepId, callback) {
      this.getAccessToken(() => {
        let currentAPIProperty = apiProperties.GET_PROCESS_LOG_TRACE_DETAILS;
        Utils.loader('#page-loader', 'show');
        let path = currentAPIProperty.path.replace("{processId}", processId).replace("{stepId}", stepId);;
        let reqHeaders = {"x-access-token": this.accessToken, "x-show-secured-info": "true"};
        if(this.selectedEnvironmentRef) {
          reqHeaders[ "x-env-key"] = this.selectedEnvironmentRef;
        }
        this.apiUtilService.invokeStudioAPI(path, currentAPIProperty.method, "", reqHeaders).subscribe(
          (res: any) => {
            Utils.loader('#page-loader', 'hide');
            this.processLogDetailsById = res.body;
            this.processLogDetailsById.requestString = "";
            if(typeof(this.processLogDetailsById.request) == "object") {
              this.processLogDetailsById.requestString = JSON.stringify(this.processLogDetailsById.request, null, 3);
            }
            this.processLogDetailsById.responseString = "";
            if(typeof(this.processLogDetailsById.response) == "object") {
              this.processLogDetailsById.responseString = JSON.stringify(this.processLogDetailsById.response, null, 3);
            }
            this.processLogDetailsById.requestHeaderString = "";
            if(typeof(this.processLogDetailsById.requestHeaders) == "object") {
              this.processLogDetailsById.requestHeaderString = JSON.stringify(this.processLogDetailsById.requestHeaders, null, 3);
            }
            this.processLogDetailsById.responseHeaderString = "";
            if(typeof(this.processLogDetailsById.responseHeaders) == "object") {
              this.processLogDetailsById.responseHeaderString = JSON.stringify(this.processLogDetailsById.responseHeaders, null, 3);
            }
            if(callback) callback();
          },
          (err: any) => {
            if(err.name == "HttpErrorResponse"){
              this.toastr.error("Something went wrong!");
            }
            Utils.loader('#page-loader', 'hide');
            console.error(err);
          } 
        );
      });
    }

    getEventLog(eventId, callback) {
      this.getAccessToken(() => {
        let currentAPIProperty = apiProperties.GET_PROCESS_EVENT;
        Utils.loader('#page-loader', 'show');
        let path = currentAPIProperty.path.replace("{eventId}", eventId);
        let reqHeaders = {"x-access-token": this.accessToken, "x-show-secured-info": "true"};
        if(this.selectedEnvironmentRef) {
          reqHeaders[ "x-env-key"] = this.selectedEnvironmentRef;
        }
        this.apiUtilService.invokeStudioAPI(path, currentAPIProperty.method, "", reqHeaders).subscribe(
          (res: any) => {
            Utils.loader('#page-loader', 'hide');
            this.processEvent = res.body;
            if(callback) callback();
          },
          (err: any) => {
            if(err.name == "HttpErrorResponse"){
              this.toastr.error("Something went wrong!");
            }
            Utils.loader('#page-loader', 'hide');
            console.error(err);
          } 
        );
      });
    }

    getEnvironmentList(callback: any = null) {
      this.getAccessToken(() => {
        let currentAPIProperty = apiProperties.GET_ENV_LIST;
        Utils.loader('#page-loader', 'show');
        let path = currentAPIProperty.path;
        let reqHeaders = {"x-access-token": this.accessToken};
        this.apiUtilService.invokeStudioAPI(path, currentAPIProperty.method, "", reqHeaders).subscribe(
          (res: any) => {
            Utils.loader('#page-loader', 'hide');
            this.environmentList = res.body;
            if(callback) callback();
          },
          (err: any) => {
            if(err.name == "HttpErrorResponse"){
              this.toastr.error("Something went wrong while retrieving the ");
            }
            Utils.loader('#page-loader', 'hide');
            console.error(err);
          } 
        );
      });
    }
}
