import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChild, ViewChildren, SimpleChanges } from '@angular/core';
import { AnalyticsStudioService } from '../analytics-studio.service';
import onChange from 'on-change';
import { Utils } from "../../utility/util";
declare var $: any;

@Component({
  selector: 'ccp-chart-properties',
  templateUrl: './chart-properties.component.html',
  styleUrls: ['./chart-properties.component.css']
})
export class ChartPropertiesComponent implements OnInit {

  @Output() rerenderPeviewEvent = new EventEmitter<any>();
  @ViewChild('generalPopover', { static: false }) generalPopover: any;
  @ViewChild('chartSeriesPopover', { static: false }) chartSeriesPopover: any;
  @ViewChild('valueLabelPopover', { static: false }) valueLabelPopover: any;
  @ViewChild('axesPopover', { static: false }) axesPopover: any;
  @ViewChild('chart3dPopover', { static: false }) chart3dPopover: any;z
  @ViewChild('chartLegendPopover', { static: false }) chartLegendPopover: any;
  @ViewChild('mapLegendPopover', { static: false }) mapLegendPopover: any;
  @ViewChild('tooltipPopover', {static:false}) tooltipPopover: any;
  @ViewChild('advanceChartPopover', { static: false}) advanceChartPopover: any;
  @ViewChild('drillDownPopover', { static: false}) drillDownPopover: any;
  @ViewChild('colorAxisPopover', { static: false}) colorAxisPopover: any;
  @ViewChild('tableGeneralPopover', { static: false}) tableGeneralPopover: any;
  @ViewChild('tableHeaderPopover', { static: false}) tableHeaderPopover: any;
  @ViewChild('tableBodyPopover', { static: false}) tableBodyPopover: any;
  @ViewChild('textarea_reportScriptEditor', { static: false }) editor: any;
  

  backupData: any = {};
  generalConfig: any = {title: {style: {}, text: ""}, subtitle: {style: {}, text: ""}};
  chartSeries: any = { colors: [] };
  dataLabels: any = {style:{color:'#000',fontSize:'12px', textOutline:'1px 1px contrast'}};
  chart3DOptions: any = {};
  chartLegend: any = { title: {}, itemStyle:{} };
  mapLegend: any = {};
  tooltip:any =  {};
  advancedChartConfig: any = {};
  drilldownInfo: any = {enabled: false, apiId: "", entity: ""};
  tableHeaderTheme: any = {};
  tableBodyTheme: any = {};
  tableGeneralTheme:any = {};
  colorAxis:any = {};
  axes: any = {xAxis: {labels: {format: "{value}"}}, yAxis: {labels: {format: "{value}"}}};

  headerTableBold:any = false;

  private dataLabelsDefault = {
    style:{
      color:'#000',
      fontSize:'14px',
      textOutline:'1px 1px contrast'
    }
  };

  private chartLegendDefault: any = {
      enabled: true,
      itemStyle: {
          color: "#000",
          fontSize: "14px",
          fontFamily: "Montserrat-SemiBold",
      },
      "align": "center",
      "layout": "horizontal",
      "x": 0,
      "verticalAlign": "bottom",
      "y": 0,
      "borderWidth": 0,
      "borderRadius": 0,
      "backgroundColor": "#ffffff",
  }

  private advancedChartConfigTooltip = {
  "enabled":true,
  "outside": false,
  "backgroundColor": "#ffffff",
  "borderColor": "#000000",
  "borderRadius": 2,
  "borderWidth": 2
  };
  defaultColors: any = ["#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1"];
  openedMenuName: string = null;
  fontFamilyArr: any[] = ['Courier', 'Georgia', 'Verdana', 'Comic Sans MS', 'Impact', 'Monospace', 'Montserrat-SemiBold', 'Tahoma'];
  fontSizeArr: any[] = ["8px","9px","10px","11px","12px","13px","14px","15px","16px","17px","18px","19px","20px","21px","22px","23px","24px","25px","26px","27px","28px","29px","30px","31px","32px","33px","34px"];
  zoomArr: any[] = ['x', 'y', 'xy', 'auto'];
  range:any[] =  [...Array(100).keys()];
  defaultOptions3d: any = { enabled: false, alpha: 0, beta: 0, depth: 0, viewDistance: 0 };
  defaultTableGenralTheme: any = { fontFamily: "Montserrat-SemiBold", sort: true, columnFilter: true, pagination: true }

  advancedScriptInfo: any = {
    mode: "javascript",
    script_prefix: "function execute(chartObject, records){\n",
    script_body: "  " +
            "\n  " +
            "\n  /*" +
            "\n    Write Business Logic Here..." +
            "\n  */" +
            "\n  " +
            "\n  return chartObject;",
    script_suffix: "\n}",	
  }

  customScript: any = "";
  
  chartScriptOption = {
    mode: "javascript",
    theme: 'blackboard',
    lineNumbers: true,
    lineWrapping: true,
    foldGutter: true,
    scrollbarStyle: 'overlay',
    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers', 'breakpoints'],
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true,
    viewEditorDelay: 300
  }

  constructor(public ats: AnalyticsStudioService) { }
  ngOnInit() {}

  subscribe(type:string, scopeKey: string){
    var _this = this;
    this[scopeKey] = onChange(this[scopeKey], function (path, value, previousValue, applyData) {
      _this.triggerPreview(type, JSON.parse(JSON.stringify(this)));
    });
  }

  triggerPreview(type: string, value: any){
    this.rerenderPeviewEvent.emit({ type, value });
  }

  unsubscribe(key: string){
    onChange.unsubscribe(this[key]);
  }

  iconOperation(menuName: string){
    if(this.openedMenuName == menuName) this.closePopover(menuName);
    else if(this.openedMenuName == null){
      this.openPopover(menuName);
    }else{
      this.closePopover(this.openedMenuName);
      this.openPopover(menuName);
    }
  }

  openPopover(menuName: string) {
    this.backupData = null;
    var analyticsConfGraphical = this.ats.analyticsConfGraphical;
    switch (menuName) {
      case 'general':
        this.generalConfig.title =  Utils.cloneJSON(analyticsConfGraphical.title || {});
        this.generalConfig.title.style = this.generalConfig.title.style || {};
        this.generalConfig.title.text = this.generalConfig.title.text || "";
        this.generalConfig.subtitle = Utils.cloneJSON(analyticsConfGraphical.subtitle || {});
        this.generalConfig.subtitle.style = this.generalConfig.subtitle.style || {};
        this.generalConfig.subtitle.text = this.generalConfig.subtitle.text || "";
        this.generalConfig.zoomType = Utils.cloneJSON(analyticsConfGraphical.chart.zoomType || {});
        this.backupData = Utils.cloneJSON(this.generalConfig);
        this.subscribe(menuName, "generalConfig");
        this.generalPopover.show();
        break;
      case 'series':
        this.chartSeries = Utils.cloneJSON({ applySeriesClrToAxis: this.ats.analyticsConfGraphAddDet.applySeriesClrToAxis || false, colors: analyticsConfGraphical.colors || this.defaultColors });
        this.backupData = Utils.cloneJSON(this.chartSeries);
        this.subscribe(menuName, "chartSeries");
        this.chartSeriesPopover.show();
        break;
      case 'valueLabel':
        this.dataLabels = this.ats.analytics.analyticsType != 'heatmap' ? Utils.cloneJSON({enabled: analyticsConfGraphical.plotOptions.series.dataLabels.enabled || false ,style: analyticsConfGraphical.plotOptions.series.dataLabels.style || this.dataLabelsDefault.style }) : Utils.cloneJSON({enabled: analyticsConfGraphical.series[0].dataLabels.enabled || false ,style: analyticsConfGraphical.series[0].dataLabels.style || this.dataLabelsDefault.style });
        this.dataLabels.style = this.dataLabels.style || {};
        this.backupData = Utils.cloneJSON(this.dataLabels);
        this.subscribe(menuName, 'dataLabels');
        this.valueLabelPopover.show();
        break;
      case 'axes':
        this.axes.xAxis = Utils.cloneJSON(analyticsConfGraphical.xAxis || {});
        this.axes.xAxis.labels.format = this.axes.xAxis.labels.format || '{value}';
        this.axes.yAxis = Utils.cloneJSON(analyticsConfGraphical.yAxis || {});
        this.axes.yAxis.labels.format = this.axes.yAxis.labels.format || '{value}';
        this.backupData = Utils.cloneJSON(this.axes);
        this.subscribe(menuName, 'axes');
        this.axesPopover.show();
        break;
      case 'chart3d':
        this.chart3DOptions = Utils.cloneJSON(analyticsConfGraphical.chart.options3d || this.defaultOptions3d); 
        this.chart3DOptions.thickness = analyticsConfGraphical.plotOptions.series.depth || 0;
        this.backupData = Utils.cloneJSON(this.chart3DOptions);
        this.subscribe(menuName, "chart3DOptions");
        this.chart3dPopover.show();
        break;
      case 'chartLegend':
        this.chartLegend = Utils.cloneJSON(analyticsConfGraphical.legend.hasOwnProperty("layout") ? analyticsConfGraphical.legend : this.chartLegendDefault);
        this.chartLegend.title = this.chartLegend.title || {}; 
        this.backupData = Utils.cloneJSON(this.chartLegend);
        this.subscribe(menuName, "chartLegend");
        this.chartLegendPopover.show();
        break;
      case 'mapLegend':
        this.mapLegend = Utils.cloneJSON(analyticsConfGraphical.legend);
        this.backupData = Utils.cloneJSON(this.mapLegend);
        this.subscribe(menuName, "mapLegend");
        this.mapLegendPopover.show();
        break;
      case 'tooltip':
        this.tooltip = Utils.cloneJSON(analyticsConfGraphical.tooltip|| this.advancedChartConfigTooltip);
        this.backupData = Utils.cloneJSON(this.tooltip);
        this.subscribe(menuName, "tooltip");
        this.tooltipPopover.show();
        break;
      case 'advanceChart':
        this.advancedChartConfig = Utils.cloneJSON({customScript: this.ats.analyticsConfGraphAddDet.customScript || "", tooltip:  analyticsConfGraphical.tooltip|| this.advancedChartConfigTooltip });
        this.backupData = Utils.cloneJSON(this.advancedChartConfig);
        this.subscribe(menuName, "advancedChartConfig");
        this.advanceChartPopover.show();
        break;
      case 'drilldown':
        this.drilldownInfo = Utils.cloneJSON(this.ats.analyticsConfGraphAddDet.drilldownInfo || {});
        this.drilldownInfo.enabled = this.drilldownInfo.apiId != null && this.drilldownInfo.apiId != "";
        this.backupData = Utils.cloneJSON(this.drilldownInfo);
        this.drillDownPopover.show();
        break;
      case 'colorAxis':
        this.colorAxis = Utils.cloneJSON( analyticsConfGraphical.colorAxis || {});
        this.backupData = Utils.cloneJSON(this.colorAxis);
        this.subscribe(menuName, "colorAxis");
        this.colorAxisPopover.show();
        break;
      
      case 'tableGeneral':
        this.tableGeneralTheme = Utils.cloneJSON(this.ats.analyticsConfGrid.theme.general || this.defaultTableGenralTheme);
        this.backupData = Utils.cloneJSON(this.tableGeneralTheme);
        this.subscribe(menuName, "tableGeneralTheme");
		    this.tableGeneralPopover.show();
        break;
      case 'tableHeader':
        this.tableHeaderTheme = Utils.cloneJSON(this.ats.analyticsConfGrid.theme.header || {});
        this.backupData = Utils.cloneJSON(this.tableHeaderTheme);
        this.subscribe(menuName, "tableHeaderTheme");
        this.tableHeaderPopover.show();
        break;
      case 'tableBody':
        this.tableBodyTheme = Utils.cloneJSON(this.ats.analyticsConfGrid.theme.body || {});
        this.tableBodyTheme.rows = this.tableBodyTheme.rows || {};
        this.backupData = Utils.cloneJSON(this.tableBodyTheme);
        this.subscribe(menuName, "tableBodyTheme");
        this.tableBodyPopover.show();
        break;
      default:
        break;
    }
    this.openedMenuName = menuName;
  }

  closePopover(menuName: string) {
    switch (menuName) {
      case 'general':
        this.unsubscribe("generalConfig");
        if(this.backupData) this.triggerPreview(menuName, this.backupData);
        this.generalPopover.hide();
        break;
      case 'series':
        this.unsubscribe("chartSeries");
        if(this.backupData) this.triggerPreview(menuName, this.backupData);
        this.chartSeriesPopover.hide();
        break;
      case 'valueLabel':
        this.unsubscribe('dataLabels');
        if(this.backupData) this.triggerPreview(menuName, this.backupData);
        this.valueLabelPopover.hide();
        break;
      case 'axes':
        this.unsubscribe('axes');
        if(this.backupData) this.triggerPreview(menuName, this.backupData);
        this.axesPopover.hide();
        break;
      case 'chart3d':
        this.unsubscribe("chart3DOptions");
        if(this.backupData) this.triggerPreview(menuName, this.backupData);
        this.chart3dPopover.hide();
        break;
      case 'chartLegend':
        this.unsubscribe("chartLegend");
        if(this.backupData) this.triggerPreview(menuName, this.backupData);
        this.chartLegendPopover.hide();
        break;
      case 'mapLegend':
        this.unsubscribe("mapLegend");
        if(this.backupData) this.triggerPreview(menuName, this.backupData);
        this.mapLegendPopover.hide();
        break;
      case 'drilldown':
        this.drillDownPopover.hide();
        break;
      case 'tooltip':
        this.unsubscribe("tooltip");
        if(this.backupData) this.triggerPreview(menuName, this.backupData);
        this.tooltipPopover.hide();
        break;
      case 'advanceChart':
        this.unsubscribe("advancedChartConfig");
        if(this.backupData) this.triggerPreview(menuName, this.backupData);
        this.advanceChartPopover.hide();
        break;
      case 'colorAxis':
        this.unsubscribe("colorAxis");
        if(this.backupData) this.triggerPreview(menuName, this.backupData);
        this.colorAxisPopover.hide();
        break;
      case 'tableGeneral':
        this.unsubscribe("tableGeneralTheme");
        if(this.backupData) this.triggerPreview(menuName, this.backupData);
        this.tableGeneralPopover.hide();
        break;
      case 'tableHeader':
        this.unsubscribe("tableHeaderTheme");
        if(this.backupData) this.triggerPreview(menuName, this.backupData);
        this.tableHeaderPopover.hide();
        break;
      case 'tableBody':
        this.unsubscribe("tableBodyTheme");
        if(this.backupData) this.triggerPreview(menuName, this.backupData);
        this.tableBodyPopover.hide();
        break;
    }
    this.openedMenuName = null;
  }

  addProperty(menuName: string) {
    var analyticsConfGraphical = this.ats.analyticsConfGraphical;
    switch (menuName) {
      case 'general':
        analyticsConfGraphical.title = Utils.cloneJSON(this.generalConfig.title);
        analyticsConfGraphical.subtitle = Utils.cloneJSON(this.generalConfig.subtitle);
        analyticsConfGraphical.chart.zoomType = Utils.cloneJSON(this.generalConfig.zoomType);
        break;
      case 'series':
        let clonedChartSeries = Utils.cloneJSON(this.chartSeries);
        this.ats.analyticsConfGraphAddDet.applySeriesClrToAxis = clonedChartSeries.applySeriesClrToAxis;
        analyticsConfGraphical.colors = clonedChartSeries.colors;
        break;
      case 'valueLabel':
        this.ats.analytics.analyticsType != 'heatmap' ? analyticsConfGraphical.plotOptions.series.dataLabels  = Utils.cloneJSON(this.dataLabels) : analyticsConfGraphical.series[0].dataLabels  = Utils.cloneJSON({enabled: this.dataLabels.enabled || false , color: this.dataLabels.style.color || "#000" ,style: this.dataLabels.style || this.dataLabelsDefault.style });
        break;
      case 'axes':
        analyticsConfGraphical.xAxis = Utils.cloneJSON(this.axes.xAxis);
        analyticsConfGraphical.yAxis = Utils.cloneJSON(this.axes.yAxis);
        break;
      case 'chart3d':
        if(this.chart3DOptions.thickness > 0) analyticsConfGraphical.plotOptions.series.depth = this.chart3DOptions.thickness;
        else delete analyticsConfGraphical.plotOptions.series.depth;
        delete this.chart3DOptions.thickness;
        analyticsConfGraphical.chart.options3d = Utils.cloneJSON(this.chart3DOptions);
        if(analyticsConfGraphical.chart.type === 'Pie'){
          var chartPlotOptions = analyticsConfGraphical.plotOptions =  analyticsConfGraphical.plotOptions || {};
          chartPlotOptions.pie = { depth : analyticsConfGraphical.chart.options3d.depth };
        }
        break;
      case 'chartLegend':
        analyticsConfGraphical.legend = Utils.cloneJSON(this.chartLegend);
        break;
      case 'mapLegend':
        analyticsConfGraphical.legend = Utils.cloneJSON(this.mapLegend);
        break;
      case 'drilldown':
        if(this.drilldownInfo.enabled){
          this.ats.analyticsConfGraphAddDet.drilldownInfo = Utils.cloneJSON(this.drilldownInfo);
          delete this.ats.analyticsConfGraphAddDet.drilldownInfo.enabled;
        }else{
          delete this.ats.analyticsConfGraphAddDet.drilldownInfo;
        }
        break;
      case 'tooltip':
        analyticsConfGraphical.tooltip = Utils.cloneJSON(this.tooltip);
        break;
      case 'advanceChart':
        this.ats.analyticsConfGraphAddDet.customScript = this.advancedChartConfig.customScript;
        break;
      case 'colorAxis':
        analyticsConfGraphical.colorAxis  = Utils.cloneJSON(this.colorAxis);
        break;
      case 'tableGeneral':
        this.ats.analyticsConfGrid.theme.general = this.tableGeneralTheme;
        break;
      case 'tableHeader':
        this.ats.analyticsConfGrid.theme.header = this.tableHeaderTheme;
        break;
      case 'tableBody':
        this.ats.analyticsConfGrid.theme.body = this.tableBodyTheme;
        break;
    }
    this.backupData = null;
    this.closePopover(menuName);
  }

  // Advanced Chart Script Code Start here

  showScriptEditor(){
    this.initReadOnlyLines(this.editor);
    this.setScriptValue("ChartScript", this.customScript || '', false, this.editor);
    $("#scriptEditorModal").modal("show");
  }

  saveScript() {
    this.advancedChartConfig.customScript = this.getScriptValue(null, this.editor);
    $("#scriptEditorModal").modal("hide");
  }

  initReadOnlyLines(editor: any) {
    var currentEditor = editor;
    var opt: any = currentEditor.codeMirror.options;
    var _readOnlyLineInfo = {};
    (opt.readOnlyLine || []).forEach((lineNum: number) => { _readOnlyLineInfo[lineNum] = "ALL"; });
    Object.keys(opt.readOnly_partialLine || {}).forEach((lineNum) => { _readOnlyLineInfo[lineNum] = opt.readOnly_partialLine[lineNum]; });
    var _isReadOnlyLine = function(from: any, to: any, lineCount: number){
      var startLine = from.line;
      var endLine = to.line;
      var startCh = from.ch;
      var endCh = to.ch;
      var startLineInfo_1 = _readOnlyLineInfo[startLine + 1];
      var startLineInfo_2 = _readOnlyLineInfo[startLine - lineCount];
      var endLineInfo_1 = _readOnlyLineInfo[endLine + 1];
      var endLineInfo_2 = _readOnlyLineInfo[endLine - lineCount];
      if(startLineInfo_1 == "ALL" || startLineInfo_1 > startCh || startLineInfo_2 == "ALL" || startLineInfo_2 > startCh ||
          endLineInfo_1 == "ALL" || endLineInfo_1 > endCh || endLineInfo_2 == "ALL" || endLineInfo_2 > endCh){
        return true;
      }
      return false;
    };
    // listen for the beforeChange event, test the changed line number, and cancel
    currentEditor.codeMirror.on('beforeChange',function(cm: any, change: any) {
      if(change.origin != "setValue"){
        if(_isReadOnlyLine(change.from, change.to, cm.lineCount())) change.cancel();
      }
    });
  }

  setScriptValue(scriptType: string, value: string, isAsyncExecution: boolean, editor: any) {
    var currentEditor: any;
    currentEditor = editor;
    var scriptInfo = this.advancedScriptInfo;
    if(scriptInfo){
      var script_prefix = scriptInfo.script_prefix;
      if(isAsyncExecution && scriptInfo.async_script_prefix){
        script_prefix = scriptInfo.async_script_prefix;
      }
      value = script_prefix + ((value || "") != "" ? value : scriptInfo.script_body) + scriptInfo.script_suffix;
      setTimeout(() => currentEditor.codeMirror.refresh(), 200);
      currentEditor.codeMirror.setValue(value);
    }
  }

  getScriptValue(excludeLineList?: number[], editor?:any) {
    var currentEditor = editor;
    excludeLineList = excludeLineList || (currentEditor.codeMirror.options || {}).excludeLine_getValue || [];
    var valueList = currentEditor.codeMirror.getValue(false);
    if(excludeLineList.length > 0){
      var totalLine = valueList.length;
      var updatedValueList = [];
      valueList.forEach((value: any, index: number) => {
        if(excludeLineList.indexOf(index + 1) == -1 && excludeLineList.indexOf(index - totalLine) == -1){
          updatedValueList.push(value);
        }
      });
      return updatedValueList.join("\n");
    }else{
      return valueList.join("\n");
    }
  }

  updateTitleStyle(titleType: any, attr: any, value: any){
    this.generalConfig[titleType]['style'][attr] != '' && this.generalConfig[titleType]['style'][attr] != undefined ? this.generalConfig[titleType]['style'][attr] = '': this.generalConfig[titleType]['style'][attr] = value;
  }

  updatelegendStyle(attr: any, value: any){
    this.chartLegend['itemStyle'][attr] != '' && this.chartLegend['itemStyle'][attr] != undefined ? this.chartLegend['itemStyle'][attr] = '': this.chartLegend['itemStyle'][attr] = value;
  }

  updateHeaderTableBold(){
    this.headerTableBold = !this.headerTableBold;
    this.headerTableBold ? this.tableHeaderTheme.fontWeight = "bold" : this.tableHeaderTheme.fontWeight = '';
  }

  setDefaultRows(){
    if(!this.tableBodyTheme.rows.needAlternalteRowTheme){
      this.tableBodyTheme.rows.evenBgColor = "#fff";
      this.tableBodyTheme.rows.evenColor = "#0F1642";
    }
  }

  updateAxesStyle(axis: any,titleType: any, attr: any, value: any){
    this.axes[axis][titleType]['style'][attr] != '' && this.axes[axis][titleType]['style'][attr] != undefined ? this.axes[axis][titleType]['style'][attr] = '': this.axes[axis][titleType]['style'][attr] = value;
  }
}
