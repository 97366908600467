import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
 selector: 'formly-custom-iframe',
 template: `
 <!-- <button (click)="onClick()">Refresh</button> -->
  <iframe
    [src]="field.templateOptions?.attributes?.src | safe : 'resourceUrl'"
    [height]="field?.templateOptions?.attributes?.height"
    [width]="field?.templateOptions?.attributes?.width"
  ></iframe>
 `,
})
export class FormlyCustomIframe extends FieldType {
  // onClick = () => {
  //   const attr = this.to.attributes as any;
  //   if (attr && attr.hasOwnProperty('iframeCallback') && typeof attr.iframeCallback === 'function') {
  //     attr.iframeCallback();
  //   }
  // };
}