import { Component } from "@angular/core";
import { Utils } from "./../utility/util";
import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
    selector: 'process-list-default-text-cell',
    template: `<span [title]="updatedCellValue"> {{updatedCellValue}} </span>`,
    styles: []
})
export class DefaultTextCellRendererComponent implements ICellRendererAngularComp {
    public updatedCellValue: any;

    constructor() {}

    agInit(params: any): void {
        const data: any = params.value;
        this.updatedCellValue = data != undefined && data != null ? Utils.escapeHtml(data) : '-';
        if(params.column.colId === 'isActive'){
            this.updatedCellValue = data ? 'ACTIVE' : 'INACTIVE';
        }
    }

    refresh(): boolean {
        return false;
    }
}