import { Component, OnInit, ViewChild } from '@angular/core';
import { AnalyticsStudioService } from './../analytics-studio.service';
import { CcpAnalyticsLandingComponent } from 'src/app/reports/ccp-analytics-landing/ccp-analytics-landing.component';

@Component({
  selector: 'ccp-analytics-preview',
  templateUrl: './analytics-preview.component.html',
  styleUrls: ['./analytics-preview.component.css']
})
export class AnalyticsPreviewComponent implements OnInit {
  
  @ViewChild(CcpAnalyticsLandingComponent, { static: false }) landingRef: CcpAnalyticsLandingComponent;
  filterList: any[] = [];
  filterReqObj: any = {};
  showPreviewLandingComp: boolean = true;
  showErrorRetryBanner: boolean = false;
  gridResponseData: any;
  gridResponseReturnFields: any;

  constructor(public ats: AnalyticsStudioService) { }

  ngOnInit() {
    var analyticsMetadata = this.ats.existingAnalytics.metadata;
    this.filterList = analyticsMetadata[analyticsMetadata.type == 'API' ? 'apiDetails' : 'dbDetails'].filters || [];
    this.filterList.forEach((filterObj) => {
      if(filterObj.isRangeSelector) filterObj.enteredValue = ['', ''];
    });
    this.init();
  }

  renderPreviewLandingComp() {
    this.showPreviewLandingComp = false;
    this.buildFilterReqObj();
    setTimeout(() => {
      this.showPreviewLandingComp = true;
    }, 0);
  }

  init() {
    if(!this.ats.showChartPreviewOverlay) {
      this.renderPreviewLandingComp();
    }
  }

  executeAnalytics() {
    this.ats.showChartPreviewOverlay = false;
    this.renderPreviewLandingComp();
  }

  buildFilterReqObj() {
    this.filterReqObj = {};
    this.filterList.forEach(filterObj => {
      this.filterReqObj[filterObj.attr] = filterObj.enteredValue;
    });
  }

  clearFilter() {
    this.filterList.forEach((filterObj) => {
      if(filterObj.isRangeSelector) filterObj.enteredValue = ['', ''];
      else filterObj.enteredValue = '';
    });
  }

  gridResponse(event: any) {
    if(event && event.results){
      this.gridResponseData = event.results.gridResponse || [];
      this.gridResponseReturnFields = event.returnField || [];
    }
  }

}
