import { deepCopy, isPlainObject } from './object-helpers';

export function _get(
  obj: any,
  path: string | string[],
  defaultValue?: any
): any {
  if (!obj) return defaultValue as any;
  const paths = Array.isArray(path) ? path : path.split(".");
  const key = paths.splice(0, 1)[0];
  const value = obj.hasOwnProperty(key) ? obj[key] : undefined;
  return paths.length === 0
    ? value
    : value && paths[0]
      ? _get(value, paths)
      : defaultValue;
}

export function isEmptyValue(value: any): boolean {
  return (
    value === "" ||
    value === null ||
    value === undefined ||
    (Array.isArray(value) && value.length === 0) ||
    (typeof value === "object" && Object.keys(value).length === 0)
  );
}

export function deepMergeValue<T extends {}>(target: T, ...sources: Array<Partial<T>>): T {
  for (const obj of sources) {
    for (const prop of Object.keys(obj)) {
      const newVal = Reflect.get(obj, prop);
      const oldVal = Reflect.get(target, prop);

      if (isPlainObject(newVal) && isPlainObject(oldVal)) {
        Reflect.set(target, prop, deepMergeValue(oldVal, newVal));
      } else if (Array.isArray(newVal) && Array.isArray(oldVal)) {
        const len = Math.max(oldVal.length, newVal.length);
        (target as any)[prop] = [];
        for (let i = 0; i < len; i++) {
          const newValI = newVal[i];
          const oldValI = oldVal[i];
          if ((isPlainObject(newValI) && isPlainObject(oldValI)) || (Array.isArray(newValI) && Array.isArray(oldValI))) {
            (target as any)[prop].push(deepMergeValue(oldValI, newValI));
          } else if(isPlainObject(newValI) || Array.isArray(newValI)) {
            (target as any)[prop].push(deepCopy(newValI));
          } else {
            (target as any)[prop].push(getNotEmptyValue(oldValI, newValI));
          }
        }
      } else if (isPlainObject(newVal)) {
        Reflect.set(target, prop, deepCopy(newVal));
      } else if (Array.isArray(newVal)) {
        Reflect.set(target, prop, deepCopy(newVal));
      } else {
        Reflect.set(target, prop, getNotEmptyValue(oldVal, newVal));
      }
    }
  }

  return target;
}

export function isNotEmptyValue(value: any): boolean {
  return !isEmptyValue(value);
}

export function getNotEmptyValue<T = any>(oldVal: T, newVal: T): T {
  if (isNotEmptyValue(oldVal) && isNotEmptyValue(newVal)) {
    if (typeof oldVal === 'string' && typeof newVal === 'string') {
      return oldVal === '' || oldVal === '0' ? newVal : oldVal;
    } else if (typeof oldVal === 'number' && typeof newVal === 'number') {
      return oldVal === 0 ? newVal : oldVal;
    } else if (typeof oldVal === 'boolean' && typeof newVal === 'boolean') {
      return newVal;
    }

  } else if (isNotEmptyValue(oldVal)) {
    return oldVal;
  }

  return newVal;
}