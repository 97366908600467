import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { AnalyticsStudioService } from '../analytics-studio.service';
import { apiProperties } from '../../utility/constants';
import { ApiUtilService } from '../../service/api-util.service';
import { Utils } from '../../utility/util';
import { DataSource } from '@angular/cdk/collections';
declare var $: any;

@Component({
  selector: 'ccp-data-source',
  templateUrl: './data-source.component.html',
  styleUrls: ['./data-source.component.css']
})
export class DataSourceComponent implements OnInit {

  @ViewChild('nativeQueryEditor', { static: true }) editor: any;
  @Output() attrDataEmitter = new EventEmitter<Event>();

  constructor(public ats: AnalyticsStudioService, private apiUtilService: ApiUtilService) { }

  public nativeQuery: any = "";
  codemirrorOptions: any = {
    theme: 'blackboard',
    mode: 'text/x-mysql',
    lineNumbers: true,
    lineWrapping: true,
    foldGutter: true,
    scrollbarStyle: 'overlay',
    gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers', 'breakpoints'],
    extraKeys: { "Ctrl-Space": "autocomplete" },
    autoCloseBrackets: true,
    matchBrackets: true,
    lint: true,
    viewEditorDelay: 300
  }

  ngOnInit() {
  }

  switchSourceType(type: string){
    if(this.ats.analyticsMetadata.type != type){
      this.ats.analyticsMetadata.type = type;
      switch(type){
        case "API":
          this.updateAPIDimensions();
          break;
        case "DATABASE":
          this.getDBTablesList();
          break;
        default: 
          //TODO: show toaster error msg ("Invalid Data Source");
      }
    }
  }

  getDBTablesList() {
    if(!this.ats.dbTablesList) {
      Utils.loader('#page-loader', 'show');
      this.ats.dbTablesList = [];
      let currentAPIProperty = apiProperties.GET_REPORT_SCHEMA_TABLES;
      this.apiUtilService.invokeCloudAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
        (res: any) => {
          this.ats.dbTablesList = res.body || [];
          this.getDBColumnDetails();
          Utils.loader('#page-loader', 'hide');
        },
        (err: any) => {
          Utils.loader('#page-loader', 'hide');
          console.error(err);
        }
      );
    } else this.getDBColumnDetails();
  }

  addEntity(dataSource: any, index: number) {
    dataSource.splice(index + 1, 0, {});
  }

  deleteEntity(dataSource: any, index: number) {
    dataSource.splice(index, 1);
    if(index == 0 && dataSource.length > 0){
      delete dataSource[0].sourceAttr;
      delete dataSource[0].targetAttr;
    }
    this.getDBColumnDetails();
  }

  dbEntityChange(index) {
    this.ats.analyticsDBDetails.dataSource[index].alias = (this.ats.analyticsDBDetails.dataSource[index].objectName || "").substring(0, 3); 
    this.getDBColumnDetails();
  }

  getDBColumnDetails() {
    var dataSource = this.ats.analyticsDBDetails.dataSource;
    var count = 0;
    var triggerUpdateDBDimention = () => {
      if(++count == dataSource.length){
        Utils.loader('#page-loader', 'hide');
        this.updateDBDimensions();
      }
    };
    dataSource.forEach(dataSource => {
      var tableName = dataSource.objectName;
      if(tableName) {
        if(this.ats.dbColumnDetails[tableName]) triggerUpdateDBDimention();
        else {
          Utils.loader('#page-loader', 'show');
          let currentAPIProperty = apiProperties.GET_REPORT_SCHEMA_TABLE_COLUMNS;
          this.apiUtilService.invokeCloudAPI(currentAPIProperty.path + '/' + tableName, currentAPIProperty.method).subscribe(
            (res: any) => {
              this.ats.dbColumnDetails[tableName] = res.body || [];
              triggerUpdateDBDimention();
            },
            (err: any) => {
              triggerUpdateDBDimention();
              console.error(err);
            }
          );
        }
      } else triggerUpdateDBDimention();
    });
  }

  updateDBDimensions() {
    this.ats.analyticsReturnField.splice(0);
    this.ats.analyticsDBDetails.dataSource.forEach(dataSource => {
      var tableName = dataSource.objectName;
      var aliasName = dataSource.alias;
      aliasName = aliasName ? aliasName + "." : "";
      (this.ats.dbColumnDetails[tableName] || []).map((obj: any) => {
        this.ats.analyticsReturnField.push({ name: obj.Field, attr: aliasName + obj.Field });
      });
    });
  }

  updateAPIDimensions() {
    var inetrnalFlow = this.ats.internalFlowIDMap[this.ats.analyticsApiDetails.apiId] || {};
    this.ats.analyticsReturnField.splice(0);
    ((this.ats.objectsMap[inetrnalFlow.output] || {}).attributes || []).forEach((obj: any) => {
      this.ats.analyticsReturnField.push({ name: obj.name, attr: obj.name });
    });
  }
  
  openNativeQueryModel() {
    setTimeout(() => this.editor.codeMirror.refresh(), 200);
    this.nativeQuery = this.ats.analyticsDBDetails.nativeQuery;
    $("#queryEditorModal").modal("show");
  }

  saveNativeQuery() {
    this.ats.analyticsDBDetails.nativeQuery = this.nativeQuery;
    $("#queryEditorModal").modal("hide");
  }

}