import { Injectable } from '@angular/core';
import { ApiUtilService } from './../service/api-util.service';
import { apiProperties } from  '../utility/constants';
import { Utils } from '../utility/util';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class ProcessListService {

  public processList: any[] = [];
  public processListLoaded: boolean = false;

  constructor(private apiUtilService: ApiUtilService, private toastr: ToastrService) { }

  getAllProcessList(callback: any = null) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.GET_ALL_PROCESS_METADATA;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        this.processList = res.body;
        this.processListLoaded = true;
        if(callback) callback();
        if(!callback) Utils.loader('#page-loader', 'hide');
      },
      (err: any) => {
        if(err.name == "HttpErrorResponse"){
          this.toastr.error("Something went wrong!");
        }
        Utils.loader('#page-loader', 'hide');
        console.error(err);
      }
    );
  } 
}
