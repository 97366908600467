import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-svg-images',
  templateUrl: './svg-images.component.html',
  styleUrls: ['./svg-images.component.css']
})
export class SvgImagesComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
