import { Component, OnInit } from '@angular/core';
import { FormlyConfig, FormlyFieldConfig, FormlyFormOptions, FormlyTemplateOptions } from '@ngx-formly/core';
import { FieldType } from '@ngx-formly/core';

export interface CustomInputTemplateOptions {
  labelPosition: 'top'
}

export interface FormlyCustomInputField {
  fieldName: string;
  fieldLabel: string;
  fieldType: string;
  fieldValue: any;
  fieldConfig: {
    options?: FormlyFormOptions;
    validation?: any;
    template?: string;
    wrappers?: string[];
    hide?: boolean;
    hideExpression?: boolean | string | ((model: any, formState: any, field?: FormlyFieldConfig) => boolean);
    fieldGroupClassName?: string;
    templateOptions: CustomInputTemplateOptions & FormlyTemplateOptions;
  }
}

/**
 *
 * Formly Model:
  {
    ...,
    customField: [
      {
        "fieldName": "Reseller_PO_Number",
        "fieldType": "input",
        "fieldLabel": "Reseller PO Number"
      },
      {
        "fieldName": "Reseller_PO_Date",
        "fieldType": "datepicker",
        "fieldLabel": "Reseller PO Date"
      },
      {
        "fieldName": "Customer_PO_Number",
        "fieldType": "input",
        "fieldLabel": "Customer PO Number"
      },
      {
        "fieldName": "Customer_PO_Date",
        "fieldType": "datepicker",
        "fieldLabel": "Customer PO Date"
      }
  ],
  ...
 }
 */

/**
 *
 * Formly Field Config:
const usersTemplate: FormlyFieldConfig =
{
  "key": "customFields",
  "type": "repeat",
  "fieldArray": {
    "fieldGroup": [
      {
        "key": "fieldValue",
        "type": "custom-input",
        "className": "col-6",
        "templateOptions": {
          "label": "fieldName"
        }
      }
    ],
    "fieldGroupClassName": "row"
  }
};
 */

const laterUpdatingFields = ['required'];

@Component({
  selector: 'formly-field-custom-input',
  template: `
    <formly-field [field]="field"></formly-field>
  `
})
export class CustomInputComponent extends FieldType<FormlyFieldConfig> implements OnInit {
  // model is in FieldType, its type is FormlyCustomInputField
  model: FormlyCustomInputField;

  constructor(private config: FormlyConfig) {
    super();
  }

  ngOnInit() {
    this.updateCustomInputFieldSetting();
  }

  private updateCustomInputFieldSetting() {
    const type: string = this.model.fieldType;

    this.field.type = type;
    this.field.templateOptions!.label = this.model.fieldLabel || this.model.fieldName;
    if (this.model.fieldConfig) {
      ['options', 'validation', 'template', 'wrappers', 'hide', 'hideExpression', 'fieldGroupClassName']
      .forEach(key => {
        mergeValues(key, this.field, this.model.fieldConfig);
      });

      if (this.model.fieldConfig.templateOptions) {
        const keys = Object.keys(this.model.fieldConfig.templateOptions);

        keys
        .filter(key => !laterUpdatingFields.includes(key))
        .forEach(key => {
          mergeValues(key, this.field.templateOptions as FormlyTemplateOptions, this.model.fieldConfig.templateOptions);
        });

        // to avoid "ExpressionChangedAfterItHasBeenCheckedError" "ng-valid" issue
        setTimeout(() => {
          laterUpdatingFields.forEach(key => {
            mergeValues(key, this.field.templateOptions as FormlyTemplateOptions, this.model.fieldConfig.templateOptions);
          });
        });

        // setTimeout(() => {
          // Object.assign(this.field.templateOptions, this.model.fieldConfig.templateOptions);
        // });
      }
    }
    this.field.wrappers!.push('form-field');
  }

}

function mergeValues(key: string, target: {[key in string]: any}, source: {[key in string]: any}) {
  if (source.hasOwnProperty(key)) {
    const value = source[key];

    if (typeof value === 'object') {
      if (!target.hasOwnProperty(key)) {
        target[key] = Array.isArray(value) ? [] : {};
      }
      Object.assign(target[key], value);
    } else {
      target[key] = source[key];
    }

  }
}