import { Component } from '@angular/core';
import { IHeaderAngularComp } from 'ag-grid-angular';
import { IHeaderParams } from 'ag-grid-community';
declare var $: any;

@Component({
    selector: 'ccp-analytics-table-custom-header',
    template: `
    <div class="ag-cell-label-container ag-header-cell-sorted-none" role="presentation" [ngStyle]="{'background-color': headerTheme.backgroundColor, 'color': headerTheme.color, 'font-size': headerTheme.fontSize, 'font-weight': headerFontWeight}">
        <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button cursor-pointer" aria-hidden="true" *ngIf="enableFilter" (click)="onMenuClicked($event)">
            <span class="ag-icon ag-icon-menu" unselectable="on" role="presentation" [ngStyle]="{'color': headerTheme.color, 'font-weight': headerFontWeight, 'font-size': iconFontSize}"></span>
        </span>
        <div ref="eLabel" class="ag-header-cell-label-container" role="presentation" unselectable="on" [style.justify-content]="headerTheme.align">
            <span ref="eText" class="ag-header-cell-value" unselectable="on">{{params.displayName}}</span>
            <span ref="eFilter" class="ag-icon ag-header-icon ag-header-label-icon ag-filter-icon" [ngStyle]="{'color': headerTheme.color, 'font-weight': headerFontWeight, 'font-size': iconFontSize}" *ngIf="enableFilter" [ngClass]="filterApplied" aria-hidden="true"><span class="ag-icon ag-icon-filter" unselectable="on" role="presentation"></span></span>
            <ng-container *ngIf="enableSort">
                <span ref="eSortOrder" class="ag-header-icon ag-header-label-icon ag-sort-order ag-hidden" aria-hidden="true"></span>
                <span ref="eSortAsc" class="ag-header-icon ag-header-label-icon ag-sort-ascending-icon cursor-pointer" [ngClass]="ascSort" aria-hidden="true" (click)="onSortRequested('desc', $event)">
                    <span class="ag-icon ag-icon-asc" unselectable="on" role="presentation" [ngStyle]="{'color': headerTheme.color, 'font-weight': headerFontWeight, 'font-size': iconFontSize}"></span>
                </span>
                <span ref="eSortDesc" class="ag-header-icon ag-header-label-icon ag-sort-descending-icon cursor-pointer" [ngClass]="descSort" aria-hidden="true" (click)="onSortRequested('', $event)">
                    <span class="ag-icon ag-icon-desc" unselectable="on" role="presentation" [ngStyle]="{'color': headerTheme.color, 'font-weight': headerFontWeight, 'font-size': iconFontSize}"></span>
                </span>
                <span ref="eSortNone" class="ag-header-icon ag-header-label-icon ag-sort-none-icon cursor-pointer" aria-hidden="true" [ngClass]="noSort" (click)="onSortRequested('asc', $event)">
                    <span class="ag-icon ag-icon-none" unselectable="on" role="presentation" [ngStyle]="{'color': headerTheme.color, 'font-weight': headerFontWeight, 'font-size': iconFontSize}"></span>
                </span>
            </ng-container>
        </div>
    </div>
   `,
    styles: [
        `
            .ag-header-cell-label-container {
                display: flex;
                flex: 1 1 auto;
                overflow: hidden;
                align-items: center;
                text-overflow: ellipsis;
                align-self: stretch;
            }
            .ag-cell-label-container {
                padding-left: 18px;
                padding-right: 18px;
            }
        `
    ]
})
export class GridCustomHeaderComponent implements IHeaderAngularComp {
    params: any;

    enableSort: boolean;
    enableFilter: boolean;
    ascSort: string;
    descSort: string;
    noSort: string;
    filterApplied: string;
    generalTheme: any;
    headerTheme: any;
    headerFontWeight: string;
    iconFontSize: string;

    agInit(params: IHeaderParams): void {
        this.params = params;
        this.initGridHeaderPpty();
        this.initGridGeneralThemePpty();
    };

    initGridHeaderPpty() {
        this.headerTheme = this.params.analyticsGridConfigTheme.header || {};
        this.headerFontWeight = this.headerTheme.fontWeight ? this.headerTheme.fontWeight : 'normal';
        this.iconFontSize = Number(this.headerTheme.fontSize.replace("px", "")) > 16 ? this.headerTheme.fontSize : "16px";
    };

    initGridGeneralThemePpty() {
        const generalThemePpty = this.generalTheme = this.params.analyticsGridConfigTheme.general || {};
        this.enableSort = generalThemePpty.hasOwnProperty("sort") ? generalThemePpty.sort : true;
        this.enableFilter = generalThemePpty.hasOwnProperty("columnFilter") ? generalThemePpty.columnFilter : true;
        if (this.enableSort) {
            this.params.column.addEventListener('sortChanged', this.onSortChanged.bind(this));
            this.onSortChanged();
        }
        if (this.enableFilter) {
            this.params.column.addEventListener('filterChanged', this.onFilterChanged.bind(this));
            this.onFilterChanged();
        }
    };

    onMenuClicked(event: Event) {
        this.params.showColumnMenu(event.currentTarget);
    };

    onSortChanged() {
        this.ascSort = this.descSort = this.noSort = 'ag-hidden';
        if (this.params.column.isSortAscending()) {
            this.ascSort = 'active';
        } else if (this.params.column.isSortDescending()) {
            this.descSort = 'active';
        } else {
            this.noSort = 'active';
        }
    };

    onSortRequested(order: string, event: any) {
        this.params.setSort(order, event.shiftKey);
        this.params.api.redrawRows();
        this.refreshGridFontFamily();
    };

    onFilterChanged() {
        var filterModel = this.params.api.filterManager.getFilterModel();
        this.filterApplied = Object.keys(filterModel).length == 0 ? "ag-hidden" : "";
        this.refreshGridFontFamily();
    };

    refreshGridFontFamily() {
        setTimeout(() => $(".ccp-report-table *").css("font-family", this.generalTheme.fontFamily), 0);
    };

    refresh(): boolean {
        return false;
    };
}