import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'ccp-landing-grid-search-bar',
  templateUrl: './landing-grid-search-bar.component.html',
  styleUrls: ['./landing-grid-search-bar.component.css']
})
export class LandingGridSearchBarComponent implements OnInit {
  @Input() listLabel: string;
  @Output() onFilterTextBoxChanged =  new EventEmitter<any>();
  searchText: string = '';

  constructor() { }

  ngOnInit() {
  }

}
