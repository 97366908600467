import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BulkAPICreateCellRendererComponent } from './../data-grid/bulk-api-create-cell-renderer.component';
import { ObjectModelerService } from '../service/object-modeler.service';
import { ApiUtilService } from '../service/api-util.service';
import { apiProperties } from '../utility/constants';
import { Utils } from './../utility/util';

declare var $: any;

@Component({
  selector: 'ccp-internal-flow-create-modal',
  templateUrl: './internal-flow-create-modal.component.html',
  styleUrls: ['./internal-flow-create-modal.component.css']
})
export class InternalFlowCreateModalComponent implements OnInit {

  @Output() reloadAPIListGrid = new EventEmitter<any>();
  @Output() skipToStudio = new EventEmitter<any>();
  apiList: any = [];

  activeTab: string;
  apiDetails: any;
  objectList: any = [];
  objectAttrList: any = [];

  private gridApi: any;
  public pageSize: any = 10;
  public searchText: string = '';
  public columnDefs: any[];
  public context: any;
  public frameworkComponents: object;

  constructor(
    private toastr: ToastrService,
    private oms: ObjectModelerService,
    private apiUtilService: ApiUtilService,
  ) { 
    this.reset();
    this.frameworkComponents = { bulkAPICreateCellRendererComponent: BulkAPICreateCellRendererComponent };
  }

  ngOnInit() {
    this.initBulkAPICreateGridDetails();
    this.oms.getAllObjects((res: any) => {
      this.objectList = res.body;
      this.oms.objectsList = this.objectList;
    }, (err: any) => {
      console.error(err);
    });
  }

  initBulkAPICreateGridDetails() {
    this.context = { componentParent: this };
    this.columnDefs = [
      { headerName: "Name", field: 'name', cellRenderer: "bulkAPICreateCellRendererComponent", width: 270, sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } },
      { headerName: "Method", field: 'method', cellRenderer: "bulkAPICreateCellRendererComponent", sortable: false, width: 110, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } },
      { headerName: "API Path", field: 'path', cellRenderer: "bulkAPICreateCellRendererComponent", sortable: false, width: 270, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } },
      { headerName: "Response", field: 'output', cellRenderer: "bulkAPICreateCellRendererComponent", sortable: false, suppressMovable: true, suppressKeyboardEvent: function (params: any) { return true; } },
      { headerName: "Action", sortable: false, headerComponent: "bulkAPICreateCellRendererComponent", cellRenderer: "bulkAPICreateCellRendererComponent", width: 75, suppressMovable: true }
    ];
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
  }

  onPageSizeChanged() {
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(this.searchText);
  }

  bulkcreate(apiList) {
    this.apiList = apiList;
    this.reset();
    $("#createAPIModal").modal("show");
  }

  getAttributes(objectName: string) {
    let selectedObj = this.objectList.filter((obj: any) => {
      if(obj.model == objectName){
        return obj;
      }
    });
    if(selectedObj.length > 0 && selectedObj[0].attributes){
      this.objectAttrList = selectedObj[0].attributes.map((item: any) => { return item.name });
    }
  }

  next(){
    try {
      switch(this.activeTab){
        case "GENERAL":
          if((this.apiDetails.name || "").trim() == "") throw {message: "Please Enter API Name"};
          this.activeTab = "OBJECTS";
          break;
        case "OBJECTS":
          if(this.apiDetails.resourceType == "NEW"){
            var newObject = this.oms.currentEditObject;
            newObject.createdAt = Utils.getCurrentDateTimeISOString();
            if(newObject && newObject.model){
              this.apiDetails.resourceName = newObject.model;
              this.apiDetails.newObject = newObject;
            }else throw {message: "Please Enter Object Name"};
          }else if(this.apiDetails.resourceType == "EXISTING"){
            delete this.apiDetails.newObject;
            if(!this.apiDetails.resourceName) throw {message: "Please Select Resource"};
          }else throw {message: "Please Select Resource Type"};
          
          var defaultApiMap = {};
          var defaultApiList = this.getDefaultApiList(this.apiDetails.resourceName);
          defaultApiList.forEach(obj => defaultApiMap[obj.method] = obj);

          this.apiDetails.apiList = this.apiList.filter(api => {
            if(api.input == this.apiDetails.resourceName){
              if(api.path == "/" + api.input) delete defaultApiMap[api.method.toUpperCase()];
              return true;
            }
          });
          if(this.apiDetails.apiList.length == 0){
            this.apiDetails.apiList = defaultApiList;
          }else{
            Object.keys(defaultApiMap).forEach(method => this.apiDetails.apiList.push(defaultApiMap[method]));
          }
          this.activeTab = "RESOURCES";

          setTimeout(()=>{
            var lastRowDom = $("#resourcesTabContent .ag-center-cols-container .ag-row-last")[0];
            if(lastRowDom) lastRowDom.scrollIntoViewIfNeeded();
          }, 500);
          break;
        case "RESOURCES":
          var newAPIList = this.apiDetails.apiList.filter(api => api.isNewApi == true);
          if(newAPIList.length == 0) throw {message: "Please add atlease one Api"};
          
          var existingApiList = this.apiList.map(api => api.method + "::" + this.getUpdatedURL(api.path));
          var existingApiNameList = this.apiList.map(api => api.name);
          this.apiDetails.apiList.forEach(api => {
            if(api.isNewApi){
              var currentApi = api.method + "::" + this.getUpdatedURL(api.path);

              if(existingApiList.indexOf(currentApi) == -1) existingApiList.push(currentApi);
              else throw {message: "Duplicate Api Path (" + api.path + ") and Method (" + api.method + ")" };
              
              if(existingApiNameList.indexOf(api.name) == -1) existingApiNameList.push(api.name);
              else throw {message: "Duplicate Api Name (" + api.name + ")" };
            }
          });
          this.activeTab = "DOCUMENTAION";
          break;
        case "DOCUMENTAION":
          let currentAPIProperty = apiProperties.BULK_CREATE_INTERNAL_FLOW;
          var data = { apiList: [], objectList: [] };
          if(this.apiDetails.newObject) data.objectList.push(this.apiDetails.newObject);
          this.apiDetails.apiList.filter(api => {
            if(api.isNewApi == true){
              data.apiList.push({
                name: api.name,
                description: api.description,
                metadata: {
                  method: api.method,
                  input: api.input,
                  output: api.output,
                  path: api.path,
                  tag: this.apiDetails.name,
                  steps: { childStepDetails:[] }
                }
              });
            }
          });
          Utils.loader('#page-loader', 'show');
          this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method, data).subscribe(
            (res: any) => {
              this.toastr.success("Imported Successfully");
              this.reloadAPIListGrid.emit();
              $("#createAPIModal").modal('hide');
            },
            (err: any) => {
              Utils.loader('#page-loader', 'hide');
              if(err.error) this.toastr.error(err.error);
            }
          );
          break;
      }
    } catch (error) {
      this.toastr.error(error.message);
    }
  }

  getUpdatedURL(path: string) {
    return (path || "").split("/").map(text => text.startsWith("{") && text.endsWith("}") ? "{}" : text).join("/");
  }

  getDefaultApiList(resourceName: string){
    var apiList = [];

    apiList.push({
      isNewApi: true,
      name: "Get all the " + resourceName + " by search",
      description: "Get all the " + resourceName + " by search",
      path: "/" + resourceName, method: "GET", input: resourceName, output: resourceName
    });

    apiList.push({
      isNewApi: true,
      name: "Create " + resourceName,
      description: "Create " + resourceName,
      path: "/" + resourceName, method: "POST", input: resourceName, output: resourceName
    });

    apiList.push({
      isNewApi: true,
      name: "Update " + resourceName,
      description: "Update " + resourceName,
      path: "/" + resourceName, method: "PUT", input: resourceName, output: resourceName
    });

    apiList.push({
      isNewApi: true,
      name: "Delete " + resourceName,
      description: "Delete " + resourceName,
      path: "/" + resourceName, method: "DELETE", input: resourceName, output: resourceName
    });

    return apiList;
  }

  addNewApi() {
    var resourceName = this.apiDetails.resourceName;
    this.apiDetails.apiList.push({ isNewApi: true, input: resourceName, output: resourceName, method: '', path: "/" + resourceName + "/" });
    this.gridApi.setRowData(this.apiDetails.apiList);
  }

  reset() {
    this.activeTab = "GENERAL";
    this.apiDetails = {
      name: "",
      resourceType: "EXISTING",
      resourceName: "",
    };
  }

  onClickTab(tabName){
    this.activeTab = tabName;
  }

  removeCurrentAPI(rowIndex: number) {
    this.apiDetails.apiList.splice(rowIndex, 1);
    this.gridApi.setRowData(this.apiDetails.apiList);
  }

  

}
