import { Component, OnInit } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';

@Component({
  selector: 'internal-flow-list-action-cell',
  template: `<div class="row d-flex w-100 position-absolute action-icon-list-3 m-0 t-0" style="line-height: 55px;">
                  <div class="col edit-icon text-center cursor-pointer">
                    <a href="javascript:void(0);" (click)="invokeAction('view-process-details')">{{actionButtonName}}</a>
                  </div>
             </div>`,
  styles: []
})
export class MonitorLogsActionsComponent implements ICellRendererAngularComp {
    public actionButtonName: string = null;
    private params: any;

  agInit(params: any): void {
      this.actionButtonName = params.context.componentParent.actionButtonName
      this.params = params;
  }

  public invokeAction(type: string) {
      this.params.context.componentParent.invokeGridAction({rowData: this.params.node.data, type});
  }

  refresh(): boolean {
      return false;
  }
}