import { Component } from "@angular/core";
import { FieldType } from "@ngx-formly/core";

@Component({
  selector: "formly-custom-button",
  template: `
    <button [ngClass]="to.btnType" (click)="click()" type="button">
    <span class="material-icons">{{ to.iconcontent }}</span>
    {{ to.text }}
    </button>
  `
})
export class RowButtonComponent extends FieldType {
  click() {
    if (this.to.onClick) {
      const onClick = this.to.onClick;

      if (typeof onClick === "string") {
        const fn = eval(onClick);
        if (typeof fn === "function") {
          fn();
          // const inner = fn();
          // if (typeof inner === "function") {
          //   inner(this.model);
          // }
        }
      } else {
        onClick();
      }
    }
  }
}