import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { ApiConnectionConfigurationComponent } from '../api-connection-configuration/api-connection-configuration.component';
import { DefaultTextCellRendererComponent } from './../data-grid/default-text-cell-renderer.component';
import { ApiMethodBadgeRendererComponent } from './../data-grid/api-method-badge-renderer.component';
import { InternalFlowService } from '../service/internal-flow.service';
import { ApiUtilService } from '../service/api-util.service';
declare var $: any;

@Component({
  selector: 'app-import-api',
  templateUrl: './import-api.component.html',
  styleUrls: ['./import-api.component.css']
})
export class ImportApiComponent implements OnInit {
  @Output() importAPI = new EventEmitter<string>();
  @ViewChild('fileInput', {static : false})myInputVariable: ElementRef;
  @ViewChild(ApiConnectionConfigurationComponent, {static: false}) apiConnection: ApiConnectionConfigurationComponent;
  importType: string = "";
  fileName: string;
  url: string = "";
  apiList = [];
  columnDefs = [];
  gridApi: any;
  frameworkComponents: object;
  context: any;
  pageSize = 7;
  rowSelection = "multiple";
  activeTab: string;
  fileText: string | ArrayBuffer;
  swaggerResponse;
  gridOptions;
  defaultColDef: { resizable: boolean; };
  selectedAPIs: any = []

  constructor(
    private internalFlowService: InternalFlowService, 
    private toastr: ToastrService,
    private apiUtilService: ApiUtilService) { }

  ngOnInit() {
    this.resetValues();

    this.columnDefs = [
      { headerName : '', field : '', width : 50, checkboxSelection: true },
      { headerName: 'Name', field: 'name', sortable: true, suppressMovable: true },
      { headerName: 'API Path', field: 'api', sortable: true, suppressMovable: true },
      { headerName: 'Method', field: 'method', sortable: true, suppressMovable: true, width: 75 }
    ];
    this.defaultColDef = { resizable: true };
    this.gridOptions = {
      columnDefs: this.columnDefs,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      rowMultiSelectWithClick: true
    };
    this.frameworkComponents = {
      defaultTextCellRendererComponent: DefaultTextCellRendererComponent,
      apiMethodBadgeRendererComponent: ApiMethodBadgeRendererComponent
    }
    this.setDataGridColDefFormatters();
  }

  setDataGridColDefFormatters() {
    this.columnDefs.forEach((colDef: any) => {
      if(!colDef.valueFormatter) {
        const headerName: string = colDef.headerName;
        switch (headerName) {
          case 'Method':
            colDef.cellRenderer = 'apiMethodBadgeRendererComponent';
            break;
          default:
            if(headerName) colDef.cellRenderer = 'defaultTextCellRendererComponent';
            break;
        }
      }
    });
  }
  
  isValidSwaggerFileContent(swaggerFileContent) {
    try{
      var data = JSON.parse(swaggerFileContent);
      if(data.swagger && data.schemes && data.paths && data.info && data.info.title){
        return true;
      }else throw "Invalid File";
    }catch(e){
      this.toastr.error("Invalid file.");
    }
    return false;
  }

  onSelectionChanged(event) {
    this.selectedAPIs = this.gridApi.getSelectedNodes().map(w => w.data);
  }

  removeSelectedAPI(selectedData) {
    this.gridApi.forEachNode(node => {
      var data = node.data;
      if(data.method == selectedData.method && data.api == selectedData.api && data.name == selectedData.name && data.operationId == selectedData.operationId) {
        node.setSelected(false);
      }
    });
    this.selectedAPIs = this.selectedAPIs.filter(w => (w.method !== selectedData.method && w.api !== selectedData.api && w.name !== selectedData.name && w.operationId == selectedData.operationId));
  }

  onFilterTextBoxChanged(value) {
    this.gridOptions.api.setQuickFilter(value);
  }

  fileChanged(event) {
    this.swaggerResponse = {};
    this.apiList = [];
    var file = event.target.files[0];
    if (file.name.endsWith('.json')) {
      let fileReader = new FileReader();
      fileReader.onload = (e) => {
        if(this.isValidSwaggerFileContent(fileReader.result)){
          this.fileName = file.name;
          this.fileText = fileReader.result;
        }        
      }
      fileReader.readAsText(file);
    }else{
      this.toastr.error("Unsupported file format.");
    }
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.selectedAPIs = [];
    //this.gridApi.paginationSetPageSize(Number(this.pageSize));
  }

  getApiFromSwagger(swaggerFileContent) {
    this.internalFlowService.getAPIFromSwaggerText(swaggerFileContent).then(data => {
      this.fileText = null;
      this.activeTab = "api";
      this.swaggerResponse = data;
      this.swaggerResponse.configuration.connector_type = "Application";
      this.swaggerResponse.configuration.connector_subtype = "REST";
      this.swaggerResponse.configuration.auth = this.swaggerResponse.configuration.auth || {};
      this.swaggerResponse.configuration.auth.auth_type = this.swaggerResponse.configuration.auth.auth_type || "";
      this.swaggerResponse.configuration.auth.auth_details = this.swaggerResponse.configuration.auth.auth_details || {signature_method: '', grant_type: ''};
      this.apiList = this.swaggerResponse['conversion_map'].operation;
      setTimeout(() => {
        this.gridApi.sizeColumnsToFit();
      }, 10);
    });
  }

  next() {
    switch (this.activeTab) {
      case "local":
        if(this.importType == "FILE_UPLOAD"){
          if (this.fileName !== "") {
            if(this.fileText) this.getApiFromSwagger(this.fileText);
            else this.activeTab = "api";
          } else this.toastr.error("Please Select the Swagger file.");
        }else if(this.importType == "URL"){
          if(this.url.trim() !== ""){
            this.apiUtilService.invokeExternalAPI(this.url, "GET").subscribe(
              (res: any) => {
                var swaggerFileContent = typeof(res.body) == "string" ? res.body : JSON.stringify(res.body);
                if(this.isValidSwaggerFileContent(swaggerFileContent)) this.getApiFromSwagger(swaggerFileContent);
              },
              (err: any) => this.toastr.error("Please Enter Valid URL.")
            )
          } else this.toastr.error("Please Enter URL.");
        }else this.toastr.error("Please Select Import Type.");
        break;
      case "api":
        let selectedNodes = this.gridApi.getSelectedNodes();
        this.swaggerResponse["operationIdList"] = selectedNodes.map(node => node.data.operationId);
        if(this.swaggerResponse["operationIdList"].length > 0){
          this.activeTab = "connection";
        }else{
          this.toastr.error("At least one row must be selected.");
        }
        break;
      case "connection":
        this.swaggerResponse.configuration = this.apiConnection.getConfiguration();
        this.internalFlowService.importAPI(this.swaggerResponse).then(res =>{
          this.toastr.success("Imported Successfully");
          $("#importModal").modal('hide');
          this.importAPI.emit();
        }).catch(err =>{  
          if(err.error)
            this.toastr.error(err.error);
        });
        break;
    }
  }

  onClickTab(tabName){
    this.activeTab = tabName;
  }
  
  resetValues(isTypeChange: boolean = false){
    if(!isTypeChange) this.importType = "";
    this.url = "";
    this.swaggerResponse = {};
    this.activeTab = "local";

    this.apiList = [];
    this.fileName = "";
    if(this.myInputVariable && this.myInputVariable.nativeElement) this.myInputVariable.nativeElement.value = "";
  }
}
