import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: 'api-connector-list-action-cell',
    template: `<span title="Edit" class="mr-4 cursor-pointer" (click)="invokeAction('edit')"> <i class="fa fa-pencil-square-o fa-lg" aria-hidden="true"></i> </span>
               <span title="Delete" class="cursor-pointer" (click)="invokeAction('delete')"> <i class="fa fa-trash-o fa-lg" aria-hidden="true"></i> </span>`,
    styles: []
})
export class ApiConnectorListActionsComponent implements ICellRendererAngularComp {
    private params: any;
a
    agInit(params: any): void {
        this.params = params;
    }

    public invokeAction(type: string) {
        this.params.context.componentParent.invokeGridAction({rowData: this.params.node.data, type});
    }

    refresh(): boolean {
        return false;
    }
}