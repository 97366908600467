import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AnalyticsStudioService } from '../analytics-studio.service';

import { Utils } from "../../utility/util";

@Component({
  selector: 'ccp-chart-types',
  templateUrl: './chart-types.component.html',
  styleUrls: ['./chart-types.component.css']
})
export class ChartTypesComponent implements OnInit {

  constructor(public ats: AnalyticsStudioService) { }

  @Output() chartList = new EventEmitter<any>();
  private previouisChartInfo: any = {};

  charts: any = [
    { icon: 'reports', title: 'Reports', analyticsType: 'reports', reportType: 'HTML Reports' },
    { icon: 'floatBar', title: 'Float Bar', analyticsType: 'column', reportType: 'Graphical'},
    { icon: 'horizontal', title: 'Horizontal', analyticsType: 'bar', reportType: 'Graphical'},
    { icon: 'lollipop', title: 'Lollipop', analyticsType: 'lollipop', reportType: 'Graphical'},
    { icon: 'pie', title: 'Pie Chart', analyticsType: 'pie', reportType: 'Graphical'},
    { icon: 'donut', title: 'Donut Chart', analyticsType: 'donut', reportType: 'Graphical'},
    { icon: 'radialBar', title: 'Radial Bar', analyticsType: 'radialbar', reportType: 'Graphical'},
    { icon: 'line', title: 'Line', analyticsType: 'line', reportType: 'Graphical'},
    { icon: 'bubble', title: 'Bubble', analyticsType: 'bubble', reportType: 'Graphical'},
    { icon: 'streamGraph', title: 'Stream Graph', analyticsType: 'streamgraph', reportType: 'Graphical'},
    { icon: 'table', title: 'Table', analyticsType: 'table', reportType: 'Data Grid'},
    { icon: 'gauge', title: 'Gauge', analyticsType: 'gauge', reportType: 'Graphical'},
    { icon: 'pyramid', title: 'Pyramid Bar', analyticsType: 'columnpyramid', reportType: 'Graphical'},
    { icon: 'funnel', title: 'Funnel', analyticsType: 'funnel', reportType: 'Graphical'},
    { icon: 'area', title: 'Area Chart', analyticsType: 'area', reportType: 'Graphical'},
    { icon: 'heatmap', title: 'Heat Map', analyticsType: 'heatmap', reportType: 'Graphical'},
    { icon: 'scatter', title: 'Scatter', analyticsType: 'scatter', reportType: 'Graphical'},
    { icon: 'candlestick', title: 'Candlestick', analyticsType: '', isDiabled: true, reportType: 'Graphical'},
    { icon: 'gauge', title: 'Solid Gauge', analyticsType: 'solidgauge', reportType: 'Graphical'}
  ];

  ngOnInit() {
    // const selectedChartType = this.charts.filter(chart => chart.analyticsType === this.ats.analytics.analyticsType)[0] || {};
    // this.chartList.emit(selectedChartType);
  }

  getScopeKeyByAnalyticsType(analyticsType: string){
    if(analyticsType == "table") return {key: "grid", refKey: "analyticsConfGrid"};
    else if(analyticsType == "reports") return {key: "htmlReport", refKey: "analyticsConfHtmlReport"};
    else return {key: "graphical", refKey: "analyticsConfGraphical"};
  }

  setChartType(chart: any) {
    if(this.ats.analytics.analyticsType != chart.analyticsType){
      this.previouisChartInfo[this.ats.analytics.analyticsType] = this.ats[this.getScopeKeyByAnalyticsType(this.ats.analytics.analyticsType).refKey];
      
      let currentChartCategory = this.ats.basicChartInfo[chart.analyticsType].additionalInfo.category;
      let previousChartCategory = this.previouisChartInfo[this.ats.analytics.analyticsType].category;
      let analyticsConfGraphAddDet = this.ats.analyticsConfGraphAddDet;
      if(previousChartCategory != currentChartCategory){
        if(previousChartCategory === "1-Dimension" && (currentChartCategory === "2-Dimension" || currentChartCategory === "3-Dimension")){
          analyticsConfGraphAddDet.yAxis = analyticsConfGraphAddDet.count;
          analyticsConfGraphAddDet.yAxisName = analyticsConfGraphAddDet.countName;
          analyticsConfGraphAddDet.yAxisCondition = analyticsConfGraphAddDet.countCondition;
        }else if((previousChartCategory === "2-Dimension" || previousChartCategory === "3-Dimension") && (currentChartCategory === "1-Dimension" || currentChartCategory === "Gauge")){
          analyticsConfGraphAddDet.count = analyticsConfGraphAddDet.yAxis;
          analyticsConfGraphAddDet.countName = analyticsConfGraphAddDet.yAxisName
          analyticsConfGraphAddDet.countCondition = analyticsConfGraphAddDet.yAxisCondition;
        }
      }

      this.ats.analytics.analyticsType = chart.analyticsType;
      this.ats.analytics.reportType = chart.reportType;  
      var details = this.getScopeKeyByAnalyticsType(chart.analyticsType);
      this.ats[details.refKey] = this.ats.analyticsConfig[details.key] = Utils.cloneJSON(this.previouisChartInfo[chart.analyticsType] || this.ats.basicChartInfo[chart.analyticsType].additionalInfo);
      delete this.previouisChartInfo[chart.analyticsType];

      var chartPropIndex = this.ats.disableTabList.indexOf("chartProp");
      if(this.ats.analytics.reportType == "HTML Reports"){
        if(chartPropIndex == -1) this.ats.disableTabList.push("chartProp");
      }else{
        if(chartPropIndex != -1) this.ats.disableTabList.splice(chartPropIndex, 1);
      }

      this.ats.reInitAnalyticsWatcher();
      this.chartList.emit(chart);
    }
  }

}
