import { Component, OnInit } from '@angular/core';
import {ICellRendererAngularComp} from "ag-grid-angular";
import { ICellRendererParams, IAfterGuiAttachedParams } from 'ag-grid-community';

@Component({
  selector: 'app-report-generator-analytic-type',
  template: `<img style="height: 1.5rem;margin-right: 1rem;" src='{{ "../../../assets/images/charts/"+chartsMetadata[updatedCellValue]?.logo+".svg" }}'><span>{{chartsMetadata[updatedCellValue]?.label}}</span>`,
  styleUrls: []
})
export class AnalyticsTypeComponent implements ICellRendererAngularComp {

  constructor() { }
  refresh(): boolean {
    return false;
  }

 public chartsMetadata = {"column":{logo:"floatBar", label:"Column"}, 
                    "bar":{logo:"horizontal", label:"Bar"},
                    "pie":{logo:"pie", label:"Pie"},
                    "donut":{logo:"donut", label:"Donut"},
                    "line":{logo:"line", label:"Line"},
                    "bubble":{logo:"bubble", label:"Bubble"},
                    "gauge":{logo:"gauge", label:"Gauge"},
                    "solidgauge":{logo:"gauge", label:"Solid Gauge"},
                    "funnel":{logo:"funnel", label:"Funnel"},
                    "area":{logo:"area", label:"Area"},
                    "scatter":{logo:"scatter", label:"Scatter"},
                    "columnpyramid":{logo:"pyramid", label:"Pyramid"},
                    "lollipop":{logo:"lollipop", label:"Lollipop"},
                    "radialbar":{logo:"radialBar", label:"Radial Bar"},
                    "streamgraph":{logo:"streamGraph", label:"Streamgraph"},
                    "dumbbell":{logo:"dumbbell", label:"Dumbbell"},
                    "candlestick":{logo:"candlestick", label:"Candlestick"},
                    "heatmap":{logo:"heatmap",label: "HeatMap"},
                    "table":{logo:"table", label:"Table"},
                    "reports":{logo:"table", label:"Report"}
                  }

  public updatedCellValue: any;

  agInit(params: any): void {
    const data: any = params.value;
    this.updatedCellValue = data;
}

  

}
