import { Component, OnInit } from '@angular/core';
import { FieldType } from '@ngx-formly/core';

@Component({
  selector: 'formly-auto-refresh',
  template: ''
})
export class AutoRefreshComponent extends FieldType implements OnInit {

  ngOnInit() {
    const attr = this.to.attributes as any;
    if (attr && attr.hasOwnProperty('autoRefreshCallback') && typeof attr.autoRefreshCallback === 'function') {
      const timerId = setTimeout(
        () => {
          attr.autoRefreshCallback();

          clearTimeout(timerId);
        }
      );
    }
  }
}
