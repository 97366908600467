import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'ccp-delete-confirmation-modal',
  templateUrl: './delete-confirmation-modal.component.html',
  styleUrls: ['./delete-confirmation-modal.component.css']
})
export class DeleteConfirmationModalComponent implements OnInit {

  @Input() selectedRow: any;
  @Input() displayName: string;
  @Output() invokeDeleteEvent = new EventEmitter<any>();

  constructor() { }

  ngOnInit() {}

  invokeDelete() {
    this.invokeDeleteEvent.emit();
  }

}
