import {CurrencyPipe} from '@angular/common';
import {Injectable, Pipe, PipeTransform} from '@angular/core';

/**
 * OrbMoneyPipe Wraps the angular Currency pipe, for use with the Orbitera Money type.
 * This pipe follows the same format for arguments as the Currency pipe, except that instead of
 * specifying the currency code, you can provide an empty value.
 *
 * OrbMoneyPipe defaults to display:'symbol-narrow' like $0.00 instead of US$0.00
 *
 * A new "display option" is added called `explicit` where the money is displayed as: `USD $0.00`,
 * where the currency code is prefixed to the output of `CurrencyPipe` using the `symbol-narrow`
 * option.
 *
 * @example:
 * {{ moneyValue | orbMoney }}
 * {{ moneyValue | orbMoney:'-':'symbol-narrow':'1.6-6' }}
 */
@Pipe({name: 'orbMoney'})
@Injectable({providedIn: 'root'})
export class OrbMoneyPipe implements PipeTransform {
  constructor(private readonly currencyPipe: CurrencyPipe) {}

  transform(
      input: any|undefined|null,
      emptyValue: string = '',
      display: Display = 'symbol-narrow',
      digitsInfo?: string,
      locale?: string,
      ): string|null {
    if (input === undefined || input === null) {
      return emptyValue;
    }

    const {value, currency} = input;

    const renderedParts = [];
    if (display === 'explicit') {
      display = 'symbol-narrow';
      renderedParts.push(currency);
    }
    renderedParts.push(this.currencyPipe.transform(value, currency, display, digitsInfo, locale));

    return renderedParts.join(' ');
  }
}

type Display = 'code'|'symbol'|'symbol-narrow'|'explicit'|string|boolean;
