import { Component } from '@angular/core';
import { FieldType, FormlyFieldConfig } from '@ngx-formly/core';

@Component({
  selector: 'formly-field-mat-toggle',
  template: `
    <mat-slide-toggle
      [id]="id"
      [formControl]="formControl"
      [formlyAttributes]="field"
      [color]="to.color"
      [tabIndex]="to.tabindex"
      [required]="to.required">
      {{ to.label }}
    </mat-slide-toggle>
  `,
})
export class FormlyFieldToggle extends FieldType<FormlyFieldConfig> {
  defaultOptions = {
    templateOptions: {
      hideFieldUnderline: true,
      floatLabel: 'always',
      hideLabel: true,
    },
  };
}