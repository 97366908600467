import { Component, Input, OnInit } from '@angular/core';
import { AnalyticsStudioService } from '../analytics-studio.service';
declare var $: any;

@Component({
  selector: 'chart-datatable',
  templateUrl: './chart-datatable.component.html',
  styleUrls: ['./chart-datatable.component.css']
})
export class ChartDatatableComponent implements OnInit {

  private gridApi: any;
  private gridExpandView: any;
  public pageSize: any = 10;
  public searchText: string = '';
  public columnDefs: any[];
  public rowData: any[];
  public context: any;
  public frameworkComponents: object;

  public eventSchemaList: any = [];
  public selectedRowObject: any= {};

  @Input() gridResponse: any;
  @Input() returnFeilds: any;
  
  constructor(
    public ats: AnalyticsStudioService
  ) { }

  ngOnInit() {
    this.initDataGridDetails();
    this.getAllRowData();
  }

  initDataGridDetails() {
    this.context = { componentParent: this };
    this.columnDefs = [];
  }
  onExpandadGridReady(params: any){
    params.api.sizeColumnsToFit(); 
    this.gridExpandView = params.api;
    this.gridExpandView.setDomLayout("autoHeight");
    this.gridExpandView.paginationSetPageSize(Number(this.pageSize));
    
  }
  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.setDomLayout("autoHeight");
    $('.fixed-grid .ag-paging-panel').hide();
    this.gridApi.paginationSetPageSize(2);
    
  }

  onPageSizeChanged() {
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
  }

  onFilterTextBoxChanged() {
    this.gridApi.setQuickFilter(this.searchText);
  }

  getAllRowData() {
    this.columnDefs = this.returnFeilds.map((field: any) => {
      var headerName = field.name.replace( /([A-Z])/g, " $1" );
      headerName = headerName.charAt(0).toUpperCase() + headerName.slice(1);
      return {headerName, field: field.name, sortable: true, suppressMovable: true, suppressSizeToFit: true};
    });
    this.rowData = this.gridResponse;
  }


}