import { Component } from "@angular/core";
import { ICellRendererAngularComp } from "ag-grid-angular";
import { ObjectModelerService } from './../service/object-modeler.service';
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
    selector: 'object-attr-cell-renderer',
    template: `<div class="w-100 d-flex mt-2 mb-2" *ngIf="params.colDef.field === 'name'">
                <input type="text" class="form-control form-control-sm" [(ngModel)]="params.data.name" (blur)="validateAttribute($event)" [disabled]="oms.currentEditObject.isSystemObject"/>
                <i class="fa fa-key primaryKey mt-1 ml-2 position-absolute r-0" *ngIf="params.data.key == 'primary' || params.data.key=='foreign'" aria-hidden="true" style="transform: rotate(315deg) !important;"></i>
               </div>
               <input type="text" *ngIf="params.colDef.field === 'label'" class="form-control form-control-sm" [(ngModel)]="params.data.label" [disabled]="oms.currentEditObject.isSystemObject"/>
               <div class="w-100 d-flex mt-2" [ngClass]="{'mb-2': !params.data.isEnum}" *ngIf="params.colDef.field === 'type_format'">
                <select class="form-control form-control-sm pr-0" [(ngModel)]="params.data.type_format" (change)="setAttributeFormat()" [disabled]="oms.currentEditObject.isSystemObject">
                    <optgroup *ngFor="let typeFormat of oms.typeFormatList" label= {{typeFormat}} >
                        <ng-container *ngFor="let attrType of oms.attrTypeList" >
                            <option hidden selected value="">Select datatype</option>
                            <option *ngIf="attrType.groupBy === typeFormat" value={{attrType.value}}>
                                {{attrType.text}}
                            </option>
                        </ng-container> 
                    </optgroup>
                </select>
                <span class="enumValIcn pl-1 cursor-pointer">
                    <i class="fa fa-th position-absolute r-0 pt-2" id="enumValIcn_{{params.rowIndex}}" aria-hidden="true" *ngIf="params.data.isEnum" title="Insert values" (click)="openEnumModal();"></i>
                </span>
               </div>
               <input type="text" *ngIf="params.colDef.field === 'size'" class="form-control form-control-sm" id="editDefSize_{{this.params.rowIndex}}" [(ngModel)]="params.data.size" (keypress)="validateAttrSize(params.data.size, $event)" [disabled]="params.data.type == 'Date' || params.data.type == 'DateTime' || oms.currentEditObject.isSystemObject">
               <div *ngIf="params.colDef.field === 'isPrivate'" class="checkbox col-12 text-center">
                <input type="checkbox" [(ngModel)]="params.data.isPrivate" [disabled]="oms.currentEditObject.isSystemObject">
               </div>
               <div *ngIf="params.colDef.field === 'mandatory'" class="checkbox col-12 text-center">
                <input type="checkbox" [(ngModel)]="params.data.mandatory" [disabled]="oms.currentEditObject.isSystemObject">
               </div>
               <div *ngIf="params.colDef.field === 'indexed'" class="checkbox col-12 text-center">
                <input type="checkbox" [(ngModel)]="params.data.indexed" [disabled]="params.data.key === 'primary' || oms.currentEditObject.isSystemObject">
               </div>
               <div *ngIf="params.colDef.field === 'isAuditField'" class="checkbox col-12 text-center">
                <input type="checkbox" [(ngModel)]="params.data.isAuditField" [disabled]="oms.currentEditObject.isSystemObject">
               </div>
               <div class="col" *ngIf="params.colDef.headerName === 'Action'">
                <span title="Delete" class="cursor-pointer" (click)="deleteAttr()"> <i class="fa fa-trash-o fa-lg" aria-hidden="true"></i> </span>
               </div>`,
    styles: []
})
export class ObjectAttributeCellRendererComponent implements ICellRendererAngularComp {
    public params: any;

    constructor(public oms: ObjectModelerService, private toastr: ToastrService) { }

    agInit(params: any): void {
        this.params = params;
    }

    public deleteAttr() {
        this.params.context.componentParent.deleteAttr(this.params.rowIndex);
    }

    refresh(): boolean {
        return false;
    }

    validateAttribute(event: Event) {
        var attributeObj = this.params.data;
        var attrList = this.oms.currentEditObject.attributes.filter((attr: object) => {
            if (attributeObj.name != "") return attributeObj.name == attr['name'];
            else return false;
        });
        var aliasRelObj = (this.oms.currentEditObject.relationships || []).find((rel: object) => attributeObj.name == rel['name']);
        if (/^[a-zA-Z0-9_$@]*$/.test(attributeObj.name) == false) {
            $(event.currentTarget).addClass("border-warning");
            this.toastr.error('Remove white space or special characters except "_", "$", "@"');
        } else if (attrList.length > 1) {
            $(event.currentTarget).addClass("border-warning");
            this.toastr.error("Attribute name already exists");
        } else if (aliasRelObj) {
            $(event.currentTarget).addClass("border-warning");
            this.toastr.error("Attribute name already exists as alias name for the relationship '" + aliasRelObj.model + "'");
        } else {
            $(event.currentTarget).removeClass("border-warning");
        }
    }

    setAttributeFormat() {
        var index: number = this.params.rowIndex;
        var curAttrObj: any = this.params.data;
        var curSelectedTypeFormat = curAttrObj.type_format;
        delete curAttrObj.key;
        curAttrObj.isEnum = false;
        delete curAttrObj.enumList;
        delete curAttrObj.default_value;
        function animateEnumValIcon() {
            setTimeout(function () {
                $("#enumValIcn_" + index).attr("style", "color: #EC9836 !important");
            }, 100);
            setTimeout(function () {
                $("#enumValIcn_" + index).attr("style", "color: #0F074B !important");
            }, 300);
            setTimeout(function () {
                $("#enumValIcn_" + index).attr("style", "color: #EC9836 !important");
            }, 500);
            setTimeout(function () {
                $("#enumValIcn_" + index).attr("style", "color: #0F074B !important");
            }, 700);
            setTimeout(function () {
                $("#enumValIcn_" + index).attr("style", "color: #EC9836 !important");
            }, 900);
            setTimeout(function () {
                $("#enumValIcn_" + index).attr("style", "color: #0F074B !important");
            }, 1100);
        }

        setTimeout(() => animateEnumValIcon(), 100);

        switch (curSelectedTypeFormat) {
            case "number":
                curAttrObj.type = "Long";
                curAttrObj.format = "default";
                break;
            case "number_array":
                curAttrObj.type = "Long";
                curAttrObj.format = "array";
                break;
            case "percent":
            case "currency":
                curAttrObj.type = "Float";
                curAttrObj.format = "default";
                break;
            case "phone":
                curAttrObj.type = "Long";
                curAttrObj.format = "phoneNumber";
                curAttrObj.size = 10;
                //$('#editDefSize_'+index).addClass('readOnly');
                break;
            case "creditcard":
                curAttrObj.type = "Long";
                curAttrObj.format = "creditCard";
                curAttrObj.size = 16;
                //$('#editDefSize_'+index).addClass('readOnly');
                break;
            case "cvv":
                curAttrObj.type = "Integer";
                curAttrObj.format = "cvv";
                curAttrObj.size = 3;
                //$('#editDefSize_'+index).addClass('disabled');
                break;
            case "autonumber":
                curAttrObj.key = "primary";
                curAttrObj.type = "Integer";
                curAttrObj.format = "default";
                break;
            case "text":
            case "textarea":
            case "url":
            case "zip":
            case "lookup":
                curAttrObj.type = "String";
                curAttrObj.format = "default";
                break;
            case "text_array":
                curAttrObj.type = "String";
                curAttrObj.format = "array";
                break;
            case "boolean":
                curAttrObj.type = "Boolean";
                curAttrObj.format = "default";
                break;
            case "email":
                curAttrObj.type = "String";
                curAttrObj.format = "email";
                break;
            case "password":
                curAttrObj.type = "String";
                curAttrObj.format = "password";
                break;
            case "ssn":
                curAttrObj.type = "String";
                curAttrObj.format = "ssn";
                curAttrObj.size = 9;
                //$('#editDefSize_'+index).addClass('readOnly');
                break;
            case "checkbox":
            case "radio":
            case "dropdown":
                curAttrObj.type = "String";
                curAttrObj.format = "default";
                curAttrObj.enumList = [{value: ''}];
                curAttrObj.isEnum = true;
                break;
            case "date":
                curAttrObj.type = "Date";
                curAttrObj.format = "default";
                break;
            case "date_sysdate":
                curAttrObj.type = "Date";
                curAttrObj.format = "default";
                curAttrObj.default_value = "sysDate";
                break;
            case "datetime":
                curAttrObj.type = "DateTime";
                curAttrObj.format = "default";
                break;
            case "datetime_sysdate":
                curAttrObj.type = "DateTime";
                curAttrObj.format = "default";
                curAttrObj.default_value = "sysDate";
                break;
            case "blob":
                curAttrObj.type = "blob";
                curAttrObj.format = "default";
                break;
            case "clob":
                curAttrObj.type = "clob";
                curAttrObj.format = "default";
                break;
            default:
                $('#editDefSize_' + index).removeClass('readOnly');

        }
    }

    openEnumModal() {
        this.oms.selectedAttribute = this.params.data;
        this.oms.selectedAttribute.enumList = this.oms.selectedAttribute.enumList || [];
        this.oms.prevEnumList = JSON.parse(JSON.stringify(this.oms.selectedAttribute.enumList));
        if (this.oms.selectedAttribute.enumList.length == 0) this.oms.selectedAttribute.enumList.push({value: ''});
        $("#enumDataModal").modal("show");
    }

    validateAttrSize(sizeVal: any, event: any){
        var keyCodeValue = event.which;
        var commaStatus = false;
        sizeVal = sizeVal + "";
        if(sizeVal.contains(",")){
            commaStatus = true;
        }
        if(commaStatus){
            if (!(keyCodeValue>=48 && keyCodeValue<58) || keyCodeValue == 44)
                event.preventDefault();
        } else {
            if (!(keyCodeValue>=48 && keyCodeValue<58) && keyCodeValue != 44)
                event.preventDefault();
        }
    }

}