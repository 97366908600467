import {NgModule} from '@angular/core';
import { TextFilterPipe } from './text-filter.pipe';
import { FilterPipe } from './filter.pipe';
import {LinkifyPipe} from './linkify.pipe';
import {OrbMoneyPipe} from './orb-money.pipe';
import {OrdinalPipe} from './ordinal.pipe';
import { SafePipe } from './safe-pipe';
import { TruncatePipe } from './truncate.pipe';

@NgModule({
  exports: [
    LinkifyPipe,
    OrbMoneyPipe,
    OrdinalPipe,
    SafePipe,
    FilterPipe,
    TruncatePipe,
    TextFilterPipe
  ],
  declarations: [
    LinkifyPipe,
    OrbMoneyPipe,
    OrdinalPipe,
    SafePipe,
    FilterPipe,
    TruncatePipe,
    TextFilterPipe
  ],
})
export class PipesModule {
}
