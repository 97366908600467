import { Component } from "@angular/core";
import {ICellRendererAngularComp} from "ag-grid-angular";

@Component({
    selector: 'process-list-public-cell',
    template: `<span [title]="updatedCellValue" class="mr-4 cursor-pointer"> <i [ngClass]="{'fa-lock lock-red' : !params.data.isPublic, 'fa-unlock-alt lock-green' : params.data.isPublic}" class="fa fa-lg" aria-hidden="true"></i> {{updatedCellValue}} </span>`,
    styles: []
})
export class ProcessListPublicComponent implements ICellRendererAngularComp {
    public params: any;
    public updatedCellValue: any;

    agInit(params: any): void {
        const data: any = params.value;
        this.params = params;
        this.updatedCellValue = data != undefined && data != null ? 'Public' : 'Private';
    }

    refresh(): boolean {
        return false;
    }
}