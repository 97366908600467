import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'ccp-quick-access-orchestration-landing',
  templateUrl: './quick-access-orchestration-landing.component.html',
  styleUrls: ['./quick-access-orchestration-landing.component.css']
})
export class QuickAccessOrchestrationLandingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.title = "CCS - Landing";
  }

}
