import { Component } from "@angular/core";
import { Utils } from "../utility/util";
import { ICellRendererAngularComp } from "ag-grid-angular";

@Component({
    selector: 'date-time-value-cell',
    template: ` <div class="position-absolute w-100 h-100 t-0 l-0 date-time-value-container text-center">
                    <div class="date-val mt-3"> {{ dateVal }} </div>
                    <span class="time-val"> {{ timeVal }} </span>
                </div>`,
    styles: [
        `.date-time-value-container > .date-val{
            margin-top: .5rem !important;
        }`
    ]
})
export class MonitorLogsDateTimeRendererComponent implements ICellRendererAngularComp {
    dateTimeVal: string;
    dateVal: string;
    timeVal: string;

    constructor() {}

    agInit(params: any): void {
        let dateTimeStr = this.dateTimeVal = Utils.dateTimeValueFormatter(params, 'MMM dd, YYYY HH:mm:ss.S');
        let dateTimeStrSplit = dateTimeStr.split(" ");
        this.timeVal = dateTimeStrSplit.splice(3, 1).join(" ");
        this.dateVal = dateTimeStrSplit.join(" ");
    }

    refresh(): boolean {
        return false;
    }
}