import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { AgGridModule } from 'ag-grid-angular';
import { CcpAnalyticsTableComponent } from './ccp-analytics-table/ccp-analytics-table.component';
import { DynamicTemplateComponent } from './dynamic-template/dynamic-template.component';
import { CcpAnalyticsLandingComponent } from './ccp-analytics-landing/ccp-analytics-landing.component';
import { CcpAnalyticsChartComponent } from './ccp-analytics-chart/ccp-analytics-chart.component';
import { GridCustomHeaderComponent } from './ccp-analytics-table/data-grid/grid-custom-header.component';

@NgModule({
  declarations: [
    CcpAnalyticsTableComponent,
    DynamicTemplateComponent,
    CcpAnalyticsLandingComponent,
    CcpAnalyticsChartComponent,
    GridCustomHeaderComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    AgGridModule.withComponents([])
  ],
  exports: [
    CcpAnalyticsLandingComponent
  ],
  entryComponents: [
    GridCustomHeaderComponent
  ]
})
export class ReportsModule { }
