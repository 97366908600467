import { Injectable } from '@angular/core';
import { apiProperties } from '../utility/constants';
import { CommonService } from './common.service';
import { ApiUtilService } from './api-util.service';
import { ToastrService } from 'ngx-toastr';
import { Utils } from '../utility/util';
import { Router } from '@angular/router';
declare var $: any;

@Injectable({
  providedIn: 'root'
})
export class ObjectModelerService {

  objectsList: any[] = [];
  currentEditObject: any = {};
  selectedAttribute: any = {};
  prevEnumList: any[];
  typeFormatList: any[];
  attrTypeList: any = [
    /* Number */
    { value: "number", text: "Number", groupBy: "Number" },
    { value: "number_array", text: "Number (Array)", groupBy: "Number" },
    { value: "phone", text: "Phone", groupBy: "Number" },
    { value: "zip", text: "Zip", groupBy: "Number" },
    { value: "currency", text: "Currency", groupBy: "Number" },
    { value: "percent", text: "Percent", groupBy: "Number" },
    { value: "creditcard", text: "Credit Card", groupBy: "Number" },
    { value: "cvv", text: "CVV", groupBy: "Number" },
    { value: "autonumber", text: "Auto Number", groupBy: "Number" },

    /* Text */
    { value: "text", text: "Text", groupBy: "Text" },
    { value: "text_array", text: "Text (Array)", groupBy: "Text" },
    { value: "textarea", text: "Text Area", groupBy: "Text" },
    { value: "url", text: "URL", groupBy: "Text" },
    { value: "email", text: "Email", groupBy: "Text" },
    { value: "password", text: "Password", groupBy: "Text" },
    { value: "ssn", text: "SSN", groupBy: "Text" },

    /* Selections */
    { value: "boolean", text: "Boolean", groupBy: "Selections" },
    { value: "checkbox", text: "Checkbox", groupBy: "Selections" },
    { value: "radio", text: "Radio", groupBy: "Selections" },
    { value: "dropdown", text: "Dropdown", groupBy: "Selections" },
    { value: "lookup", text: "Lookup", groupBy: "Selections" },

    /* Other */
    { value: "date", text: "Date", groupBy: "Other" },
    { value: "date_sysdate", text: "Date(Sysdate)", groupBy: "Other" },
    { value: "time", text: "Time", groupBy: "Other" },
    { value: "datetime", text: "DateTime", groupBy: "Other" },
    { value: "datetime_sysdate", text: "DateTime(Sysdate)", groupBy: "Other" },
    { value: "blob", text: "Blob", groupBy: "Other" },
    { value: "clob", text: "Clob", groupBy: "Other" }
  ];
  
  constructor(private apiUtilService: ApiUtilService, private toastr: ToastrService, private router: Router, private cs: CommonService) {
    this.typeFormatList = Array.from(new Set(this.attrTypeList.map(({groupBy}) => groupBy)));
  }

  getAllObjects(successCallback: any, errorCallback: any) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.GET_ALL_OBJECT_METADATA;
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method).subscribe(
      (res: any) => {
        Utils.loader('#page-loader', 'hide');
        this.objectsList = res.body || [];
        successCallback(res);
      },
      (err: any) => {
        Utils.loader('#page-loader', 'hide');
        if(err.name == "HttpErrorResponse"){
          this.toastr.error("Something went wrong!");
        }
        errorCallback(err);
      }
    );
  }

  cleanRequestData(obj){
    obj.attributes = obj.attributes.filter(attr => (attr.name || "").trim() != "");
    obj.relationships = obj.relationships.filter(rel => (rel.model || "").trim() != "" && (rel.name || "").trim() != "");
  };

  saveObjectMetadataByUUId() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.SAVE_APPLICATION_OBJECT_METADATA;
    this.currentEditObject.createdAt = Utils.getCurrentDateTimeISOString();
    this.cleanRequestData(this.currentEditObject);
    this.apiUtilService.invokeAPI(currentAPIProperty.path, currentAPIProperty.method, this.currentEditObject).subscribe(
      (res: any) => {
        this.currentEditObject = res.body;
        this.toastr.success('Object Saved Successfully');
        Utils.loader('#page-loader', 'hide');
        this.router.navigate(['landing', { outlets: { studio: 'object-modeler-editor/' + this.currentEditObject.id }}]);
      },
      (err: any) => {
        this.toastr.error((err.error || {}).message || 'Save Failed');
        Utils.loader('#page-loader', 'hide');
        console.error(err);
      }
    );
  }

  updateObjectMetadataByUUId() {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.UPDATE_APPLICATION_OBJECT_METADATA_BY_UUID;
    this.currentEditObject.updatedAt = Utils.getCurrentDateTimeISOString();
    this.cleanRequestData(this.currentEditObject);
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace('{ID}', this.currentEditObject.id), currentAPIProperty.method, this.currentEditObject).subscribe(
      (res: any) => {
        this.toastr.success('Object Updated Successfully');
        this.getAllObjects((res: any) => {
          this.currentEditObject = this.objectsList.find((obj: any) => obj.id === this.currentEditObject.id);
          this.cs.updateMenuBreadCrumbDetails({ label: this.currentEditObject.model, parentListComponentURL: 'object-list'});
          Utils.loader('#page-loader', 'hide');
        },(err: any) => {
          if(err.name == "HttpErrorResponse"){
            this.toastr.error("Something went wrong!");
          }
          Utils.loader('#page-loader', 'hide');
          console.error(err);
        });
      },
      (err: any) => {
        this.toastr.error((err.error || {}).message || 'Update Failed');
        Utils.loader('#page-loader', 'hide');
        console.error(err);
      }
    );
  }

  deleteObjectMetadata(selectedRowObject: any) {
    Utils.loader('#page-loader', 'show');
    let currentAPIProperty = apiProperties.DELETE_APPLICATION_OBJECT_METADATA_BY_UUID;
    this.apiUtilService.invokeAPI(currentAPIProperty.path.replace("{ID}", selectedRowObject.id), currentAPIProperty.method).subscribe(
      (res: any) => {
        this.toastr.success('Object (' + selectedRowObject.model + ') Deleted Successfully');
        $('#delete-confirmation-modal').modal('hide');
        this.getAllObjects((res: any) => {
          Utils.loader('#page-loader', 'hide');
        }, (err: any) => {
          if(err.name == "HttpErrorResponse"){
            this.toastr.error("Something went wrong!");
          }
          Utils.loader('#page-loader', 'hide');
          console.error(err);
        });
      },
      (err: any) => {
        console.log(err);
        this.toastr.error('Object (' + selectedRowObject.model + ') Delete Failed');
        Utils.loader('#page-loader', 'hide');
      }
    );
  }

}
