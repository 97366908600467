import { Component, OnInit } from '@angular/core';
import { EventApiService } from './../service/event-api.service';
import { CommonService } from '../service/common.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'ccp-event-api-studio',
  templateUrl: './event-api-studio.component.html',
  styleUrls: ['./event-api-studio.component.css']
})
export class EventApiStudioComponent implements OnInit {
  
  eventApiInput: string;
  apiSettings: any;
  private routedSubmoduleName: string;
  public isSubmodule: boolean;

  constructor(
    private eventApiService: EventApiService,
    private cs: CommonService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService
  ) { 
    this.apiSettings = this.eventApiService.apiSettings;
    this.route.paramMap.subscribe(paramObject => {
      this.routedSubmoduleName = (paramObject['params']['moduleName']) || '';
      this.isSubmodule = this.routedSubmoduleName != '';
      this.eventApiInput = (paramObject['params']['inputEvent']) || '';
    });
  }

  ngOnInit() {
    document.title = "CCS - Event API";
    this.eventApiService.resetMetadataRefs();
    this.eventApiService.getAllEventAPIs((res: any) => {
      if(this.eventApiInput) {
        this.apiSettings.isCreateFlow = false;
        var currentEventAPIName = (res.body.find((api: any) => api.inputEvent === this.eventApiInput) || {}).name;
        if(currentEventAPIName) {
          if (this.routedSubmoduleName) this.cs.updateMenuBreadCrumbDetails({ label: currentEventAPIName, parentListComponentURL: `submodule/${this.routedSubmoduleName}/event-api-list` });
          else this.cs.updateMenuBreadCrumbDetails({ label: currentEventAPIName, parentListComponentURL: 'event-api-list'});
          this.apiSettings.init({ isCallFrmProcessFlow: false, inputEvent: this.eventApiInput });
        } else {
          this.toastr.error("Event API does not exist.");
          this.router.navigate(['landing', { outlets: { studio: 'event-api-list' } }]);
        }
      }else{
        this.cs.updateMenuBreadCrumbDetails({ label: 'Create', parentListComponentURL: 'event-api-list'});
        this.apiSettings.initCreateNewEventAPI();
      }
    }, (err: any) => {
        console.error(err);
    });
  }

}
