import { Component, ChangeDetectionStrategy, ViewChild, AfterViewInit, OnDestroy, ChangeDetectorRef, ViewContainerRef,
  ElementRef, DoCheck } from '@angular/core';
import { FormlyConfig, FormlyFieldConfig, FormlyTemplateOptions } from '@ngx-formly/core';
import { ComponentType } from '@angular/cdk/portal';
import {  MatDatepicker, MatDatepickerInput } from '@angular/material/datepicker';
import { FieldType } from '@ngx-formly/core';
import {MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, MatDateFormats} from '@angular/material/core';
import { deepMergeValue, _get } from '../../shared/utils';

/**
 * date format: https://momentjs.com/docs/#/customization/
 * L	  09/04/1986	                          Date (in local format)
 * LL	  September 4 1986	                    Month name, day of month, year
 * LLL	September 4 1986 8:30 PM	            Month name, day of month, year, time
 * LLLL	Thursday, September 4 1986 8:30 PM	  Day of week, month name, day of month, year, time
 * LT	  8:30 PM	Time (without seconds)
 * LTS	8:30:00 PM	Time (with seconds)
 */

/**
 * to display label on top of datepicker
 * \"fieldConfig\": {\"templateOptions\":{\"labelPosition\": \"top\"}}
 */

/**
 * schema datepicker, Example:
 *
 * field: FormlyFieldConfig =
 * {
      key: 'startDate',
      type: 'datepicker',
      templateOptions: {
        label: 'Period Start',
        required: true,
        datepickerOptions: {              <== DatepickerOptions
          min: '2023-06-10',
          max: '2023-06-29',
          formats: {                      <== MatDateFormats
            parse: {
              dateInput: 'L',
            },
            display: {
              dateInput: 'L',
              monthYearLabel: 'MMM YYYY',
              dateA11yLabel: 'LL',
              monthYearA11yLabel: 'MMMM YYYY',
            }
          }
        },
        expressionProperties:
          { 'templateOptions.datepickerOptions.max': 'model.maxDate', },
      }
    }
 */

export const MY_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'LL',
  },
  display: {
    dateInput: 'LL',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

export interface DatepickerOptions {
  touchUi?: boolean;
  opened?: boolean;
  disabled?: boolean;
  startView?: 'month' | 'year' | 'multi-year';
  datepickerTogglePosition?: 'suffix' | 'prefix';
  calendarHeaderComponent?: ComponentType<any>;
  filter?: (date: any | null) => boolean;
  min?: any;
  max?: any;
  dateInput?: (field: any, event: any) => void;
  dateChange?: (field: any, event: any) => void;

  monthSelected?: (field: any, event: any, picker: MatDatepicker<any>) => void;
  yearSelected?: (field: any, event: any, picker: MatDatepicker<any>) => void;

  dateClass?: any;
  panelClass?: string | string[];
  startAt?: any | null;
  formats?: MatDateFormats;
}

export interface DatepickerProps extends FormlyTemplateOptions {
  labelPosition?: 'string';
  datepickerOptions?: DatepickerOptions;
}


@Component({
  selector: 'formly-field-mat-datepicker',
  template: `
    <mat-form-field>
      <input
        matInput
        [id]="id"
        [formControl]="formControl"
        [name]="field.name"
        [matDatepicker]="picker"
        [matDatepickerFilter]="props.datepickerOptions!.filter"
        [max]="props.datepickerOptions!.max"
        [min]="props.datepickerOptions!.min"
        [formlyAttributes]="field"
        [placeholder]="props.placeholder"
        [tabindex]="props.tabindex"
        [readonly]="props.readonly"
        [required]="props.required"
        (dateInput)="props.datepickerOptions!.dateInput!(field, $event)"
        (dateChange)="props.datepickerOptions!.dateChange!(field, $event)"
      />
      <mat-datepicker-toggle
        (click)="detectChanges()"
        [disabled]="props.disabled"
        matSuffix
        [for]="picker"
        ></mat-datepicker-toggle>
      <mat-datepicker
        #picker
        [color]="props.color"
        [dateClass]="props.datepickerOptions!.dateClass"
        [disabled]="props.datepickerOptions!.disabled"
        [opened]="props.datepickerOptions!.opened"
        [panelClass]="props.datepickerOptions!.panelClass"
        [startAt]="props.datepickerOptions!.startAt"
        [startView]="props.datepickerOptions!.startView"
        [touchUi]="props.datepickerOptions!.touchUi"
        (monthSelected)="props.datepickerOptions!.monthSelected!(field, $event, picker)"
        (yearSelected)="props.datepickerOptions!.yearSelected!(field, $event, picker)"
        (opened)="props.datepickerOptions!.opened = true"
        (closed)="props.datepickerOptions!.opened = false"
      >
      </mat-datepicker>
    </mat-form-field>
  `,
  styles: [
    `
      ::ng-deep .cdk-overlay-container {
        z-index: 1060 !important;
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },

    {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS},
  ],
})
export class FormlyFieldDatepicker extends FieldType<FormlyFieldConfig> implements AfterViewInit, OnDestroy, DoCheck {
  // @ViewChild('datepickerToggle', { static: true }) datepickerToggle!: TemplateRef<any>;
  @ViewChild(MatDatepickerInput, { static: true}) datepickerInput: ViewContainerRef;

  props: DatepickerProps = {
    placeholder: null as any,
    tabindex: null as any,
    readonly: false,
    color: null as any,
    disabled: false,
    required: false,
    datepickerOptions: {
      startView: 'month' as const,
      datepickerTogglePosition: 'suffix' as const,
      disabled: false,
      opened: false,
      dateInput: (field: any, $event: any) => {},
      dateChange: (field: any, $event: any) => {},
      monthSelected: (field: any, $event: any, picker: any) => {},
      yearSelected: (field: any, $event: any, picker: any) => {},
      panelClass: [] as any,
      dateClass: '',
      touchUi: false,
      startAt: new Date(),
      max: null as any,
      min: null as any,
      filter: () => true
    }
  };

  // defaultOptions = {
  //   props: {
  //     datepickerOptions: {
  //       startView: 'month' as const,
  //       datepickerTogglePosition: 'suffix' as const,
  //       disabled: false,
  //       opened: false,
  //       dateInput: () => {},
  //       dateChange: () => {},
  //       monthSelected: () => {},
  //       yearSelected: () => {},
  //     },
  //   },
  // };
  private fieldErrorsObserver!: ReturnType<any>;

  constructor(
    private config: FormlyConfig,
    private cdRef: ChangeDetectorRef,
    private el: ElementRef
  ) {
    super();
  }

  ngOnInit() {
    this.setDatepickerOptions();
  }

  detectChanges() {
    // this.options.detectChanges?.(this.field);
  }

  ngAfterViewInit() {
    this.setDatepickerFormats();
    // this.props[this.props.datepickerOptions.datepickerTogglePosition] = this.datepickerToggle;
    // observe<boolean>(this.field, ['props', 'datepickerOptions', 'opened'], () => {
    //   this.cdRef.detectChanges();
    // });

    // temporary fix for https://github.com/angular/components/issues/16761
    // if (this.config.getValidatorMessage('matDatepickerParse')) {
    //   this.fieldErrorsObserver = observe<any>(this.field, ['formControl', 'errors'], ({ currentValue }) => {
    //     if (currentValue && currentValue.required && currentValue.matDatepickerParse) {
    //       const errors = Object.keys(currentValue)
    //         .sort((prop) => (prop === 'matDatepickerParse' ? -1 : 0))
    //         .reduce((errors, prop) => ({ ...errors, [prop]: currentValue[prop] }), {});

    //       this.fieldErrorsObserver?.setValue(errors);
    //     }
    //   });
    // }
  }

  ngDoCheck() {
    const labelPosition = _get(this.props, 'labelPosition');
    var host = this.el.nativeElement;
    if (labelPosition === 'top') {
      host.style.display = 'inherit';
    }
  }

  ngOnDestroy() {
    // this.ngOnDestroy();
    // this.fieldErrorsObserver?.unsubscribe();
  }

  private setDatepickerOptions() {
    const templateOptions = this.field.templateOptions as FormlyTemplateOptions;
    deepMergeValue(this.props, templateOptions);
    ['max', 'min']
    .forEach(key => {
      (this.props.datepickerOptions as any)[key] = templateOptions[key];
    })
    const options = this.props.datepickerOptions as DatepickerOptions;

    if (options.max) {
      if (typeof options.max === 'string') {
        options.max = toDate(options.max);
      }
    }

    if (options.min) {
      if (typeof options.min === 'string') {
        options.min = toDate(options.min);
      }
    }

    if (!this.props.placeholder && this.props.placeholder !== '') {
      this.props.placeholder = this.props.label || 'Choose a date';
    }
  }

  private setDatepickerFormats() {
    const matFormats: MatDateFormats = this.props.datepickerOptions!.formats as MatDateFormats;
    if (matFormats) {
      const datepickerFormat: MatDateFormats = (this.datepickerInput as any)._dateFormats;
      datepickerFormat.display.dateInput = matFormats.display.dateInput;
      datepickerFormat.display.monthYearLabel = matFormats.display.monthYearLabel;
      datepickerFormat.display.dateA11yLabel = matFormats.display.dateA11yLabel;
      datepickerFormat.display.monthYearA11yLabel = matFormats.display.monthYearA11yLabel;

      datepickerFormat.parse.dateInput = matFormats.parse.dateInput;
    }
  }
}

function toDate(date: string): Date {
  if (date === 'new Date()' || date === 'today') {
    return new Date();
  } else {
    return new Date(date);
  }
}