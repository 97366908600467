import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DateTimeRendererComponent } from 'src/app/data-grid/date-time-renderer.component';
import { DefaultTextCellRendererComponent } from 'src/app/data-grid/default-text-cell-renderer.component';
import { UserListActionsComponent } from 'src/app/data-grid/user-list-actions.component';
import { ApiUtilService } from 'src/app/service/api-util.service';
import { CommonService } from 'src/app/service/common.service';

import { UsersService } from 'src/app/service/users.service';
import { Utils } from 'src/app/utility/util';
declare var $: any;

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.css']
})
export class UserListComponent implements OnInit {

  private gridApi: any;
  public pageSize: any = 10;
  public columnDefs: any[];
  public context: any;
  public frameworkComponents: object;

  public selectedRowObject: any= {};
  public isObjectNameExists_ignoreCase: boolean = false;
  public isInValidCloneObj: boolean = false;

  constructor(
    public usersService: UsersService,
    private apiUtilService: ApiUtilService,
    private router: Router,
    private toastr: ToastrService,
    private cs: CommonService
  ) { 
    this.getAllUsers();
  }

  ngOnInit() {
    this.initDataGridDetails();
  }

  getAllUsers() {
    Utils.loader('#page-loader', 'show');
    this.usersService.getAllUsers((res: any) => {
      Utils.loader('#page-loader', 'hide');
    }, (err: any) => {
      console.error(err);
      Utils.loader('#page-loader', 'hide');
    });
  }

  initDataGridDetails() {
    this.context = { componentParent: this };
    this.columnDefs = [
      { headerName: 'Email', field: 'email', sortable: true, suppressMovable: true, cellClass: 'text-custom-link' },
      { headerName: "Username", field: 'userName', width: 130, sortable: true, suppressMovable: true, cellClass: 'ag-text-val-color ag-text-align-center'},
      { headerName: 'Full Name', field: 'fullName', sortable: true, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Status', field: 'isActive', sortable: true, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Created', field: 'createdAt', sortable: true, suppressMovable: true, width: 100, cellClass: 'ag-text-val-color' },
      { headerName: 'Updated', field: 'updatedAt', sortable: true, suppressMovable: true, width: 100, cellClass: 'ag-text-val-color' },
      { headerName: 'Actions', sortable: false, suppressMovable: true, width: 115, cellClass: 'p-0'  }
    ];
    this.frameworkComponents = {
      defaultTextCellRendererComponent: DefaultTextCellRendererComponent,
      userListActionsComponent: UserListActionsComponent,
      dateTimeRendererComponent: DateTimeRendererComponent
    }
    this.setDataGridColDefFormatters();
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout("autoHeight");
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
    this.cs.updateCurrentGridAPI(this.gridApi);
  }

  onFilterTextBoxChanged(searchText: string) {
    this.gridApi.setQuickFilter(searchText);
  }

  setDataGridColDefFormatters() {
    this.columnDefs.forEach((colDef: any) => {
      if(!colDef.valueFormatter) {
        const headerName: string = colDef.headerName;
        switch (headerName) {
          case 'Updated':
          case 'Created':
            colDef.cellRenderer = 'dateTimeRendererComponent';
            break;
          case 'Actions':
            colDef.cellRenderer = 'userListActionsComponent';
            break;
          default:
            colDef.cellRenderer = 'defaultTextCellRendererComponent';
            break;
        }
      }
    });
  }

  invokeGridAction(params: any) {
    let currentRowData: any = params.rowData;
    let type: string = params.type;
    if (type === 'edit') {
      this.router.navigate(['landing', { outlets: { studio: 'user-editor/' + currentRowData['id'] }}]);
    } else if (type === 'delete') {
      this.selectedRowObject = currentRowData;
      $('#delete-confirmation-modal').modal('show');
    }
  }

  deleteEventAPI() {
    Utils.loader('#page-loader', 'show');
    this.usersService.deleteUserFlow(this.selectedRowObject.id, (res: any) => {
      this.toastr.success('Internal Flow (' + this.selectedRowObject.name + ') Deleted Successfully');
      $('#delete-confirmation-modal').modal('hide');
      Utils.loader('#page-loader', 'hide');
      this.getAllUsers();
    }, (err: any) => {
      console.log(err);
      this.toastr.error((err.error || {}).message || 'Internal Flow (' + this.selectedRowObject.name + ') Delete Failed');
      Utils.loader('#page-loader', 'hide');
    });
  }

}
