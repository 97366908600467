import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { DefaultTextCellRendererComponent } from '../data-grid/default-text-cell-renderer.component';
import { MonitorLogsActionsComponent } from '../data-grid/monito-logs-actions.component';
import { MonitorLogsDateTimeRendererComponent } from '../data-grid/monitor-logs-date-time-renderer.component';
import { ApiUtilService } from '../service/api-util.service';
import { CommonService } from '../service/common.service';
import { MonitorLogsService } from '../service/monitor-logs.service';
import { ProcessListService } from '../service/process-list.service';

declare var $: any;

@Component({
  selector: 'app-monitor-logs',
  templateUrl: './monitor-logs.component.html',
  styleUrls: ['./monitor-logs.component.css']
})
export class MonitorLogsComponent implements OnInit {

  private actionButtonName: string = "View Details";
  public mainFilters = {instance_uuid: "", processName: "", status: "", environment: ""};
  private gridApi: any;
  public pageSize: any = 10;
  public columnDefs: any[];
  public context: any;
  public frameworkComponents: object;

  activeTab: any = "RAW";

  public selectedRowObject: any = {};
  showDetailsScreen: boolean = false;
  processContextString: any;
  isDirectMonitorLogHit: boolean = false;

  constructor(
    private apiUtilService: ApiUtilService,
    public processListService: ProcessListService,
    public monitorLogsService: MonitorLogsService,
    private toastr: ToastrService,
    private cs: CommonService,
    private router: Router
  ) { }

  clearFilters() {
    this.mainFilters = {instance_uuid: "", processName: "", status: "", environment: this.mainFilters.environment};
  }

  ngOnInit() {
    if(this.router.url.startsWith('/landing/(studio:monitorLogs')) {
      this.isDirectMonitorLogHit = true;
      if($('#sidebar').hasClass('active') == false) {
        $('#sidebar').addClass('active');
        $('.studio-wrapper').addClass('full-width');
        $('#sidebar > .custom-menu').css('display', 'none');
        $(".studio-wrapper .rowHeader .top-menu-breadcrumb").css('display', 'none');
        $(".studio-wrapper .rowHeader .user-access").css('visibility', 'hidden');
      }
    }
    document.title = "CCS - Process";
    this.initDataGridDetails();
    this.processListService.getAllProcessList(() => {
      (this.processListService.processList || []).map((obj) => {
        return {
          id: obj.name,
          text:obj.name
        }
      });
    });
    if(this.monitorLogsService.selectedEnvironmentRef) {
      this.mainFilters.environment = this.monitorLogsService.selectedEnvironmentRef;
    }
    this.monitorLogsService.getAllProcess(this.mainFilters, true, () => {
      this.monitorLogsService.getEnvironmentList();
    });
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    this.gridApi.sizeColumnsToFit();
    this.gridApi.setDomLayout("autoHeight");
    this.gridApi.paginationSetPageSize(Number(this.pageSize));
    this.cs.updateCurrentGridAPI(this.gridApi);
  }

  initDataGridDetails() {
    this.context = { componentParent: this };
    this.columnDefs = [
      { headerName: 'Process Name', field: 'processName', sortable: true, filter:true, width: 250, suppressMovable: true, cellClass: 'text-custom-link' },
      { headerName: 'Process Id', field: 'instance_uuid', sortable: true, filter:true, width: 250, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Current API', field: 'currentApi', sortable: true, filter:true, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Status', field: 'status', sortable: true, width: 150, filter:true, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Created', field: 'createdAt', sortable: true, sort: 'desc', width: 150, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Updated', field: 'updatedAt', sortable: true, width: 150, suppressMovable: true, cellClass: 'ag-text-val-color' },
      { headerName: 'Actions', sortable: false, suppressMovable: true, width: 100, cellClass: 'p-0' }
    ];
    this.frameworkComponents = {
      "defaultTextCellRendererComponent": DefaultTextCellRendererComponent,
      "monitorLogsActionsComponent": MonitorLogsActionsComponent,
      "dateTimeRendererComponent": MonitorLogsDateTimeRendererComponent
    }
    this.setDataGridColDefFormatters();
  }

  setDataGridColDefFormatters() {
    this.columnDefs.forEach((colDef: any) => {
      if (!colDef.valueFormatter) {
        const headerName: string = colDef.headerName;
        switch (headerName) {
          case 'Created':
          case 'Updated':
            colDef.cellRenderer = 'dateTimeRendererComponent';
            break;
          case 'Actions':
            colDef.cellRenderer = 'monitorLogsActionsComponent';
            break;
          default:
            colDef.cellRenderer = 'defaultTextCellRendererComponent';
            break;
        }
      }
    });
  }

  updateLogsBasedOnEnvironment() {
    this.monitorLogsService.setSelectedEnvRef(this.mainFilters.environment);
    this.monitorLogsService.getAllProcess(this.mainFilters, true);
  }

  invokeGridAction(params: any) {
    let currentRowData: object = params.rowData;
    let type: string = params.type;
    this.selectedRowObject = JSON.parse(JSON.stringify(currentRowData));
    let processId = currentRowData['instance_uuid'];
    if(type == "view-process-details") {
      var monitorLogURL = this.isDirectMonitorLogHit ? 'monitorLogs/process/' : 'monitor-logs/process/';
      if(this.mainFilters.environment) {
        this.router.navigate(['landing', { outlets: { studio: monitorLogURL + processId }}], {queryParams: {env: this.mainFilters.environment}});
      } else {
        this.router.navigate(['landing', { outlets: { studio: monitorLogURL + processId }}]);
      }
    } else if( type == "get-process-logs" ) {
      
    }
  }

}
