import * as CryptoJS from 'crypto-js';

export const apiProperties = {

    STUDIO_LOGIN: { path: "/ccs/users/login", method: "POST" },
    STUDIO_LOGOUT: { path: "/ccs/logout", method: "POST" },

    GET_ALL_PROCESS_METADATA: { path: "/apiEngine/processMedatata", method: "GET" },
    GET_PROCESS_METADATA_BY_NAME: { path: "/apiEngine/processMedatata/name/{NAME}", method: "GET" },
    SAVE_PROCESS_METADATA: { path: "/apiEngine/processMedatata", method: "POST" },
    UPDATE_PROCESS_METADATA: { path: "/apiEngine/processMedatata/{NAME}", method: "PUT" },
    DELETE_PROCESS_METADATA: { path: "/apiEngine/processMedatata/{NAME}", method: "DELETE" },
    CLONE_PROCESS_METADATA: { path: "/apiEngine/processMedatata/clone/{NAME}", method: "POST" },

    GET_ALL_EVENT_APIS: { path: "/apiEngine/apiMetadata", method: "GET" },
    GET_EVENT_API_BY_INPUT_EVENT_NAME: { path: "/apiEngine/apiMetadata/inputEvent/{INPUT_EVENT_NAME}", method: "GET" },
    SAVE_EVENT_API: { path: "/apiEngine/apiMetadata", method: "POST" },
    UPDATE_EVENT_API: { path: "/apiEngine/apiMetadata/{ID}", method: "PUT" },
    DELETE_EVENT_API_BY_ID: { path: "/apiEngine/apiMetadata/{ID}", method: "DELETE" },
    CLONE_EVENT_API: { path: "/apiEngine/apiMetadata/clone/{ID}", method: "POST" },
    SUBMIT_EVENT: { path: "/apiEngine/submitEvent", method: "POST" },
    EXECUTE_EVENT_FLOW: { path: "/apiEngine/eventFlow/execute/{INPUT_EVENT}", method: "POST" },
    EXECUTE_INTERNAL_FLOW: { path: "/apiEngine/api", method: "POST" },

    GET_ALL_INTERNAL_FLOWS: { path: "/apiEngine/internalFlowMetadata", method: "GET" },
    GET_INTERNAL_FLOW_BY_ID: { path: "/apiEngine/internalFlowMetadata/{ID}", method: "GET" },
    SAVE_INTERNAL_FLOW: { path: "/apiEngine/internalFlowMetadata", method: "POST" },
    BULK_CREATE_INTERNAL_FLOW: { path: "/apiEngine/internalFlowMetadata/bulkCreate", method: "POST" },
    UPDATE_INTERNAL_FLOW: { path: "/apiEngine/internalFlowMetadata/{ID}", method: "PUT" },
    DELETE_INTERNAL_FLOW_BY_ID: { path: "/apiEngine/internalFlowMetadata/{ID}", method: "DELETE" },
    CLONE_INTERNAL_FLOW: { path: "/apiEngine/internalFlowMetadata/clone/{ID}", method: "POST" },
    EXPORT_INTERNAL_FLOWS: { path: "/apiEngine/internalFlowMetadata/export/{TYPE}", method: "POST" },

    GET_ALL_EVENT_SCHEMA: { path: "/apiEngine/eventSchema", method: "GET" },
    GET_EVENT_SCHEMA_BY_NAME: { path: "/apiEngine/eventSchema/name/{NAME}", method: "GET" },
    SAVE_EVENT_SCHEMA: { path: "/apiEngine/eventSchema", method: "POST" },
    UPDATE_EVENT_SCHEMA: { path: "/apiEngine/eventSchema/{NAME}", method: "PUT" },
    DELETE_EVENT_SCHEMA_BY_NAME: { path: "/apiEngine/eventSchema/{NAME}", method: "DELETE" },
    CLONE_EVENT_SCHEMA: { path: "/apiEngine/eventSchema/clone/{NAME}", method: "POST" },

    GET_ALL_OBJECT_METADATA: { path: "/apiEngine/objectMetadata", method: "GET" },
    SAVE_APPLICATION_OBJECT_METADATA : { path: "/apiEngine/objectMetadata", method: "POST" },
    UPDATE_APPLICATION_OBJECT_METADATA_BY_UUID: { path: "/apiEngine/objectMetadata/{ID}", method: "PUT" },
    DELETE_APPLICATION_OBJECT_METADATA_BY_UUID: { path: "/apiEngine/objectMetadata/{ID}", method: "DELETE" },
    CLONE_APPLICATION_OBJECT_METADATA: { path: "/apiEngine/objectMetadata/clone/{ID}", method: "POST" },

    GET_ALL_API_CONNECTORS: { path: "/apiEngine/apiConnector", method: "GET" },
    GET_API_CONNECTOR_BY_ID: { path: "/apiEngine/apiConnector/{ID}", method: "GET" },
    UPDATE_API_CONNECTOR_BY_ID: { path: "/apiEngine/apiConnector/{ID}", method: "PUT" }, 
    SAVE_API_CONNECTOR: { path: "/apiEngine/apiConnector", method: "POST" },
    CLONE_API_CONNECTOR: { path: "/apiEngine/apiConnector/clone/{ID}", method: "POST" },
    TEST_CONNECTION_BY_CONNECTOR_DETAILS: { path: "/apiEngine/apiConnector/testConnection", method: "POST" },
    DELETE_API_CONNECTOR_BY_ID: { path: "/apiEngine/apiConnector/{ID}", method: "DELETE" },
    IMPORT_SWAGGER: {path: "/apiEngine/util/convertSwaggerToJson", method: "POST"},
    IMPORT_API: {path: "/apiEngine/internalFlowMetadata/import", method: "POST"},

    GET_ALL_SYSTEM_PROPERTIES: { path: "/apiEngine/property", method: "GET" },
    SAVE_SYSTEM_PROPERTIES: { path: "/apiEngine/property", method: "POST" },
    UPDATE_SYSTEM_PROPERTY_BY_KEY: { path: "/apiEngine/property/{KEY}", method: "PUT" },
    DELETE_SYSTEM_PROPERTY_BY_KEY: { path: "/apiEngine/property/{KEY}", method: "DELETE" },

    GET_DEBUGGER_REF: { path: "/apiEngine/workspace/debuggerRef", method: "GET" },
    GET_ALL_PROJECTS_LIST: { path: "/apiEngine/workspace/projectList", method: "GET" },
    CREATE_NEW_PROJECT: { path: "/apiEngine/workspace/newProject", method: "POST" },
    GET_ACTIVE_PROJECT: { path: "/apiEngine/workspace/activeProject", method: "GET" },
    SET_ACTIVE_PROJECT: { path: "/apiEngine/workspace/activeProject", method: "PUT" },
    GET_ACTIVE_PROJECT_SUBMODULE_NAMES: { path: "/apiEngine/workspace/listSubmodule", method: "GET" },

    GET_ALL_REPORT_METADATA: { path: "/apiEngine/analyticsStudio", method: "GET" },
    CLONE_REPORT_METADATA: { path: "/apiEngine/analyticsStudio/clone/{ID}", method: "POST" },
    GET_REPORT_METADATA_BY_ID: { path: "/apiEngine/analyticsStudio/{ID}", method: "GET" },
    SAVE_REPORT_METADATA: { path: "/apiEngine/analyticsStudio", method: "POST" },
    UPDATE_REPORT_METADATA_BY_ID: { path: "/apiEngine/analyticsStudio/{ID}", method: "PUT" },
    DELETE_REPORT_METADATA_BY_ID: { path: "/apiEngine/analyticsStudio/{ID}", method: "DELETE" },
    GET_REPORT_SCHEMA_TABLES: { path: "/apiEngine/analyticsStudio/getDBSchema/tables", method: "GET" },
    GET_REPORT_SCHEMA_TABLE_COLUMNS: { path: "/apiEngine/analyticsStudio/getDBSchema/tableColumns", method: "GET" },

    GET_REPORT_FILTER_DATA: { path: "/apiEngine/analytics/filterList/{ID}", method: "GET" },
    GET_REPORT_RECORDS: { path: "/apiEngine/analytics/execute/{ID}", method: "POST" },
    GET_CHART_DRILLDOWN_RECORDS: { path: "/apiEngine/analytics/chartDrilldown/{APIID}", method: "POST" },

    GENERATE_ACCESS_TOKEN: { path: "/access/token", method: "POST" },
    LIST_ALL_PROCESS: { path: "/oam/process", method: "GET" },
    GET_ENV_LIST: { path: "/oam/process/environmentList", method: "GET" },
    GET_PROCESS_DETAILS: { path: "/oam/process/{processId}", method: "GET" },
    GET_PROCESS_EVENT: { path: "/oam/event/{eventId}", method: "GET" },
    GET_PROCESS_LOG: { path: "/oam/processLog/{processId}", method: "GET" },
    GET_PROCESS_LOG_TRACE_DETAILS: { path: "/oam/processLog/{processId}/trace/{stepId}", method: "GET" },

    LIST_USERS: { path: "/studio/users", method: "GET" },
    GET_USER_BY_ID: { path: "/studio/users/{id}", method: "GET" },
    CREATE_USER: { path: "/studio/users", method: "POST" },
    UPDATE_USER: { path: "/studio/users/{id}", method: "PUT" },
    DELETE_USER: { path: "/studio/users/{id}", method: "DELETE" }
}

export const dateFormat: any = {"moment":{"EEE":"ddd","EEEE":"dddd","d":"D","dd":"DD","M":"M","MM":"MM","MMM":"MMM","MMMM":"MMMM","yy":"YY","yyyy":"YYYY","h":"h","hh":"hh","H":"H","HH":"HH","a":"A","mm":"mm","ss":"ss","S":"SSS"}};
export const CRYPT_SECRET_KEY: any = "ZXAtcGxhdGZvcm0tc2Vj";
export const PLATFORM_CRYPTO_IV: any = CryptoJS.enc.Hex.parse("0f0e0d0c0b0a09080706050403020100");