import { dateFormat } from  './constants';
  
export function getMomentDateFormat(format: string){
    return updateFormat(dateFormat.moment, format, '%')
}

function updateFormat(dateFormatObj: any, format: string, additionKey: string){
    additionKey = additionKey || '';
    Object.keys(dateFormatObj).sort().reverse().forEach(function(key){
        const temp_format = format.replace(key, additionKey + dateFormatObj[key]);
        if(temp_format.indexOf('%%') == -1){
            format = temp_format;
        }
    });
    if(additionKey != ''){
        const reg = new RegExp(additionKey, 'g');
        format = format.replace(reg, '');			
    }
    if(format.indexOf('XXXXX') != -1){
        format = format.replace('XXXXX', 'SS');
    }
    return format;
}