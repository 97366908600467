import { Pipe, PipeTransform } from '@angular/core';
import { _get } from './../shared/utils';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {
  transform(items: any[], path: string, value: any, changeIndicator: number): any[] {
    if (!items) return [];
    if (!path) return items;

    return items.filter(item => {
      if (path === undefined || path === '*') {
        return Object.keys(item).some(key => {
          return String(item[key]).toLowerCase().includes(value.toLowerCase());
        });
      } else {
        if (value === undefined) {
          return _get(item, path);
        } else {
          return _get(item, path) == value;
        }
      }
    });
   }
}