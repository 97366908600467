import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
declare var $: any;

@Component({
  selector: 'app-system-properties-editor',
  templateUrl: './system-properties-editor.component.html',
  styleUrls: ['./system-properties-editor.component.css']
})
export class SystemPropertiesEditorComponent implements OnInit {

  @Input() selectedRow: any;
  @Input() isCreateFlow: boolean;
  @Output() invokeSaveEvent = new EventEmitter<any>();
  @Output() invokeUpdateEvent = new EventEmitter<any>();

  public isPasswordMode: boolean = true;

  constructor() { }

  ngOnInit() {
    this.isPasswordMode = true;
  }

  invokeSave() {
    this.invokeSaveEvent.emit();
  }

  invokeUpdate() {
    this.invokeUpdateEvent.emit();
  }
  
}