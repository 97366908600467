import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Location } from '@angular/common';
import { ApiUtilService } from '../../service/api-util.service';


@Injectable({
  providedIn: 'root'
})
export class RouterGaurdService implements CanActivate {
  constructor(
    public router: Router,
    public location: Location,
    public apiUtilService: ApiUtilService
    ) { }

  canActivate(activatedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot): boolean | Promise<boolean> {
    return this.checkEligibilityToRoute(activatedRoute, routerState);
  }

  private getConfiguredUrl(route: ActivatedRouteSnapshot): string {
    return '/' + route.pathFromRoot
      .filter(v => v.routeConfig)
      .map(v => v.routeConfig!.path)
      .join('/');
  }

  private async checkEligibilityToRoute(activatedRoute: ActivatedRouteSnapshot, routerState: RouterStateSnapshot) {
    let routerUrl = this.getConfiguredUrl(activatedRoute);
    let access = true;
    this.apiUtilService.isUserLoggedIn = true;
    if(!access){
      this.router.navigate(['/unautorized']);
    }
    return access;
  }

}
