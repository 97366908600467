import { getMomentDateFormat } from './../utility/date-util';
import * as moment from 'moment';
import * as CryptoJS from 'crypto-js';
import { CRYPT_SECRET_KEY, PLATFORM_CRYPTO_IV } from '../utility/constants';
declare var $: any;
export class Utils {
    
    static escapeHtml(source: any): any {
        if (typeof(source) == 'string') {
            source = source
            .replace(/&/g, '&amp;')
            .replace(/"/g, '&quot;')
            .replace(/'/g, '&#39;')
            .replace(/</g, '&lt;')
            .replace(/>/g, '&gt;');
        }
        return source;
    };

    static getRandomId(): string{ //Prototype to generate random number
        var d = Date.now();
        return "xxxxxxxx-xxxx-7xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function(c) {
            var r = (d + Math.random()*16)%16 | 0;
            d = Math.floor(d/16);
            return (c=='x' ? r : (r&0x3|0x8)).toString(16);
        });
    };

    static isMergeableObject(val: any) {
      let nonNullObject: boolean = (val && typeof val === 'object');
    
      return nonNullObject
        && Object.prototype.toString.call(val) !== '[object RegExp]'
        && Object.prototype.toString.call(val) !== '[object Date]';
    };

    static dateValueFormatter(params: any): string{
        const data: any = params.value;
        // return data != undefined && data != null ? Utils.escapeHtml(moment(data).format(getMomentDateFormat('yyyy/MM/dd HH:mm:ss'))) : '-';
        return data != undefined && data != null ? Utils.escapeHtml(moment(data.toUpperCase().endsWith("Z") ? data : data + "Z").format(getMomentDateFormat('MMM dd, YYYY'))) : '-';
    };

    static dateTimeValueFormatter(params: any, format?: string): string{
      const data: any = params.value;
      const dateTimeFormat: string = (params.dateTimeFormat || format) || 'MMM dd, YYYY hh:mm A';
      // return data != undefined && data != null ? Utils.escapeHtml(moment(data).format(getMomentDateFormat('yyyy/MM/dd HH:mm:ss'))) : '-';
      return data != undefined && data != null ? Utils.escapeHtml(moment(data.toUpperCase().endsWith("Z") ? data : data + "Z").format(getMomentDateFormat(dateTimeFormat))) : '-';
    };

    static isNullOrEmpty(data: any): boolean{
        data = data || "";
        return data == "";
    };

    static capitalize(str: string) {
      str = str || "";
      return str.replace(/^./, str[0].toUpperCase());
    };

    static loader(selector: any, type: string){
        if(type === 'show') $(selector).show();
        else if(type === 'hide') $(selector).fadeOut();
    };

    static getCurrentDateTimeISOString() {
        return moment.utc().toISOString();
    };

    static angularMerge(target: any, source: any, optionsArgument?: any){
        var array: boolean = Array.isArray(source);
        var options: any = optionsArgument || { arrayMerge: this.defaultArrayMerge };
        var arrayMerge: any = options.arrayMerge || this.defaultArrayMerge;
      
        if (array) {
          return Array.isArray(target) ? arrayMerge(this, target, source, optionsArgument) : this.cloneIfNecessary(source, optionsArgument);
        } else {
          return this.mergeObject(target, source, optionsArgument);
        }
    };

    static defaultArrayMerge(_this: any, target: any, source: any, optionsArgument: any) {
        var destination: any = target.slice();
        source.forEach( (e: any, i: number) => {
          if (typeof destination[i] === 'undefined') {
            destination[i] = _this.cloneIfNecessary(e, optionsArgument);
          } else if (_this.isMergeableObject(e)) {
            destination[i] = _this.angularMerge(target[i], e, optionsArgument);
          } else if (target.indexOf(e) === -1) {
            destination.push(_this.cloneIfNecessary(e, optionsArgument));
          }
        })
        return destination;
    };

    static cloneIfNecessary(value: any, optionsArgument: any) {
        let clone: boolean = (optionsArgument && optionsArgument.clone === true);
        return (clone && this.isMergeableObject(value)) ? this.angularMerge(this.emptyTarget(value), value, optionsArgument) : value;
    };

    static mergeObject(target: any, source: any, optionsArgument: any) {
        var destination: any = {}
        if (this.isMergeableObject(target)) {
          Object.keys(target).forEach( (key: string) => {
            destination[key] = this.cloneIfNecessary(target[key], optionsArgument);
          });
        }
        Object.keys(source).forEach( (key: any) => {
          if (!this.isMergeableObject(source[key]) || !target[key]) {
            destination[key] = this.cloneIfNecessary(source[key], optionsArgument)
          } else {
            destination[key] = this.angularMerge(target[key], source[key], optionsArgument)
          }
        });
        return destination;
    };

    static emptyTarget(val: any) {
        return Array.isArray(val) ? [] : {};
    };

    static cloneJSON(json){
        return JSON.parse(JSON.stringify(json));
    };

    static cleanEmptyStrFromObj(data: any) {
      var cleanObjectOrArray = (obj: any) => {
        if (obj && typeof(obj) == "object") {
          if (obj instanceof Array) cleanArray(obj);
          else cleanObject(obj);
        }
      };
      var cleanObject = (obj: any) => {
        Object.keys(obj).forEach(key => {
          var value = obj[key];
          if (typeof(value) == "string" && value == "") {
            delete obj[key];
          } else cleanObjectOrArray(value);
        });
      };
      var cleanArray = (arr: any[]) => { arr.forEach(obj => cleanObject(obj)); };

      cleanObjectOrArray(data);
    };

    static cryptoJSEncrypt (textToEncrypt: any) {
      if (["number", "string"].indexOf(typeof (textToEncrypt)) == -1 || (textToEncrypt + "") == "") return textToEncrypt;
      else return CryptoJS.AES.encrypt(textToEncrypt.toString(), CryptoJS.MD5(CRYPT_SECRET_KEY), { iv: PLATFORM_CRYPTO_IV, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }).toString();
    };

}